import React, { useState, useEffect } from "react";
import NavbarAdmin from "../../../component/navbarAdmin/Sidebar";
import CalenderComp from "./components/Calender";
import "./dashboard.css";
import ApexChart from "./components/ChartDashboard";
import MapIndo from "./components/MapIndo";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import DistributionTable from "./components/DistributionTable";
import BatchTable from "./components/BatchTable";
import LoadingAdm from "../../loadAdmin/loadingAdm";

const Dashboard = () => {
  const authToken = localStorage.getItem("token");
  const navigate = useNavigate();
  // const [stock, setStock] = useState([]);
  // const [sold, setSold] = useState([]);
  const [topBuyer, setTopBuyer] = useState([]);
  const [price, setPrice] = useState("");
  const [loading, setLoading] = useState(false);

  const [sbOn, setSbOn] = useState("");

  const receiveDataFromSidebar = (data) => {
    const sbData = { data: data === undefined ? true : data };
    setSbOn(sbData.data);
  };

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth > 768) {
        setSbOn(false);
      } else {
        setSbOn(true);
      }
    }

    handleResize();

    const getPrice = async () => {
      try {
        const response = await axios.get("/price");
        setPrice(response.data[0].buy);
      } catch (error) {
        console.log(error.message);
      }
    };
    getPrice();
    console.log(price);

    // const produkData = async () => {
    //   try {
    //     const response = await axios.get("/product", {
    //       headers: {
    //         Authorization: authToken,
    //       },
    //     });
    //     const filteredStock = response.data.filter(
    //       (item) => item.prefix === "S1" && item.sales != "sold",
    //     );
    //     const filteredSold = response.data.filter(
    //       (item) => item.prefix === "S1" && item.sales === "sold",
    //     );

    //     setStock(filteredStock);
    //     setSold(filteredSold);
    //     setLoading(false);
    //   } catch (error) {
    //     if (error.response.status === 401) {
    //       navigate("/login");
    //     } else if (error.response.status === 403) {
    //       navigate("/login");
    //     } else {
    //       console.log(error.response.status);
    //     }
    //   }
    // };

    // produkData();

    const salesData = async () => {
      try {
        const response = await axios.get("/sales", {
          headers: {
            Authorization: authToken,
          },
        });

        // Filter data yang memiliki properti "qty"
        const filteredSales = response.data.filter(
          (item) => item.qty !== undefined,
        );

        // Gabungkan qty berdasarkan properti "phone"
        const phoneSalesMap = filteredSales.reduce((acc, item) => {
          if (acc[item.phone]) {
            acc[item.phone].qty += item.qty;
          } else {
            acc[item.phone] = { ...item };
          }
          return acc;
        }, {});

        // Konversi kembali objek menjadi array
        const combinedSales = Object.values(phoneSalesMap);

        // Urutkan data berdasarkan nilai "qty" secara menurun
        combinedSales.sort((a, b) => b.qty - a.qty);

        // Ambil 10 data pertama dari hasil urutan
        const top10Qty = combinedSales.slice(0, 10);

        setTopBuyer(top10Qty);
      } catch (error) {
        if (error.response.status === 401) {
          navigate("/login");
        } else if (error.response.status === 403) {
          navigate("/login");
        } else {
          console.log(error.response.status);
        }
      }
    };
    window.addEventListener("resize", handleResize);

    salesData();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
      minimumFractionDigits: 0, // Menghilangkan digit di belakang koma
    }).format(amount);
  };

  /* --------------------------------------------------------------------------------- */

  return (
    <div className="d-flex">
      <NavbarAdmin sbOnProp={receiveDataFromSidebar} sbAct={"dashboard"} />
      <br />
      <LoadingAdm loadingProps={loading} />

      <div
        className={`container-fluid ${
          !sbOn ? "content-body" : "content-body-active"
        } p-4`}
      >
        {/* ------------------------------------------------------------ */}
        <div className="row">
          <div className="col-lg-7 col-md-12 col-xl-8">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-4 col-lg-4 mb-4">
                <div className="card-admin p-3">
                  <p
                    style={{
                      opacity: "60%",
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                    className="mb-1 text-d-head-1"
                  >
                    Product Sold
                  </p>
                  <p
                    style={{ fontSize: "16px", fontWeight: "600" }}
                    className="mb-0 text-d-head-1"
                  >
                    {/* {sold.length} Slop */}-
                  </p>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-4 col-lg-4 mb-4">
                <div className="card-admin p-3">
                  <p
                    style={{
                      opacity: "60%",
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                    className="mb-1 text-d-head-1"
                  >
                    Product Stock
                  </p>
                  <p
                    style={{ fontSize: "16px", fontWeight: "600" }}
                    className="mb-0 text-d-head-1"
                  >
                    {/* {stock.length} Slop */}-
                  </p>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-4 col-lg-4 mb-4">
                <div className="card-admin p-3">
                  <p
                    style={{
                      opacity: "60%",
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                    className="mb-1 text-d-head-1"
                  >
                    Income
                  </p>
                  <p
                    style={{ fontSize: "16px", fontWeight: "600" }}
                    className="mb-0 text-d-head-1"
                  >
                    {/* {formatCurrency(sold.length * 330000)} */}-
                  </p>
                </div>
              </div>
            </div>
            {/* ------------------------------------------------------------ */}
            <div className="card-admin p-3 mb-4">{/* <ApexChart /> */}</div>
          </div>
          {/* ------------------------------------------------------------ */}
          <div className="col-md-12 col-lg-5 col-xl-4 mb-4">
            <div className="card-admin p-3" style={{ height: "100%" }}>
              <h4 className="mb-1">Monthly Top Buyer</h4>
              <p style={{ fontSize: "12px", opacity: "60%" }}>
                Monitored to become a potential main distributor
              </p>
              <hr className="mb-2" />{" "}
              <table className="w-100">
                <tr>
                  <th className="p-2" style={{ width: "25%" }}>
                    <p style={{ fontSize: "16px" }} className="mb-0">
                      Rank
                    </p>
                  </th>
                  <th className="p-2" style={{ width: "50%" }}>
                    <p style={{ fontSize: "16px" }} className="mb-0">
                      Phone
                    </p>
                  </th>
                  <th className="p-2" style={{ width: "25%" }}>
                    <p style={{ fontSize: "16px" }} className="mb-0">
                      Qty
                    </p>
                  </th>
                </tr>
                {topBuyer.map((item, index) => (
                  <tr>
                    <td className="px-2 py-1">
                      <p style={{ fontSize: "14px" }} className="mb-0">
                        {index + 1}
                      </p>
                    </td>
                    <td className="px-2 py-1">
                      <p style={{ fontSize: "14px" }} className="mb-0">
                        {item.phone}
                      </p>
                    </td>

                    <td className="px-2 py-1">
                      <p style={{ fontSize: "14px" }} className="mb-0">
                        {item.qty}
                      </p>
                    </td>
                  </tr>
                ))}
              </table>
            </div>
          </div>
        </div>
        {/* ------------------------------------------------------------ */}

        <div
          className="card-admin p-3 mb-4"
          style={{ overflow: "scroll", position: "relative" }}
        >
          <h4 className="mb-1">Warehouse Newest History</h4>
          <p style={{ fontSize: "12px", opacity: "60%" }}>
            Transaction & Monitoring Product{" "}
          </p>
          {/* <DistributionTable /> */}
        </div>

        {/* ------------------------------------------------------------ */}
        {/* <BatchTable /> */}
        {/* ------------------------------------------------------------ */}
        <div className="d-flex flex-wrap-rows">
          <div
            className="card-admin p-3 card-sch mb-4"
            style={{ maxWidth: "350px" }}
          >
            <h4 className="">Company Schedule</h4>
            <hr className="mb-1" />
            <CalenderComp />
          </div>
          <div
            className="card-admin p-3 w-100 mb-4 "
            style={{ maxWidth: "350px" }}
          >
            <h4 className="">Product Distribution</h4>
            <hr className="mb-3" />
            <MapIndo />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
