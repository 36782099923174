import React, { useEffect } from "react";
import "./footer.css";
import cup from "./kopi.png";
import { IoLocationSharp } from "react-icons/io5";
import { IoMail } from "react-icons/io5";
import { IoIosPhonePortrait } from "react-icons/io";

import { AiFillInstagram } from "react-icons/ai";
import { FaFacebookSquare } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa6";
import { TbBrandYoutubeFilled } from "react-icons/tb";
import { FaSquareXTwitter } from "react-icons/fa6";

const Footer = () => {
  return (
    <div id="footer" className="footer-ddn p-3 d-flex mt-auto">
      <div className="footer-content container">
        <div className="row">
          <div className=" mb-4 col-6 col-sm-6 col-md-4 col-lg d-flex justify-content-start">
            <div className="mt-4">
              <p className="title-footer">Beranda</p>
              <p className="text-footer">Lihat Produk</p>
              <p className="text-footer">Kelebihan </p>
              <p className="text-footer">Tentang Perusahaan </p>
              <p className="text-footer">Komposisi </p>
            </div>
          </div>
          <div className=" mb-4 col-6 col-sm-6 col-md-4 col-lg d-flex justify-content-start">
            <div className="mt-4">
              <p className="title-footer">Produk</p>
              <p className="text-footer">List Produk</p>
              <p className="text-footer">Promo</p>
            </div>
          </div>

          <div className=" mb-4 col-6 col-sm-6 col-md-4 col-lg d-flex justify-content-start">
            <div className="mt-4">
              <p className="title-footer">Sosial Media</p>
              <div className="row">
                <a
                  href="https://www.instagram.com/dynamiccoffeeofficial2024?igsh=MTl2bTFicTQwMmpmcA=="
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="col-4 col-md-4 col-lg-4 col-xl-3 mb-2">
                    <AiFillInstagram size={40} className="pointer" />
                  </div>
                </a>
              </div>
            </div>
          </div>

          <div className=" mb-4 col-6 col-sm-6 col-md-6 col-lg d-flex justify-content-start">
            <div className="mt-4">
              <span className="d-flex mb-3 align-items-center">
                <div
                  className="me-2 d-flex justify-content-center align-items-center"
                  style={{ width: "20px" }}
                >
                  <IoMail size={18} />{" "}
                </div>
                <p className="text-footer mb-0">Dynamiccoffe@gmail.com</p>
              </span>
              <span className="d-flex mb-3 align-items-center">
                <div
                  className="me-2 d-flex justify-content-center align-items-center"
                  style={{ width: "20px" }}
                >
                  <IoIosPhonePortrait size={20} />{" "}
                </div>
                <p className="text-footer mb-0">+62 811 1111 4040</p>
              </span>
            </div>
          </div>
          <div className="mb-4 col-12 col-sm-12 col-md-6 col-lg d-flex justify-content-center">
            <img
              src={cup}
              className="m-auto d-flex mt-4 img-f"
              style={{ width: "100px", height: "auto" }}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
