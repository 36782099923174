import React, { useState, useEffect } from "react";
import "./product.css";
import { IoClose } from "react-icons/io5";
import axios from "axios";

const BuyProduct = ({
  propsModalProduct,
  closeModalCallback,
  type,
  price,
  qty,
}) => {
  console.log(qty);

  const closeModal = () => {
    closeModalCallback(); // Panggil fungsi pengatur dari komponen utama
  };

  const formatRupiah = (number) => {
    const formattedString = new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
    }).format(number);

    return formattedString.endsWith(",00")
      ? formattedString.slice(0, -3)
      : formattedString;
  };

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    price: price,
    qty: 1,
    city: "",
    role: 0,
    postalcode: "",
    type: type,
    status: "waiting",
  });
  console.log(formData);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "qty") {
      const parsedValue = parseInt(value, 10);
      if (!isNaN(parsedValue) && parsedValue >= 1) {
        setFormData((prevState) => ({
          ...prevState,
          [name]: parsedValue,
        }));
      } else {
        setFormData((prevState) => ({
          ...prevState,
          [name]: 1,
        }));
      }
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      !formData.name ||
      !formData.email ||
      !formData.phone ||
      !formData.qty ||
      !formData.address ||
      !formData.city ||
      !formData.postalcode
    ) {
      alert("Mohon lengkapi formulir terlebih dahulu.");
      return;
    }
    if (formData.qty > qty) {
      alert(
        "Stok tidak cukup, mohon masukan jumlah kuantiti yang lebih kecil.",
      );
      return;
    }
    try {
      const response = await axios.post("/add-sales", formData);
      alert("Pesanan anda akan segera diproses.");
      window.location.reload();
      closeModalCallback(); // Panggil fungsi pengatur dari komponen utama
    } catch (error) {
      console.error("Error:", error);
      alert("Terjadi Kesalahan");
    }
  };

  return (
    <div className="">
      {propsModalProduct && (
        <div>
          <div className="modalp">
            <div className="modal-content-product">
              <div className="d-flex align-items-center justify-content-between">
                <h2 className="mb-0 modal-content-product-text">Beli Produk</h2>

                <div className="pointer" onClick={closeModal}>
                  <IoClose size={26} color="red" />
                </div>
              </div>
              <p className="mb-0 modal-content-product-text">
                Formulir Pembelian
              </p>
              <hr style={{ color: "black" }} />

              <div>
                <div className="d-flex justify-content-between mb-3">
                  <div className="me-1">
                    <p
                      style={{
                        opacity: "80%",
                        fontSize: "14px",
                        fontWeight: "600",
                      }}
                      className="ms-1 mb-0 modal-content-product-text"
                    >
                      Email
                    </p>
                    <input
                      onChange={handleChange}
                      className="input-product w-100"
                      type="text"
                      name="email"
                      placeholder="Email"
                      autocomplete="off"
                    />
                  </div>
                  <div className="me-1">
                    <p
                      style={{
                        opacity: "80%",
                        fontSize: "14px",
                        fontWeight: "600",
                      }}
                      className="ms-1 mb-0 modal-content-product-text"
                    >
                      Name
                    </p>
                    <input
                      onChange={handleChange}
                      className="input-product w-100"
                      type="text"
                      placeholder=" Name"
                      name="name"
                      autocomplete="off"
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-between mb-3">
                  <div className="ms-1">
                    <p
                      style={{
                        opacity: "80%",
                        fontSize: "14px",
                        fontWeight: "600",
                      }}
                      className="ms-1 mb-0 modal-content-product-text"
                    >
                      Phone (WA)
                    </p>
                    <input
                      onChange={handleChange}
                      className="input-product w-100"
                      type="number"
                      placeholder="Recipient Phone"
                      name="phone"
                      autocomplete="off"
                    />
                  </div>
                  <div className="ms-1">
                    <div className="d-flex justify-content-between align-items-center">
                      <p
                        style={{
                          opacity: "80%",
                          fontSize: "14px",
                          fontWeight: "600",
                        }}
                        className="ms-1 mb-0 modal-content-product-text"
                      >
                        Qty
                      </p>
                      <p
                        style={{
                          opacity: "80%",
                          fontSize: "12px",
                          fontWeight: "600",
                          fontStyle: "italic",
                        }}
                        className="ms-1 mb-0 modal-content-product-text"
                      >
                        Tersisa : {qty} SLOP
                      </p>
                    </div>

                    <input
                      onChange={handleChange}
                      className="input-product w-100"
                      type="number"
                      placeholder="Qty"
                      name="qty"
                      autoComplete="off"
                      min="1"
                    />
                  </div>
                </div>
                <p
                  style={{
                    opacity: "80%",
                    fontSize: "14px",
                    fontWeight: "600",
                  }}
                  className="ms-1 mb-0 modal-content-product-text"
                >
                  Address
                </p>
                <textarea
                  onChange={handleChange}
                  className="input-product w-100"
                  type="text"
                  placeholder="Recipient Name"
                  style={{ minHeight: "60px" }}
                  name="address"
                  autocomplete="off"
                />
                <div className="d-flex justify-content-between my-2">
                  <div className="me-1">
                    <p
                      style={{
                        opacity: "80%",
                        fontSize: "14px",
                        fontWeight: "600",
                      }}
                      className="ms-1 mb-0 modal-content-product-text"
                    >
                      City
                    </p>
                    <input
                      onChange={handleChange}
                      className="input-product w-100"
                      type="text"
                      placeholder="City"
                      name="city"
                      autocomplete="off"
                    />
                  </div>
                  <div className="ms-1">
                    <p
                      style={{
                        opacity: "80%",
                        fontSize: "14px",
                        fontWeight: "600",
                      }}
                      className="ms-1 mb-0 modal-content-product-text"
                    >
                      Postal Code
                    </p>
                    <input
                      onChange={handleChange}
                      className="input-product w-100"
                      type="number"
                      placeholder="Postal Code"
                      name="postalcode"
                      autocomplete="off"
                    />
                  </div>
                </div>

                <hr className="mt-4" style={{ color: "black" }} />
                <div className="d-flex justify-content-between">
                  <p className="mb-0 modal-content-product-text">Price</p>
                  <p className="mb-0 modal-content-product-text">
                    {formatRupiah(price)}
                  </p>
                </div>
                <div className="d-flex justify-content-between">
                  <p className="mb-0 modal-content-product-text">Qty</p>
                  <p className="mb-0 modal-content-product-text">
                    {formData.qty || 0}
                  </p>
                </div>
                <hr
                  className="my-2 ms-auto"
                  style={{ color: "black", width: "40%" }}
                />

                <div className="d-flex justify-content-between">
                  <p
                    style={{ fontSize: "20px", fontWeight: "600" }}
                    className="mb-0 modal-content-product-text"
                  >
                    Total
                  </p>
                  <p
                    style={{ fontSize: "20px", fontWeight: "600" }}
                    className="mb-0 modal-content-product-text"
                  >
                    {formatRupiah(formData.qty * price)}
                  </p>
                </div>
                <br />
                <button
                  onClick={handleSubmit}
                  className="w-100 btn btn-dark btn-buy mx-auto d-flex justify-content-center "
                >
                  Lanjutkan
                </button>
              </div>
            </div>
          </div>
          <div className="modal-blocker" onClick={closeModal}></div>
        </div>
      )}
    </div>
  );
};

export default BuyProduct;
