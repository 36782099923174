import React, { useState, useEffect } from "react";
import QRCode from "qrcode";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import logotick from "../../../../publicAssets/img/lt2.svg";

const Wqr = ({ wqr }) => {
  console.log(wqr);
  const [qrCodeDataURL, setQRCodeDataURL] = useState("");

  useEffect(() => {
    generateQR();
  }, [wqr]);

  const generateQR = async () => {
    try {
      const qrDataURL = await QRCode.toDataURL(JSON.stringify(wqr));
      setQRCodeDataURL(qrDataURL);
    } catch (error) {
      console.error("Error generating QR code:", error);
    }
  };

  const downloadQR = async () => {
    try {
      // Buat elemen canvas dari gambar QR code
      const canvas = await html2canvas(document.getElementById("qrcode"), {
        scale: 2, // Skala gambar QR code untuk meningkatkan resolusi
      });

      // Buat file PDF baru dengan ukuran kertas 10x15 cm (atau 100x150 mm)
      const pdf = new jsPDF({
        orientation: "potrait", // Untuk mengatur orientasi menjadi lanskap
        unit: "mm",
        format: [100, 100], // Ukuran kertas dalam mm
      });

      pdf.addImage(canvas.toDataURL("image/png"), "PNG", 0, 0, 100, 100); // Mulai dari posisi (0,15) dengan ukuran 100x100 mm

      pdf.save("invoice_qr.pdf");
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  };

  return (
    <div className="d-flex flex-column justify-content-center align-items-center mt-3">
      {qrCodeDataURL && (
        <div
          id="qrcode"
          style={{
            width: "100mm",
            height: "100mm",
            padding: "40px",
            backgroundColor: "white",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
          className="mb-4"
        >
          <h4 className="text-center mb-0 fw-bold" style={{ color: "black" }}>
            DIGITAL INVOICE
          </h4>
          <div
            style={{ position: "relative" }}
            className="d-flex align-items-center justify-content-center"
          >
            <img
              src={qrCodeDataURL}
              style={{ objectFit: "cover", width: "225px", height: "225px" }} // Menyesuaikan gambar agar tidak terlalu tergeser
              alt="QR Code"
            />
            <img
              src={logotick}
              style={{ position: "absolute", width: "35px" }}
              alt=""
            />
          </div>
          <p
            className="text-center mb-0"
            style={{ color: "black", fontSize: "12px" }}
          >
            Scan QR ini sebagai bentuk tanda terima terhadap barang yang sudah
            dikirim. Kunjungi website{" "}
            <span
              style={{
                fontWeight: "600",
                color: "blue",
                fontStyle: "italic",
              }}
            >
              {" "}
              www.dynamiccoffee.id
            </span>{" "}
            , kemudian konfirmasi barang diterima.
          </p>
        </div>
      )}

      <span className="btn btn-ddn w-100" onClick={downloadQR}>
        Download WQR
      </span>
      <br />
    </div>
  );
};

export default Wqr;
