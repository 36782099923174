import React, { useState, useEffect } from "react";
import axios from "axios";
import "./scanner.css";
import { useNavigate, useParams } from "react-router-dom";
import logo from "../../publicAssets/img/ddn-logo.png";
import logotick from "../../publicAssets/img/lt2.svg";
import PinInput from "react-pin-input";

import QRCode from "qrcode";

import Loading from "../../component/loading/loading";
import AlertDdn from "../../component/alert/AlertDdn";

const WqrUser = () => {
  const navigate = useNavigate();
  const { invoice } = useParams();

  const [decryptedText, setDecryptedText] = useState("");
  const [data, setData] = useState("");
  const [acc, setAcc] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const [qrCodeDataURL, setQRCodeDataURL] = useState("");
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  const [openVerifCode, setOpenVerifCode] = useState(false);

  const [verifC, setverifC] = useState([]);

  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);

  const [city, setCity] = useState(null);
  const [prov, setProv] = useState(null);

  const coordinats = `${latitude},${longitude}`;
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    code: "",
    type: "Carton",

    city: city,
    prov: prov,
    coordinat: coordinats,
    status: "scanned",
  });

  console.log(formData);
  console.log(data);

  const verifcode = (data && data.verifcode) || "";

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);

    window.scrollTo(0, 0);
    const fetchCity = async (lat, lon) => {
      try {
        const response = await fetch(
          `https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${lon}&format=json`,
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        const city = data.address.city || "Unknown";
        const postcode = data.address.postcode || "Unknown";
        const provn = data.address.state || "Unknown";
        const cityName = `${city}, ${postcode}`;
        setCity(cityName);
        setProv(provn);
        return cityName;
      } catch (error) {
        console.error("Error fetching city:", error);
        return "Unknown";
      }
    };
    const getLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setLatitude(position.coords.latitude);
            setLongitude(position.coords.longitude);
            fetchCity(position.coords.latitude, position.coords.longitude).then(
              () => setLoading(false),
            );
          },
          (error) => {
            setError(error.message);
          },
        );
      } else {
        setError("Geolocation is not supported by this browser.");
      }
    };

    getLocation();

    if (!loading) {
      const coordinats = `${latitude},${longitude}`;
      setFormData((prevFormData) => ({
        ...prevFormData,
        code: "",
        city: city,
        prov: prov,
        coordinat: coordinats,
        type: "Carton",
        status: "scanned",
      }));
    }
    return () => clearTimeout(timer);
  }, [loading, city, prov, latitude, longitude]);

  const verifCodeClose = () => {
    window.location.reload();
  };

  const verifCodeConfirm = async () => {
    if (verifcode === verifC) {
      const idInv = data.id;

      try {
        const response = await axios.post(`/update-sales-stat/${idInv}`, {
          status: "received",
        });
        window.alert("Pesanan berhasil diverifikasi");
        window.location.reload();
      } catch (error) {
        setLoading(false);
        console.error("Error:", error);
      }
    } else {
      window.alert("Kode Verifikasi Salah");
    }
  };

  useEffect(() => {
    const checkScreenSize = () => {
      if (window.innerWidth < 769) {
        setIsSmallScreen(true);
      } else {
        setIsSmallScreen(false);
      }
    };

    checkScreenSize();

    window.addEventListener("resize", checkScreenSize);

    return () => {
      window.removeEventListener("resize", checkScreenSize);
    };
  }, []);

  useEffect(() => {
    generateQR();
  }, [decryptedText]);

  const generateQR = async () => {
    try {
      const qrDataURL = await QRCode.toDataURL(JSON.stringify(decryptedText), {
        color: {
          light: "#0000", // Transparan
        },
      });
      setQRCodeDataURL(qrDataURL);
    } catch (error) {
      console.error("Error generating QR code:", error);
    }
  };

  useEffect(() => {
    const decryptText = (encryptedText) => {
      try {
        const [encryptedTexts, random] = encryptedText.split("_");
        const decodedText = atob(encryptedTexts);
        return decodedText;
      } catch (error) {
        throw new Error(
          "Failed to decode the text. It might be incorrectly encoded.",
        );
      }
    };

    try {
      const decryptedText = decryptText(invoice);
      setDecryptedText(decryptedText);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  }, [invoice]);

  useEffect(() => {
    if (decryptedText) {
      const fetchData = async () => {
        try {
          const response = await axios.get(`/sales-invoice/${decryptedText}`);
          setData(response.data);
          setAcc(true);
          setLoading(false);
        } catch (error) {
          setAcc(false);
          setLoading(false);
        }
      };
      fetchData();
    }
  }, [decryptedText]);

  const handleSubmit = async () => {
    const recipientPhoneNumber = data.phone.replace(/^0/, "62");

    try {
      const response = await axios.post("/send-verif", {
        recipientPhoneNumber: recipientPhoneNumber,
        verificationCode: data.verifcode,
      });

      if (response.status === 200) {
        setAlertResend("Kode berhasil dikirim");
      } else {
        setAlertResend("Gagal mengirim kode");
      }
    } catch (error) {
      setAlertResend("Gagal mengirim kode");
      console.error("Error sending invoice:", error);
    }
    setTimeout(() => {
      setAlertResend("");
    }, 5000);
    setOpenVerifCode(true);
  };

  const [alertResend, setAlertResend] = useState("");

  const handleResend = async () => {
    const recipientPhoneNumber = data.phone.replace(/^0/, "62");

    try {
      const response = await axios.post("/send-verif", {
        recipientPhoneNumber: recipientPhoneNumber,
        verificationCode: data.verifcode,
      });

      if (response.status === 200) {
        setAlertResend("Kode berhasil dikirim");
      } else {
        setAlertResend("Gagal mengirim kode");
      }
    } catch (error) {
      setAlertResend("Gagal mengirim kode");
      console.error("Error sending invoice:", error);
    }

    setTimeout(() => {
      setAlertResend("");
    }, 5000);
  };

  const [phoneError, setPhoneError] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setPhoneError("");
  };

  const [alert, setAlert] = useState({
    title: "",
    message: "",
    open: false,
    results: true,
  });

  const handleSubmitReward = async (e) => {
    e.preventDefault();
    setFormData(() => ({
      batch: data && data.batch,
      type: data && data.type,
    }));

    if (!formData.phone) {
      setPhoneError("Mohon Masukan Nomor Whatsapp");
      return;
    }
    if (data.status != "received") {
      setPhoneError("Mohon Konfirmasi Pesanan Diterima Terlebih Dahulu");
      return;
    }

    try {
      const idInv = data.id;

      await axios.post("/add-reward", formData);
      await axios.post(`/update-sales-stat/${idInv}`, {
        status: "scanned",
      });

      setAlert({
        title: "BERHASIL DIDAFTARKAN !",
        message: "Kepemilikan produk ini berhasil didaftarkan.",
        open: true,
        results: true,
      });
    } catch (error) {
      setAlert({
        title: "PRODUK INI TIDAK ORIGINAL !!",
        message:
          "Produk ini tidak sesuai dengan kriteria otentikasi produk original, silahkan tukarkan kembali dan laporkan kepada kami.",
        open: true,
        results: false,
      });
      console.error("Error posting data:", error);
    }
  };

  return (
    <div className="section-lp">
      <Loading loadingProps={loading} />
      <AlertDdn
        openAlert={alert.open}
        resultAlert={alert.results}
        title={alert.title}
        message={alert.message}
      />

      {openVerifCode && (
        <div>
          <div className="bg-cardkodeverif"></div>
          <div className="cardkodeverif d-flex justify-content-center align-items-center py-4">
            <h5 className=" text-dark mb-1">Verifikasi Pesanan Diterima </h5>
            <p
              className="text-center text-dark mb-2"
              style={{ fontSize: "10px", opacity: "70%" }}
            >
              Kami telah mengirimkan kode verifikasi ke nomor penerima. <br />{" "}
              Silahkan verifikasi jika benar anda sudah menerima barang ini.
            </p>
            <PinInput
              length={6}
              initialValue=""
              onChange={setverifC}
              type="numeric"
              inputMode="number"
              style={{ padding: "10px" }}
              inputStyle={{ borderColor: "grey" }}
              inputFocusStyle={{ borderColor: "red" }}
              onComplete={(value, index) => {}}
              autoSelect={true}
              regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
            />{" "}
            <div className="d-flex w-100" style={{ padding: "0px 14px" }}>
              <button
                onClick={verifCodeConfirm}
                className="btn-ddn mt-2 mb-2 w-100 me-2"
              >
                Konfirmasi
              </button>

              <button
                onClick={verifCodeClose}
                className="btn btn-secondary mt-2 mb-2 w-100 ms-2"
                style={{ borderRadius: "10px", height: "40px" }}
              >
                Batalkan
              </button>
            </div>
            <p
              onClick={handleResend}
              className="text-center text-dark mt-2 mb-0 pointer"
            >
              Kirim Ulang Kode Verifikasi
            </p>
            <p
              className="text-center text-dark mb-0 pointer"
              style={{ fontSize: "12px", opacity: "60%" }}
            >
              {alertResend}
            </p>
          </div>
        </div>
      )}

      <br />
      <div className="container p-2 ">
        {!acc ? (
          <div>
            <h1 className="text-center text-danger">Akses di tolak</h1>
          </div>
        ) : (
          <div className="inv mx-auto p-4">
            <div className="d-flex align-items-center justify-content-between ">
              <img
                onClick={() => navigate(-1)}
                src={logo}
                style={{ width: "150px" }}
                className="pointer"
              />
              <p
                className="mb-0"
                style={{
                  fontSize: "28px",
                  color: "#D7A64D",
                  fontWeight: "600",
                }}
              >
                INVOICE
              </p>
            </div>
            <br />
            {isSmallScreen && (
              <div className="cardWqr1 mb-5">
                <div
                  style={{
                    position: "relative",
                    width: "225px",
                    height: "225px",
                  }}
                >
                  <img
                    src={qrCodeDataURL}
                    style={{
                      objectFit: "cover",
                      width: "225px",
                      height: "225px",
                    }}
                    alt="QR Code"
                  />
                  <img
                    className="wqrt"
                    src={logotick}
                    style={{ position: "absolute", width: "35px" }}
                    alt=""
                  />
                </div>
              </div>
            )}
            <div className="row mt-4">
              <div className="col-12 col-sm-12 col-md-6">
                <p
                  className="text-dark fw-bold mb-3"
                  style={{ fontSize: "28px" }}
                >
                  Order Details
                </p>
                <table>
                  <tr>
                    <td className="textinv">
                      <p className="text-dark fw-semibold  mb-3">
                        Weigth {"(kg)"}
                      </p>
                    </td>
                    <td style={{ width: "15px" }}>
                      <p className="text-dark fw-semibold mb-3">:</p>
                    </td>

                    <td>
                      <p className="text-dark fw-semibold  mb-3">
                        {data.qty * 1}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td className="textinv">
                      <p className="text-dark fw-semibold  mb-3">
                        Qty {"(SLOP)"}
                      </p>
                    </td>
                    <td style={{ width: "15px" }}>
                      <p className="text-dark fw-semibold mb-3">:</p>
                    </td>

                    <td>
                      <p className="text-dark fw-semibold  mb-3">{data.qty}</p>
                    </td>
                  </tr>
                  <tr>
                    <td className="textinv">
                      <p className="text-dark fw-semibold  mb-3">TXN ID </p>
                    </td>
                    <td style={{ width: "15px" }}>
                      <p className="text-dark fw-semibold mb-3">:</p>
                    </td>

                    <td>
                      <p
                        className=" fw-semibold  mb-3"
                        style={{ color: "#FF535D" }}
                      >
                        {data.invoice}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td className="textinv">
                      <p className="text-dark fw-semibold  mb-3">Status</p>
                    </td>
                    <td style={{ width: "15px" }}>
                      <p className="text-dark fw-semibold mb-3">:</p>
                    </td>

                    <td>
                      <p
                        className="fw-semibold mb-3"
                        style={{
                          textTransform: "uppercase",
                          color: data.status === "received" ? "green" : "blue",
                        }}
                      >
                        {data.status}
                      </p>
                    </td>
                  </tr>
                </table>
              </div>
              <div className="col">
                {!isSmallScreen && (
                  <div className="cardWqr">
                    <div
                      style={{
                        position: "relative",
                        width: "225px",
                        height: "225px",
                      }}
                    >
                      <img
                        src={qrCodeDataURL}
                        style={{
                          objectFit: "cover",
                          width: "225px",
                          height: "225px",
                        }}
                        alt="QR Code"
                      />
                      <img
                        className="wqrt"
                        src={logotick}
                        style={{ position: "absolute", width: "35px" }}
                        alt=""
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <br />
            <div className="">
              <p
                className="text-dark fw-bold mb-3"
                style={{ fontSize: "28px" }}
              >
                Sender Details
              </p>
              <table>
                <tr>
                  <td className="textinv">
                    <p className="text-dark fw-semibold  mb-3">Name</p>
                  </td>
                  <td style={{ width: "15px" }}>
                    <p className="text-dark fw-semibold mb-3">:</p>
                  </td>

                  <td>
                    <p className="text-dark fw-semibold  mb-3">
                      Dynamic Coffee Official
                    </p>
                  </td>
                </tr>
                <tr>
                  <td className="textinv">
                    <p className="text-dark fw-semibold  mb-3">Phone</p>
                  </td>
                  <td style={{ width: "15px" }}>
                    <p className="text-dark fw-semibold mb-3">:</p>
                  </td>
                  <td>
                    <p className="text-dark fw-semibold  mb-3">081111114040</p>
                  </td>
                </tr>
                <tr>
                  <td className="textinv">
                    <p className="text-dark fw-semibold mb-3">Address</p>
                  </td>
                  <td style={{ width: "15px" }}>
                    <p className="text-dark fw-semibold mb-3">:</p>
                  </td>

                  <td>
                    <p className="text-dark fw-semibold mb-3">
                      Jalan Genjer No 37, Jawa Barat, Kota Bandung,Kec. Lengkong
                    </p>
                  </td>
                </tr>

                <tr>
                  <td className="textinv">
                    <p className="text-dark fw-semibold  mb-3">Postal Code</p>
                  </td>
                  <td style={{ width: "15px" }}>
                    <p className="text-dark fw-semibold mb-3">:</p>
                  </td>

                  <td className="">
                    <p className="text-dark fw-semibold  mb-3">081111114040</p>
                  </td>
                </tr>
              </table>
            </div>
            <br />
            <div className="">
              <p
                className="text-dark fw-bold mb-3"
                style={{ fontSize: "28px" }}
              >
                Recipient Details
              </p>
              <table>
                <tr>
                  <td className="textinv">
                    <p className="text-dark fw-semibold  mb-3">Name</p>
                  </td>
                  <td style={{ width: "15px" }}>
                    <p className="text-dark fw-semibold mb-3">:</p>
                  </td>

                  <td>
                    <p className="text-dark fw-semibold  mb-3">{data.name}</p>
                  </td>
                </tr>
                <tr>
                  <td className="textinv">
                    <p className="text-dark fw-semibold  mb-3">Phone</p>
                  </td>
                  <td style={{ width: "15px" }}>
                    <p className="text-dark fw-semibold mb-3">:</p>
                  </td>
                  <td>
                    <p className="text-dark fw-semibold  mb-3">{data.phone}</p>
                  </td>
                </tr>
                <tr>
                  <td className="textinv">
                    <p className="text-dark fw-semibold mb-3">Address</p>
                  </td>
                  <td style={{ width: "15px" }}>
                    <p className="text-dark fw-semibold mb-3">:</p>
                  </td>

                  <td>
                    <p className="text-dark fw-semibold mb-3">
                      {`${data.address}, ${data.city}, ${data.postalcode}`}
                    </p>
                  </td>
                </tr>

                <tr>
                  <td className="textinv">
                    <p className="text-dark fw-semibold  mb-3">Postal Code</p>
                  </td>
                  <td style={{ width: "15px" }}>
                    <p className="text-dark fw-semibold mb-3">:</p>
                  </td>

                  <td className="">
                    <p className="text-dark fw-semibold  mb-3">
                      {data.postalcode}{" "}
                    </p>
                  </td>
                </tr>
              </table>
            </div>
            {data.status === "received" ? (
              <div>
                {" "}
                <p
                  className="mb-1 blink-login"
                  style={{ fontSize: "12px", color: "red" }}
                >
                  {phoneError}
                </p>
                <input
                  onChange={handleChange}
                  className="input-admin d-flex w-100"
                  type="number"
                  placeholder="Nomor Whatsapp"
                  name="phone"
                  style={{ maxWidth: "300px" }}
                />
                <button
                  onClick={handleSubmitReward}
                  className="btn btn-md btn-success px-4 mt-3"
                  style={{ borderRadius: "10px" }}
                >
                  Daftarkan Kepemilikan
                </button>
              </div>
            ) : (
              ""
            )}
            <br />
            {data.status === "sent" && (
              <button
                onClick={handleSubmit}
                className="btn btn-lg d-flex mx-auto btn-ddn my-4 px-5"
              >
                Pesanan Diterima
              </button>
            )}
            <br />
          </div>
        )}
      </div>
      <br />
    </div>
  );
};

export default WqrUser;
