import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import success from "./success.svg";
import fail from "./fail.svg";

import "./alert.css";

const AlertDdn = ({ openAlert, resultAlert, title, message }) => {
  console.log(openAlert);
  const [open, setOpen] = useState(openAlert);
  const [results, setResults] = useState(resultAlert);

  useEffect(() => {
    setOpen(openAlert);
  }, [openAlert]);

  useEffect(() => {
    setResults(resultAlert);
  }, [resultAlert]);

  const closeModal = () => {
    setOpen(false);
    window.location.reload();
  };
  return (
    <div>
      {open && (
        <div>
          {" "}
          <div className="modala p-4">
            <div className="modal-alert">
              <h3
                className={`text-center mb-2 ${results ? "text-s" : "text-f"}`}
              >
                {title ? title : "Terjadi Kesalahan"}
              </h3>
              <p className="text-center text-alert" style={{ color: "gray" }}>
                {" "}
                {message ? message : "Terjadi Kesalahan"}
              </p>
              {results ? (
                <img
                  src={success}
                  className="imgalert mx-auto d-flex"
                  alt="Success"
                />
              ) : (
                <img
                  src={fail}
                  className="imgalert mx-auto d-flex"
                  alt="Fail"
                />
              )}
              <br />
              <button
                onClick={closeModal}
                className={`btn mx-auto d-flex justify-content-center ${
                  results ? "btn-s" : "btn-f"
                }`}
              >
                Kembali
              </button>{" "}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AlertDdn;
