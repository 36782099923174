import React, { useState, useEffect, useRef } from "react";
import { MdQrCodeScanner } from "react-icons/md";
import { HiMenuAlt2 } from "react-icons/hi";

import logo from "../../publicAssets/img/logo-home.svg";
import { useNavigate } from "react-router-dom";
import "./navbar.css";

const Navbar = ({ name }) => {
  const navigate = useNavigate();
  const [navm, setNavm] = useState(false);
  const navRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navRef.current && !navRef.current.contains(event.target)) {
        setNavm(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleNavm = () => {
    setNavm((prevNavm) => !prevNavm); // Menggunakan prevState untuk membalikkan nilai state
  };

  return (
    <div ref={navRef}>
      <div className="w-100 nav-outer" style={{ position: "fixed", top: "0" }}>
        <div className="nav-ddn container p-3 ">
          <div className="d-flex justify-content-between w-100">
            <img src={logo} style={{ width: "120px" }} alt="" />
            <div className="nav-w">
              <div className="d-flex align-items-center">
                <p
                  onClick={() => navigate(`/`)}
                  className="mb-0 mx-3 nav-text"
                  style={{ color: name === "home" ? "#ff535d" : "black" }}
                >
                  Beranda
                </p>
                <p
                  onClick={() => navigate(`/products`)}
                  className="mb-0 mx-3 nav-text"
                  style={{ color: name === "product" ? "#ff535d" : "black" }}
                >
                  Produk
                </p>
                <button
                  onClick={() => navigate(`/scan`)}
                  className="btn btn-dark  px-4 ms-2 d-flex align-items-center"
                  style={{
                    backgroundColor: "transparent",
                    border: "solid 1px #FF535D",
                    borderRadius: "20px",
                  }}
                >
                  <MdQrCodeScanner color="#FF535D" size={18} />
                </button>
                <button
                  onClick={() => navigate(`/login`)}
                  className="btn btn-dark btn-nav px-4 ms-3 d-flex align-items-center"
                >
                  Login
                </button>
              </div>
            </div>
          </div>
          <div className="d-flex">
            <button
              onClick={() => navigate(`/scan`)}
              className=" px-4 me-2 btn-nav-m2 align-items-center"
            >
              <MdQrCodeScanner color="#FF535D" size={30} />
            </button>
            <button
              onClick={toggleNavm}
              className="btn btn-dark btn-nav-m align-items-center"
            >
              <HiMenuAlt2 size={26} />
            </button>
          </div>
        </div>
        <div className={navm ? "nav-m-o-active" : "nav-m-o"}>
          {" "}
          <div className="container">
            <div onClick={() => navigate(`/`)} className="nav-m-inner  pointer">
              <div className="d-flex">
                <p
                  className="mb-0"
                  style={{ color: name === "home" ? "#ff535d" : "black" }}
                >
                  Beranda
                </p>
              </div>
            </div>
            <div
              onClick={() => navigate(`/products`)}
              className="nav-m-inner1  pointer"
            >
              <div className="d-flex">
                <p
                  className="mb-0"
                  style={{ color: name === "product" ? "#ff535d" : "black" }}
                >
                  Produk
                </p>
              </div>
            </div>
            <div
              onClick={() => navigate(`/login`)}
              className="nav-m-inner2  pointer"
            >
              <div className="d-flex align-items-center">
                <p className="mb-0">Login</p>
              </div>
            </div>

            <div
              onClick={() => navigate(`/scan`)}
              className="nav-m-inner3 mb-2 mt-3 pointer"
            >
              <div className="d-flex align-items-center">
                <MdQrCodeScanner
                  className="mx-auto"
                  color="#FF535D"
                  size={60}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
