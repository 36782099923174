import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import DataTable from "react-data-table-component";
import "./history.css";

const History = () => {
  const authToken = localStorage.getItem("token");
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const formatToRupiah = (number) => {
    const formattedNumber = Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
    }).format(number);
    return formattedNumber.replace(/,00$/g, "");
  };
  const columns = [
    {
      name: "STATUS",
      selector: (row) => (row.status ? row.status : "-"),
      sortable: true,
      cell: (row) => {
        let cellStyle = {};
        if (row.status === "finish") {
          cellStyle.color = "#0CAB58";
          cellStyle.fontWeight = "600";
          cellStyle.textTransform = "uppercase";
        } else if (row.status === "approved") {
          cellStyle.color = "#B97F00";
          cellStyle.fontWeight = "600";
          cellStyle.textTransform = "uppercase";
        } else if (row.status === "packing") {
          cellStyle.color = "#0E8BAA";
          cellStyle.fontWeight = "600";
          cellStyle.textTransform = "uppercase";
        } else if (row.status === "sent") {
          cellStyle.color = "#B050EF";
          cellStyle.fontWeight = "600";
          cellStyle.textTransform = "uppercase";
        } else if (row.status === "waiting") {
          cellStyle.color = "gray";
          cellStyle.fontWeight = "600";
          cellStyle.textTransform = "uppercase";
        } else if (row.status === "received") {
          cellStyle.color = "#ff535d ";
          cellStyle.fontWeight = "600";
          cellStyle.textTransform = "uppercase";
        } else if (row.status === "scanned") {
          cellStyle.color = "#ff535d ";
          cellStyle.fontWeight = "600";
          cellStyle.textTransform = "uppercase";
        }
        return <div style={cellStyle}>{row.status ? row.status : "-"}</div>;
      },
    },

    {
      name: "TXN ID",
      selector: (row) => (row.invoice ? row.invoice : "-"),
      sortable: true,
    },

    {
      name: "QTY",
      selector: (row) => (row.qty ? row.qty : "-"),
      sortable: true,
    },
    {
      name: "PRICE",
      selector: (row) =>
        row.price ? formatToRupiah(row.price * row.qty) : "-",
      sortable: true,
    },

    {
      name: "RESI",
      selector: (row) => (row.resi ? row.resi : "-"),
      sortable: true,
    },
    {
      name: "COURIER",
      selector: (row) => (row.kurir ? row.kurir : "-"),
      sortable: true,
    },

    {
      name: "CREATED AT",
      selector: (row) =>
        row.createdAt ? new Date(row.createdAt).toLocaleDateString() : "-",
      sortable: true,
    },
  ];
  const userId = localStorage.getItem("userid");
  console.log(userId);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/sales", {
          headers: {
            Authorization: authToken,
          },
        });
        const filteredData = response.data.filter(
          (item) => item.userid === Number(userId),
        );
        setData(filteredData);
      } catch (error) {
        if (error.response.status === 401) {
          navigate("/login");
        } else if (error.response.status === 403) {
          navigate("/login");
        } else {
          console.log(error.response.status);
        }
      }
    };
    fetchData();
  }, []);

  return (
    <div
      className="tab-pane fade"
      id="pills-history"
      role="tabpanel"
      aria-labelledby="pills-history-tab"
    >
      <div className="card-reseller">
        {" "}
        <DataTable columns={columns} data={data} pagination />
      </div>
    </div>
  );
};

export default History;
