import React, { useState, useEffect } from "react";
import axios from "axios";
import NavbarAdmin from "../../../component/navbarAdmin/Sidebar";
import { IoIosArrowForward } from "react-icons/io";
import AllTable from "./components/AllTable";
import HistoryO from "./components/HistoryO";

const today = new Date().toISOString().split("T")[0];

const History = () => {
  const [sbOn, setSbOn] = useState("");
  const [price, setPrice] = useState("");

  const receiveDataFromSidebar = (data) => {
    const sbData = { data: data === undefined ? true : data };
    setSbOn(sbData.data);
  };
  useEffect(() => {
    function handleResize() {
      if (window.innerWidth > 768) {
        setSbOn(false);
      } else {
        setSbOn(true);
      }
    }

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // ---------------------------------------------------------------------------------

  return (
    <div className="d-flex">
      <NavbarAdmin sbOnProp={receiveDataFromSidebar} sbAct={"order"} />

      <div
        className={`container-fluid ${
          !sbOn ? "content-body" : "content-body-active"
        } p-4`}
      >
        <br />
        <div className="accordion mb-4">
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseBuy"
                aria-expanded="true"
                aria-controls="collapseBuy"
              >
                Order Book
              </button>
            </h2>
            <div
              id="collapseBuy"
              className="accordion-collapse collapse show card-admin2"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body p-2">
                <AllTable />{" "}
              </div>
            </div>
          </div>
        </div>
        <br />
        <div className="accordion mb-4">
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseHis"
                aria-expanded="true"
                aria-controls="collapseHis"
              >
                Order History
              </button>
            </h2>
            <div
              id="collapseHis"
              className="accordion-collapse collapse show card-admin2"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body p-2">
                <HistoryO />{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default History;
