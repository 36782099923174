import React, { useState, useEffect } from "react";
import axios from "axios";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";

const columns = [
  {
    name: "STATUS",
    selector: (row) => (row.status ? row.status : "-"),
    sortable: true,
    cell: (row) => {
      let cellStyle = {};
      if (row.status === "finish") {
        cellStyle.color = "#0CAB58";
        cellStyle.fontWeight = "600";
        cellStyle.textTransform = "uppercase";
      } else if (row.status === "approved") {
        cellStyle.color = "#B97F00";
        cellStyle.fontWeight = "600";
        cellStyle.textTransform = "uppercase";
      } else if (row.status === "packing") {
        cellStyle.color = "#0E8BAA";
        cellStyle.fontWeight = "600";
        cellStyle.textTransform = "uppercase";
      } else if (row.status === "sent") {
        cellStyle.color = "#B050EF";
        cellStyle.fontWeight = "600";
        cellStyle.textTransform = "uppercase";
      } else if (row.status === "scanned") {
        cellStyle.color = "grey";
        cellStyle.fontWeight = "600";
        cellStyle.textTransform = "uppercase";
      }
      return <div style={cellStyle}>{row.status ? row.status : "-"}</div>;
    },
  },

  {
    name: "REWARD CODE",
    selector: (row) => (row.noUndian ? row.noUndian : "-"),
    sortable: true,
  },

  {
    name: "BATCH",
    selector: (row) => (row.batch ? row.batch : "-"),
    sortable: true,
  },

  {
    name: "BUYER",
    selector: (row) => (row.phone ? row.phone : "-"),
    sortable: true,
  },

  {
    name: "TYPE",
    selector: (row) => (row.type ? row.type : "-"),
    sortable: true,
    cell: (row) => {
      let cellStyle = {};
      cellStyle.textTransform = "uppercase";

      return (
        <div style={cellStyle}>
          {row.qty > 20 ? "CARTON" : row.type ? row.type : "-"}
        </div>
      );
    },
  },
  {
    name: "CREATED AT",
    selector: (row) =>
      row.createdAt ? new Date(row.createdAt).toLocaleDateString() : "-",
    sortable: true,
  },
];

const OwnershipEvent = () => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const authToken = localStorage.getItem("token");
  const [dataR, setDataR] = useState([]);

  console.log(dataR);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/reward", {
          headers: {
            Authorization: authToken,
          },
        });
        const responseR = await axios.get("/get-spin-reward", {
          headers: {
            Authorization: authToken,
          },
        });

        setData(response.data);
        setDataR(responseR.data);
      } catch (error) {
        if (error.response.status === 401) {
          navigate("/login");
        } else if (error.response.status === 403) {
          navigate("/login");
        } else {
          console.log(error.response.status);
        }
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <DataTable columns={columns} data={data} pagination />
    </>
  );
};

export default OwnershipEvent;
