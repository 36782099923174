import React, { useState, useEffect } from "react";
import imgs1 from "../../publicAssets/img/ddn-img-home.png";
import imgcards1 from "../../publicAssets/img/img-card-s1.png";
import ddbtextf from "../../publicAssets/img/ddn-fill.png";
import ddbtextb from "../../publicAssets/img/ddn-stroke.png";
import coffee from "../../publicAssets/img/coffe.png";

import BijiSolid from "../../publicAssets/img/biji-solid.png";
import Navbar from "../../component/navbar-lp/Navbars";
import "./home.css";
import { useNavigate } from "react-router-dom";
import Loading from "../../component/loading/loading";
import HomeS2 from "./HomeS2";
import HomeS3 from "./HomeS3";
import HomeS4 from "./HomeS4";
import HomeS5 from "./HomeS5";
import HomeS6 from "./HomeS6";
import HomeBg1 from "../../publicAssets/img/home-bg-1.png";
import HomeBg2 from "../../publicAssets/img/home-bg-2.png";

import Footer from "../../component/footer/Footer";

const Home = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  const navigate = useNavigate();
  return (
    <div>
      <Loading loadingProps={loading} />
      <div className="section-lp">
        <Loading />

        <Navbar name={"home"} />
        <div className="s1">
          <img className="img-s1" src={imgs1} alt="" />
          <img className="bgg-s1" src={HomeBg1} alt="" />
          <img className="bgg-s2" src={HomeBg2} alt="" />

          <img className="biji1-s1" src={BijiSolid} alt="" />
          <img className="biji2-s1" src={BijiSolid} alt="" />
          <img className="biji3-s1" src={BijiSolid} alt="" />
          <img className="biji4-s1" src={BijiSolid} alt="" />
          <img src={ddbtextf} className="text-fill-s1" alt="" />
          <img src={ddbtextb} className="text-border-s1" alt="" />
          <img src={coffee} className="text-coffee-s1" alt="" />
        </div>
        <div className=" container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 mb-3">
              <div className="card-s1">
                <div className="card-s1-inner">
                  <div className="card-s1-front">
                    <img
                      src={imgcards1}
                      className="img-card-s1"
                      alt="KUALITAS TERBAIK"
                    />
                    <p
                      className="text-card-s1 mb-0 w-100"
                      style={{ fontFamily: "David Libre" }}
                    >
                      KUALITAS TERBAIK
                    </p>
                  </div>
                  <div className="card-s1-back  flex-column">
                    <p
                      className="text-card-s1-b mb-2 w-100"
                      style={{ fontFamily: "David Libre" }}
                    >
                      KUALITAS TERBAIK
                    </p>
                    <p className="mb-0 tc2">
                      Setiap sachet kopi kami dibuat dengan biji kopi premium
                      dan bahan tambahan alami, memastikan pengalaman yang kaya
                      dan beraroma tanpa mengorbankan manfaat kesehatan.{" "}
                    </p>{" "}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 mb-3">
              <div className="card-s1">
                <div className="card-s1-inner">
                  <div className="card-s1-front">
                    <img
                      src={imgcards1}
                      className="img-card-s1"
                      alt="CITA RASA NUSANTARA"
                    />
                    <p
                      className="text-card-s1 mb-0 w-100"
                      style={{ fontFamily: "David Libre" }}
                    >
                      CITA RASA NUSANTARA
                    </p>
                  </div>
                  <div className="card-s1-back  flex-column">
                    <p
                      className="text-card-s1-b mb-2 w-100"
                      style={{ fontFamily: "David Libre" }}
                    >
                      CITA RASA NUSANTARA{" "}
                    </p>
                    <p className="mb-0 tc2">
                      Kami menggabungkan berbagai biji kopi pilihan dari seluruh
                      Indonesia untuk menciptakan rasa yang autentik dan
                      memuaskan. Setiap tegukan membawa Anda dalam perjalanan ke
                      kebun-kebun kopi terbaik di tanah air{" "}
                    </p>{" "}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 mb-3">
              <div className="card-s1">
                <div className="card-s1-inner">
                  <div className="card-s1-front">
                    <img
                      src={imgcards1}
                      className="img-card-s1"
                      alt="HARGA BERSAHABAT"
                    />
                    <p
                      className="text-card-s1 mb-0 w-100"
                      style={{ fontFamily: "David Libre" }}
                    >
                      HARGA BERSAHABAT
                    </p>
                  </div>
                  <div className="card-s1-back flex-column">
                    <p
                      className="text-card-s1-b mb-2 w-100"
                      style={{ fontFamily: "David Libre" }}
                    >
                      HARGA BERSAHABAT
                    </p>
                    <p className="mb-0 tc2">
                      Dynamic Coffee menawarkan berbagai pilihan kemasan dengan
                      harga yang bersahabat. Mulai dari paket coba-coba hingga
                      paket besar, kami memastikan bahwa setiap orang dapat
                      menikmati manfaat Dynamic Coffee tanpa harus mengeluarkan
                      biaya yang besar.{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>{" "}
        </div>
        <br />
        <br />
        <HomeS2 />
        <br />
        <br />
        <HomeS3 />
        <br />
        <br />
        <br />
        <HomeS4 />
        <br />
        <br />
        <HomeS5 />
        <br />
        <br />
        <HomeS6 />
        <br />
        <br />
        <Footer />
      </div>
    </div>
  );
};

export default Home;
