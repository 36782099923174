import React, { useEffect } from "react";
import img1 from "../../publicAssets/img/s5-1.png";
import img2 from "../../publicAssets/img/s5-2.png";
import "./home.css";

const HomeS5 = () => {
  useEffect(() => {
    const animateCounter = () => {
      const targetElements = document.querySelectorAll(".tit-s5");
      const options = {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
      };

      const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            observer.unobserve(entry.target); // Stop observing element after animation
            startCounter(entry.target);
          }
        });
      }, options);

      targetElements.forEach((element) => {
        observer.observe(element);
      });
    };

    const startCounter = (element) => {
      const target = +element.getAttribute("data-target");
      let count = 0;
      const increment = Math.ceil(target / 100);

      const updateCount = () => {
        if (count < target) {
          count += increment;
          element.innerText = formatNumber(count);
          setTimeout(updateCount, 10); // Adjust the speed of the counter animation
        } else {
          element.innerText = formatNumber(target);
        }
      };

      const formatNumber = (number) => {
        if (number >= 1000000) {
          return (number / 1000000).toFixed(0) + " JT+";
        } else if (number >= 1000) {
          return (number / 1000).toFixed(0) + " K+";
        } else {
          return number.toString();
        }
      };

      updateCount();
    };

    const sectionObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            animateCounter(); // Start animation whenever HomeS5 is in viewport
          }
        });
      },
      { threshold: 0 },
    );

    const section = document.getElementById("s5");
    if (section) {
      sectionObserver.observe(section);
    }

    return () => {
      sectionObserver.disconnect();
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const section = document.getElementById("s5");
      const animatedElements = section.querySelectorAll(
        ".animated-s5,.animated-s5b",
      );

      const options = {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
      };

      const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
          } else {
            entry.target.classList.remove("visible");
          }
        });
      }, options);

      animatedElements.forEach((element) => {
        observer.observe(element);
      });
    };

    handleScroll();
  }, []);

  return (
    <div id="s5" className="container" style={{ position: "relative" }}>
      <br />
      <div className="row">
        <div className="mb-5 col-12 col-sm-12 col-md-6 animated-s5">
          <div className="card-s5 d-flex flex-column align-items-end justify-content-center p-3 px-4">
            <p className="tit-s5 mb-0" data-target="3000"></p>
            <p className="stit-s5">CUSTOMER SETIA</p>
            <img src={img1} className="img-card-s5-1" alt="" />
          </div>
        </div>
        <div className="mb-5 col-12 col-sm-12 col-md-6 animated-s5b">
          <div className="card-s5-2 d-flex flex-column align-items-start justify-content-center p-3 px-4">
            <p className="tit-s5 mb-0" data-target="12000"></p>
            <p className="stit-s5">SACHET TERJUAL DI MARKET</p>
            <img src={img2} className="img-card-s5-2" alt="" />
          </div>
        </div>
      </div>
      <br />
    </div>
  );
};

export default HomeS5;
