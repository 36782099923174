import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { IoIosClose } from "react-icons/io";
import "./reward.css";
import img from "./imgr.png";
import logo from "./logo.png";

import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const Leaderboard = () => {
  const [data, setData] = useState([]);
  const [paginationStartIndex, setPaginationStartIndex] = useState(0);
  const navigate = useNavigate();
  const authToken = localStorage.getItem("token");

  const pdfRef = useRef();

  const downloadPdf = () => {
    const input = pdfRef.current;
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");

      // Buat link untuk mendownload gambar
      const link = document.createElement("a");
      link.href = imgData;
      link.download = "download.png";
      link.click();
    });
  };

  const columns = [
    {
      name: "RANK",
      selector: (row, index) => index + 1 + paginationStartIndex,
      sortable: true,
    },

    {
      name: "BUYER",
      selector: (row) => (row.phone ? row.phone : "-"),
      sortable: true,
    },

    {
      name: "TYPE",
      selector: (row) => (row.type ? row.type : "-"),
      sortable: true,
      cell: (row) => {
        let cellStyle = {};
        cellStyle.textTransform = "uppercase";

        return <div style={cellStyle}>{row.type ? row.type : "-"}</div>;
      },
    },
    {
      name: "TOTAL QTY",
      selector: (row) => (row.qtyTotal ? row.qtyTotal : "-"),
      sortable: true,
    },
    {
      name: "CITY",
      selector: (row) => (row.city ? row.city : "-"),
      sortable: true,
    },
    {
      name: "POSTALCODE",
      selector: (row) => (row.postalcode ? row.postalcode : "-"),
      sortable: true,
    },
    {
      name: "CREATED AT",
      selector: (row) =>
        row.createdAt ? new Date(row.createdAt).toLocaleDateString() : "-",
      sortable: true,
    },
    {
      name: "ACTION",
      cell: (row, index) => (
        <div>
          <span
            className="pointer"
            style={{ width: "100px", color: "#FF535D" }}
            onClick={() => openModal(row, index)}
          >
            DETAIL
          </span>
        </div>
      ),
      sortable: true,
    },
  ];

  const [odData, setOdData] = useState({
    username: "",
    phone: "",
    address: "",
    city: "",
    postalcode: "",
    date: "",
    role: 0,
    rank: 0,
    qtyTotal: "",
  });

  const openModal = (rowData, index) => {
    setShowModal(true);
    setOdData({
      name: rowData.name || "",
      phone: rowData.phone || "",
      address: rowData.address || "",
      postalcode: rowData.postalcode || "",
      date: rowData.createdAt || "",
      role: rowData.role || 0,
      qtyTotal: rowData.qtyTotal || "",
      rank: index + 1 + paginationStartIndex || "",
    });
  };

  const closeModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/sales", {
          headers: {
            Authorization: authToken,
          },
        });
        const groupedData = groupDataByPhoneNumber(response.data);
        const processedData = processGroupedData(groupedData);
        const sortedData = sortDataByQtyTotal(processedData);
        setData(sortedData);
      } catch (error) {
        if (error.response.status === 401) {
          navigate("/login");
        } else if (error.response.status === 403) {
          navigate("/login");
        } else {
          console.log(error.response.status);
        }
      }
    };
    fetchData();
  }, []);

  const groupDataByPhoneNumber = (data) => {
    const groupedData = {};
    data.forEach((item) => {
      const phoneNumber = item.phone;
      if (!groupedData[phoneNumber]) {
        groupedData[phoneNumber] = [];
      }
      groupedData[phoneNumber].push(item);
    });
    return groupedData;
  };

  const processGroupedData = (groupedData) => {
    const processedData = [];
    for (const phoneNumber in groupedData) {
      if (groupedData.hasOwnProperty(phoneNumber)) {
        const latestItem = groupedData[phoneNumber][0]; // Get latest item for each phone number
        const totalQty = groupedData[phoneNumber].reduce(
          (acc, curr) => acc + curr.qty,
          0,
        ); // Calculate total qty
        latestItem.qtyTotal = totalQty; // Add total qty to latest item
        processedData.push(latestItem); // Push latest item to processed data
      }
    }
    return processedData;
  };

  const sortDataByQtyTotal = (data) => {
    return data.sort((a, b) => b.qty - a.qty);
  };

  const handlePageChange = (page) => {
    setPaginationStartIndex((page - 1) * 10);
  };
  const formatDate = (dateString) => {
    const options = { day: "2-digit", month: "2-digit", year: "2-digit" };
    return new Date(dateString).toLocaleDateString("id-ID", options);
  };

  const [showModal, setShowModal] = useState(false);

  return (
    <>
      {showModal && (
        <div>
          <div className="modalh flex-column">
            <div
              className="modal-content-product-l m-0"
              style={{ minWidth: "300px", borderRadius: "10px" }}
            >
              <h5 className="text-center">OWNERSHIP DETAILS</h5>
              <hr />
              <div className="d-flex justify-content-between mb-3">
                <p className="mb-0" style={{ opacity: "50%" }}>
                  USERNAME
                </p>
                <p className="mb-0 text-l">{odData.name}</p>
              </div>
              <div className="d-flex justify-content-between mb-3">
                <p className="mb-0" style={{ opacity: "50%" }}>
                  PHONE
                </p>
                <p className="mb-0 text-l">{odData.phone}</p>
              </div>
              <div className="d-flex justify-content-between mb-3">
                <p className="mb-0" style={{ opacity: "50%" }}>
                  ADDRESS
                </p>
                <p className="mb-0 text-l">{odData.address}</p>
              </div>

              <div className="d-flex justify-content-between mb-3">
                <p className="mb-0" style={{ opacity: "50%" }}>
                  POSTAL CODE
                </p>
                <p className="mb-0 text-l">{odData.postalcode}</p>
              </div>

              <hr />

              <div className="d-flex justify-content-between mb-3">
                <p className="mb-0" style={{ opacity: "50%" }}>
                  ORDER DATE
                </p>
                <p className="mb-0 text-l">{formatDate(odData.date)}</p>
              </div>
              <br />
              <div className="d-flex justify-content-between">
                <button
                  className="btn btn-secondary"
                  onClick={closeModal}
                  style={{ borderRadius: "10px" }}
                >
                  <IoIosClose size={30} />
                </button>
                <button className="btn-ddn " onClick={downloadPdf}>
                  SAVE & DOWNLOAD
                </button>
              </div>
            </div>
            <div
              className="modal-content-product-l hidden-content"
              style={{
                minWidth: "300px",
                overflow: "hidden",
                boxShadow: "none",
                border: "none",
                maxHeight: "650px",
              }}
              ref={pdfRef}
            >
              <img src={img} className="w-100 " />
              <br />
              <br />
              <div className="d-flex justify-content-between mb-3">
                <p className="mb-0" style={{ opacity: "50%" }}>
                  USERNAME
                </p>
                <p className="mb-0 text-l">{odData.name}</p>
              </div>
              <div className="d-flex justify-content-between mb-3">
                <p className="mb-0" style={{ opacity: "50%" }}>
                  ROLE
                </p>
                <p className="mb-0 text-l">
                  {odData.role === 0
                    ? "USER"
                    : odData.role === 2
                    ? "DISTRIBUTOR"
                    : odData.role === 1
                    ? "ADMIN"
                    : ""}{" "}
                </p>
              </div>
              <div className="d-flex justify-content-between mb-3">
                <p className="mb-0" style={{ opacity: "50%" }}>
                  TOTAL ACCOUMULATION
                </p>
                <p className="mb-0 text-l">{odData.qtyTotal} SLOP</p>
              </div>
              <div className="d-flex justify-content-between mb-3">
                <p className="mb-0" style={{ opacity: "50%" }}>
                  LEADERBOARD RANK
                </p>
                <p className="mb-0 text-l" style={{ color: "#2EBADB" }}>
                  #{odData.rank}
                </p>
              </div>
              <br />
              <img
                src={logo}
                className="d-flex mx-auto"
                style={{ width: "200px" }}
              />
            </div>
          </div>
        </div>
      )}

      <DataTable
        columns={columns}
        data={data}
        pagination
        paginationTotalRows={data.length}
        onChangePage={handlePageChange}
      />
    </>
  );
};

export default Leaderboard;
