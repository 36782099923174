import React, { useState, useEffect, useRef } from "react";
import "./reseller.css";
import { FaArrowRight } from "react-icons/fa6";
import logoTick from "../../component/navbarAdmin/asset-sb/logoTick.svg";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { IoClose } from "react-icons/io5";
import { BiShow, BiHide } from "react-icons/bi";

const Profile = () => {
  const authToken = localStorage.getItem("token");
  const navigate = useNavigate();

  const userIds = localStorage.getItem("userid");
  const [data, setData] = useState("");
  console.log(data);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`/user/${userIds}`, {
          headers: {
            Authorization: authToken,
          },
        });

        setData(response.data);
      } catch (error) {
        if (error.response.status === 401) {
          navigate("/login");
        } else if (error.response.status === 403) {
          navigate("/login");
        } else {
          console.log(error.response.status);
        }
      }
    };
    fetchData();
  }, []);

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("username");
    localStorage.removeItem("userid");

    navigate("/login");
  };

  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const [formData, setFormData] = useState({
    userId: userIds,
    oldPassword: "",
    newPassword: "",
    newPasswordc: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const loginSubmit = async (e) => {
    e.preventDefault();

    if (
      !formData.oldPassword ||
      !formData.newPassword ||
      !formData.newPasswordc
    ) {
      alert("Mohon lengkapi form");
      return;
    }

    if (formData.newPassword.length < 6 || formData.newPasswordc.length < 6) {
      alert("Password baru harus lebih dari 6 karakter");
      return;
    }

    if (formData.newPassword != formData.newPasswordc) {
      alert("Konfirmasi password salah");
      return;
    }

    try {
      const response = await axios.post("/change-password", formData);
      alert(response.data.message);
      setFormData({
        userId: userIds,
        oldPassword: "",
        newPassword: "",
        newPasswordc: "",
      });
      setShowModal(false);
    } catch (error) {
      alert(error.response.data.message);
    }
  };

  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const [showPasswordc, setShowPasswordc] = useState(false);
  const togglePasswordVisibilityc = () => {
    setShowPasswordc(!showPasswordc);
  };

  const [showPasswordck, setShowPasswordck] = useState(false);
  const togglePasswordVisibilityck = () => {
    setShowPasswordck(!showPasswordck);
  };

  return (
    <div
      className="tab-pane fade "
      id="pills-profile"
      role="tabpanel"
      aria-labelledby="pills-profile-tab"
    >
      <div className="card-reseller">
        <h3 className="mb-0" style={{ opacity: "70%" }}>
          General
        </h3>
        <hr className="w-100 mb-3 mt-2" style={{ color: "white" }} />

        <table className="w-100">
          <tr>
            <td style={{ width: "140px" }}>
              <p style={{ width: "140px" }}>Username</p>
            </td>
            <td style={{ width: "10px" }}>
              <p className=" text-center fw-normal">:</p>
            </td>
            <td className="">
              <p className="w-100 ms-2">{data && data.username}</p>
            </td>
          </tr>
          <tr>
            <td style={{ width: "140px" }}>
              <p style={{ width: "140px" }}>Email</p>
            </td>
            <td style={{ width: "10px" }}>
              <p className=" text-center fw-normal">:</p>
            </td>
            <td className="">
              <p className="w-100 ms-2">{data && data.email}</p>
            </td>
          </tr>
          <tr>
            <td style={{ width: "140px" }}>
              <p style={{ width: "140px" }}>Role</p>
            </td>
            <td style={{ width: "10px" }}>
              <p className=" text-center fw-normal">:</p>
            </td>
            <td className="">
              <p className="w-100 ms-2">
                {data.role === 2 ? "Distributor" : "-"}
              </p>
            </td>
          </tr>
        </table>
        <br />
        <h3 className="mb-0" style={{ opacity: "70%" }}>
          Location
        </h3>
        <hr className="w-100 mb-3 mt-2" style={{ color: "white" }} />

        <table className="w-100">
          <tr>
            <td style={{ width: "140px" }}>
              <p style={{ width: "140px" }}>Address</p>
            </td>
            <td style={{ width: "10px" }}>
              <p className=" text-center fw-normal">:</p>
            </td>
            <td className="">
              <p className="w-100 ms-2">{data && data.address}</p>
            </td>
          </tr>
          <tr>
            <td style={{ width: "140px" }}>
              <p style={{ width: "140px" }}>City</p>
            </td>
            <td style={{ width: "10px" }}>
              <p className=" text-center fw-normal">:</p>
            </td>
            <td className="">
              <p className="w-100 ms-2">{data && data.city}</p>
            </td>
          </tr>
          <tr>
            <td style={{ width: "140px" }}>
              <p style={{ width: "140px" }}>Postal Code</p>
            </td>
            <td style={{ width: "10px" }}>
              <p className=" text-center fw-normal">:</p>
            </td>
            <td className="">
              <p className="w-100 ms-2">{data && data.postalcode}</p>
            </td>
          </tr>
        </table>
        <br />
        <h3 className="mb-0" style={{ opacity: "70%" }}>
          Account
        </h3>
        <hr className="w-100 mb-3 mt-2" style={{ color: "white" }} />
        <table className="w-100">
          <tr>
            <td style={{ width: "140px" }}>
              <p style={{ width: "140px" }}>Nomor Rekening</p>
            </td>
            <td style={{ width: "10px" }}>
              <p className=" text-center fw-normal">:</p>
            </td>
            <td className="">
              <p className="w-100 ms-2">{data && data.norek}</p>
            </td>
          </tr>
          <tr>
            <td style={{ width: "140px" }}>
              <p style={{ width: "140px" }}>Bank</p>
            </td>
            <td style={{ width: "10px" }}>
              <p className=" text-center fw-normal">:</p>
            </td>
            <td className="">
              <p className="w-100 ms-2" style={{ textTransform: "uppercase" }}>
                {data && data.bank}
              </p>
            </td>
          </tr>
          <tr>
            <td style={{ width: "140px" }}>
              <p style={{ width: "140px" }}>Nama Rekening</p>
            </td>
            <td style={{ width: "10px" }}>
              <p className=" text-center fw-normal">:</p>
            </td>
            <td className="">
              <p className="w-100 ms-2" style={{ textTransform: "uppercase" }}>
                {data && data.namerek}
              </p>
            </td>
          </tr>
        </table>

        <br />
        <div className="d-flex justify-content-between">
          <button
            className="btn-ddn w-100 me-2"
            style={{ maxWidth: "200px" }}
            onClick={logout}
          >
            Keluar
          </button>
          <button
            className="btn-ddn w-100 ms-2"
            style={{ maxWidth: "200px" }}
            onClick={openModal}
          >
            Ganti Password
          </button>
        </div>
      </div>
      <br />
      {showModal && (
        <div>
          <div
            className="modalh"
            style={{ backgroundColor: "rgba(0, 0, 0, 0.6)" }}
          >
            <div
              className="modal-content-product-h"
              style={{ minWidth: "300px" }}
            >
              <div className="d-flex align-items-center justify-content-between">
                <h3 className="">Ganti Password</h3>
                <div className="pointer" onClick={closeModal}>
                  <IoClose size={26} color="red" />
                </div>
              </div>
              <p
                className="mb-1 mt-3"
                style={{ fontSize: "12px", opacity: "60%" }}
              >
                Password Saat ini
              </p>
              <div
                className="input-admin d-flex p-0 w-100"
                style={{ backgroundColor: "white" }}
              >
                <input
                  onChange={handleChange}
                  name="oldPassword"
                  type={showPassword ? "text" : "password"}
                  className="input-admin w-100"
                  autocomplete="off"
                  placeholder="Min 6 Karakter"
                />
                <div
                  className="pointer d-flex justify-content-center align-items-center me-3"
                  onClick={togglePasswordVisibility}
                >
                  {showPassword ? (
                    <BiHide color="black" size={20} />
                  ) : (
                    <BiShow color="black" size={20} />
                  )}
                </div>
              </div>{" "}
              <br />
              {/* ------- */}
              <p
                className="mb-1 mt-3"
                style={{ fontSize: "12px", opacity: "60%" }}
              >
                Password Baru
              </p>
              <div
                className="input-admin d-flex p-0 w-100"
                style={{ backgroundColor: "white" }}
              >
                <input
                  onChange={handleChange}
                  name="newPassword"
                  type={showPasswordc ? "text" : "password"}
                  className="input-admin w-100"
                  autocomplete="off"
                  placeholder="Min 6 Karakter"
                />
                <div
                  className="pointer d-flex justify-content-center align-items-center me-3"
                  onClick={togglePasswordVisibilityc}
                >
                  {showPasswordc ? (
                    <BiHide color="black" size={20} />
                  ) : (
                    <BiShow color="black" size={20} />
                  )}
                </div>
              </div>{" "}
              {/* ------- */}
              <p
                className="mb-1 mt-3"
                style={{ fontSize: "12px", opacity: "60%" }}
              >
                Konfirmasi Password Baru
              </p>
              <div
                className="input-admin d-flex p-0 w-100"
                style={{ backgroundColor: "white" }}
              >
                <input
                  onChange={handleChange}
                  name="newPasswordc"
                  type={showPasswordck ? "text" : "password"}
                  className="input-admin w-100"
                  autocomplete="off"
                  placeholder="Min 6 Karakter"
                />
                <div
                  className="pointer d-flex justify-content-center align-items-center me-3"
                  onClick={togglePasswordVisibilityck}
                >
                  {showPasswordck ? (
                    <BiHide color="black" size={20} />
                  ) : (
                    <BiShow color="black" size={20} />
                  )}
                </div>
              </div>{" "}
              <br />
              <button onClick={loginSubmit} className="btn-ddn w-100">
                Lanjutkan
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Profile;
