import React, { useState, useEffect } from "react";
import axios from "axios";
import NavbarAdmin from "../../../component/navbarAdmin/Sidebar";
import { IoIosArrowForward } from "react-icons/io";
import imgP from "./layoutBanner.png";
import "./adminInput.css";

const InputPromotions = () => {
  const [promo, setPromo] = useState([]);

  useEffect(() => {
    const getPromo = async () => {
      try {
        const response = await axios.get("/promo");
        setPromo(response.data);
      } catch (error) {
        console.log(error.message);
      }
    };
    getPromo();
  }, []);

  // ---------------------------------------------------------------------------------

  const [formData, setFormData] = useState([]);

  const handleChange = (e, promoId) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [promoId]: {
        ...prevFormData[promoId],
        [name]: value,
      },
    }));
  };

  const handleSubmit = async (promoa) => {
    if (window.confirm("Make sure the data is correct")) {
      try {
        const response = await axios.post(
          `/edit-promo/${promoa}`,
          formData[promoa],
        );
        alert("Promo Updated");
        window.location.reload();
      } catch (error) {
        console.error("Error posting data:", error);
      }
    }
  };

  const handleSubmitImg = async (e, promos) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("image", e.target.files[0]);

    if (window.confirm("Pastikan data sudah benar")) {
      try {
        const response = await axios.post(
          `/set-promo-image/${promos}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          },
        );
        alert("Gambar berhasil diunggah");

        window.location.reload();
      } catch (error) {
        console.error("Error posting data:", error);
      }
    }
  };

  const [addPromo, setAddPromo] = useState(false);

  const openAddPromo = () => {
    setAddPromo(true);
  };

  const closeAddPromo = () => {
    setAddPromo(false);
  };

  const [formDataAdd, setFormDataAdd] = useState({
    image: "",
    desc: "",
  });

  console.log(formDataAdd);

  const handleChangeAdd = (e) => {
    const { name, value } = e.target;
    setFormDataAdd({
      ...formDataAdd,
      [name]: value,
    });
    console.log(formDataAdd.image);
  };

  const handleChangeImage = (e) => {
    const file = e.target.files[0];
    setFormDataAdd((prevFormDataAdd) => ({
      ...prevFormDataAdd,
      image: file,
    }));
  };

  const AddPromo = async () => {
    if (window.confirm("Make sure the data is correct")) {
      try {
        const response = await axios.post(`/set-promo`, formDataAdd, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        alert("Promo Updated");
        window.location.reload();
      } catch (error) {
        console.error("Error posting data:", error);
      }
    }
  };

  const DetelePromo = async (id) => {
    if (window.confirm("Make sure the data is correct")) {
      try {
        const response = await axios.post(`/delete-promo/${id}`);
        alert("Promo Deleted");
        window.location.reload();
      } catch (error) {
        console.error("Error posting data:", error);
      }
    }
  };

  return (
    <div>
      <div
        onClick={openAddPromo}
        className="btn btn-success pointer text-center mb-3"
        style={{
          maxWidth: "200px",
          height: "40px",
          borderRadius: "10px",
        }}
      >
        Add New Promotion
      </div>{" "}
      {addPromo && (
        <div>
          <div className="blocker-addPromo"></div>
          <div className="p-3 addPromo">
            <div className="mb-3">
              <div
                className="p-2 w-100 h-100 d-flex justify-content-center align-items-center"
                style={{ backgroundColor: "grey" }}
              >
                <label htmlFor={`imageUploads`} className="image-upload-label">
                  <img
                    src={
                      formDataAdd.image
                        ? URL.createObjectURL(formDataAdd.image)
                        : imgP
                    }
                    className="w-100 pointer"
                    style={{
                      maxWidth: "1000px",
                      maxHeight: "300px",
                      objectFit: "contain",
                      caretColor: "transparent",
                    }}
                    alt=""
                  />
                  <input
                    id={`imageUploads`}
                    type="file"
                    style={{ display: "none" }}
                    onChange={handleChangeImage}
                    name="image"
                  />
                </label>
              </div>
            </div>
            <div className="row">
              <div className="col mb-3">
                <div className="mb-2">
                  <p className="mb-1" style={{ fontSize: "12px" }}>
                    Description
                  </p>
                  <input
                    style={{ caretColor: "white" }}
                    name="desc"
                    className="input-admin w-100"
                    type="text"
                    onChange={handleChangeAdd}
                    value={formDataAdd.desc}
                  />
                </div>

                <div className="d-flex justify-content-between">
                  <div
                    onClick={AddPromo}
                    className="btn-ddn pointer text-center"
                    style={{ maxWidth: "200px" }}
                  >
                    Create
                  </div>{" "}
                  <div
                    onClick={closeAddPromo}
                    className="btn btn-danger pointer text-center"
                    style={{ maxWidth: "200px", borderRadius: "10px" }}
                  >
                    Close
                  </div>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div
        className="accordion mb-4"
        style={{ backgroundColor: "transparent" }}
      >
        {promo.map((promoItem, index) => (
          <div className="accordion-item mb-3">
            <h2 className="accordion-header">
              <button
                className="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target={`#promo${index + 1}`}
                aria-expanded="true"
                aria-controls={`promo${index + 1}`}
              >
                Promotion {index + 1}
              </button>
            </h2>
            <div
              id={`promo${index + 1}`}
              className="accordion-collapse collapse card-admin2 mt-3 p-0"
              style={{ borderRadius: "10px", backgroundColor: "transparent" }}
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body p-0">
                <div className="mb-3">
                  <div
                    className="p-2 w-100 h-100 d-flex justify-content-center align-items-center"
                    style={{ backgroundColor: "grey" }}
                  >
                    <label
                      htmlFor={`imageUploads${index}`}
                      className="image-upload-label"
                    >
                      <img
                        src={
                          promoItem && promoItem.image
                            ? process.env.REACT_APP_API_URL + promoItem.image
                            : imgP
                        }
                        className="w-100 pointer"
                        style={{
                          maxWidth: "1000px",
                          maxHeight: "300px",
                          objectFit: "contain",
                          caretColor: "transparent",
                        }}
                        alt=""
                      />
                      <input
                        id={`imageUploads${index}`}
                        type="file"
                        onChange={(e) => handleSubmitImg(e, promoItem.id)}
                        style={{ display: "none" }}
                      />
                    </label>
                  </div>
                </div>
                <div className="row">
                  <div className="col mb-3">
                    <div className="mb-2">
                      <p className="mb-1" style={{ fontSize: "12px" }}>
                        Url Link
                      </p>
                      <input
                        style={{ caretColor: "white" }}
                        name="desc"
                        onChange={(e) => handleChange(e, promoItem.id)}
                        className="input-admin w-100 mb-2"
                        type="text"
                        value={formData.desc}
                        defaultValue={promoItem.desc}
                      />
                    </div>
                    <div className="d-flex justify-content-between">
                      <div
                        onClick={() => handleSubmit(promoItem.id)}
                        className="btn-ddn pointer text-center"
                        style={{ maxWidth: "200px" }}
                      >
                        Update
                      </div>{" "}
                      <div
                        onClick={() => DetelePromo(promoItem.id)}
                        className="btn btn-danger pointer text-center"
                        style={{
                          maxWidth: "200px",
                          height: "40px",
                          borderRadius: "10px",
                        }}
                      >
                        Delete
                      </div>{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default InputPromotions;
