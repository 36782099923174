import React, { useState, useEffect } from "react";
import axios from "axios";
import NavbarAdmin from "../../../component/navbarAdmin/Sidebar";
import CTable from "./components/CTable";
import AllTable from "./components/AllTable";
import Modal from "react-modal";
import { IoClose } from "react-icons/io5";
import { IoIosArrowForward } from "react-icons/io";
import "./product.css";
import LoadingAdm from "../../loadAdmin/loadingAdm";

const today = new Date().toISOString().split("T")[0];

const Product = () => {
  const [sbOn, setSbOn] = useState("");
  const [loading, setLoading] = useState(false);

  const receiveDataFromSidebar = (data) => {
    const sbData = { data: data === undefined ? true : data };
    setSbOn(sbData.data);
  };
  useEffect(() => {
    function handleResize() {
      if (window.innerWidth > 768) {
        setSbOn(false);
      } else {
        setSbOn(true);
      }
    }

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // ---------------------------------------------------------------------------------

  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handlOpenModal = () => {
    setShowModal(true);
  };

  const [formData, setFormData] = useState({
    batch: "",
    production: today,
    expire: today,
    qty: "",
  });
  const [response, setResponse] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (
      !formData.batch ||
      !formData.production ||
      !formData.expire ||
      !formData.qty
    ) {
      alert("All data must be filled in");
      return;
    }

    if (window.confirm("Make sure the data is correct")) {
      try {
        const { qty, ...rest } = formData;
        for (let i = 0; i < parseInt(qty); i++) {
          const response = await axios.post("/add-product", rest);
          console.log("Product added:", response.data);
        }
        window.location.reload();
      } catch (error) {
        console.error("Error posting data:", error);
        setResponse({ error: "An error occurred while sending the data" });
      }
    }
  };

  return (
    <div className="d-flex">
      <LoadingAdm loadingProps={loading} />

      <NavbarAdmin sbOnProp={receiveDataFromSidebar} sbAct={"product"} />
      <Modal
        className="modalProd"
        isOpen={showModal}
        onRequestClose={handleCloseModal}
      >
        <div className="card-admin p-4 mx-auto" style={{ maxWidth: "400px" }}>
          <div>
            <IoClose className="pointer" size={20} onClick={handleCloseModal} />
          </div>
          <h3 className="my-2 text-center">Generate QR</h3>
          <p className="text-center">Add Product Details to Generate QR</p>
          <br />
          <div className="d-flex align-items-center mb-3">
            <p className="mb-1" style={{ opacity: "60%", width: "200px" }}>
              QTY
            </p>
            <input
              type="text"
              className="w-100 input-admin"
              placeholder="Example : 1"
              name="qty"
              value={formData.qty}
              onChange={handleChange}
            />
          </div>

          <div className="d-flex align-items-center mb-3">
            <p className="mb-1" style={{ opacity: "60%", width: "200px" }}>
              BATCH
            </p>
            <input
              type="text"
              className="w-100 input-admin"
              placeholder="Example : 1"
              name="batch"
              value={formData.batch}
              onChange={handleChange}
            />
          </div>

          <div className="d-flex align-items-center mb-3">
            <p className="mb-1" style={{ opacity: "60%", width: "200px" }}>
              MGF.DATE
            </p>

            <input
              type="date"
              className="w-100 input-admin"
              name="production"
              value={formData.production}
              onChange={handleChange}
            />
          </div>

          <div className="d-flex align-items-center mb-3">
            <p className="mb-1" style={{ opacity: "60%", width: "200px" }}>
              EXP.DATE
            </p>

            <input
              type="date"
              className="w-100 input-admin"
              name="expire"
              value={formData.expire}
              onChange={handleChange}
            />
          </div>

          <button
            style={{ maxWidth: "300px" }}
            className="btn-ddn mx-auto d-flex justify-content-center w-100 mt-5 mb-2"
            onClick={handleSubmit}
          >
            Generate New
          </button>
        </div>
      </Modal>

      <div
        className={`container-fluid ${
          !sbOn ? "content-body" : "content-body-active"
        } p-4`}
      >
        <button
          style={{ caretColor: "transparent" }}
          onClick={handlOpenModal}
          className="btn-generateqr d-flex align-items-center"
        >
          Generate New QR
          <IoIosArrowForward className="ms-2" />
        </button>

        <br />
        <div className="accordion">
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseCarton"
                aria-expanded="true"
                aria-controls="collapseCarton"
              >
                Download QR
              </button>
            </h2>
            <div
              id="collapseCarton"
              className="accordion-collapse collapse show card-admin2"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body ">
                <CTable />
              </div>
            </div>
          </div>
        </div>
        <br />
        <div className="accordion">
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseAll"
                aria-expanded="true"
                aria-controls="collapseAll"
              >
                All Products
              </button>
            </h2>
            <div
              id="collapseAll"
              className="accordion-collapse collapse show card-admin2"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body ">{/* <AllTable /> */}</div>
            </div>
          </div>
        </div>
        <br />
      </div>
    </div>
  );
};

export default Product;
