import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./auth.css";
import logo from "../../publicAssets/img/ddn-logo.png";
import { BiShow, BiHide } from "react-icons/bi";

const Login = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [response, setResponse] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const loginSubmit = async (e) => {
    e.preventDefault();

    if (!formData.email || !formData.password) {
      alert("Mohon masukan email dan password");

      return;
    }

    try {
      const response = await axios.post("/login", formData);
      const role = response.data.user.role;
      const token = response.data.token;
      const id = response.data.user.id;
      const username = response.data.user.username;

      localStorage.setItem("token", token);
      localStorage.setItem("username", username);
      localStorage.setItem("userid", id);

      if (role === 1) {
        navigate(`/controller/dashboard/${token}`);
      } else if (role === 2) {
        navigate(`/controller/reseller/${token}`);
      } else {
        alert("Terjadi Kesalahan");
      }
    } catch (error) {
      setResponse(error.response.data.msg);
    }
  };

  console.log(response);

  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const [showPasswordc, setShowPasswordc] = useState(false);
  const togglePasswordVisibilityc = () => {
    setShowPasswordc(!showPasswordc);
  };

  return (
    <div className="section-lp">
      <div>
        <svg
          className="bg"
          width="800"
          viewBox="0 0 1063 735"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M31.3003 290.479C203.842 160.298 497.297 -83 507.648 -83H1063V347.103L797.826 563.593C785.215 573.888 770.278 581.282 754.016 582.025C733.78 582.949 706.055 580.871 690.895 565.291C677.33 551.35 675.712 527.703 677.129 508.771C678.572 489.484 689.524 472.699 703.948 459.815L796.27 377.35C812.82 362.567 824.792 342.643 824.328 320.457C823.977 303.672 820.854 284.793 810.167 274.109C796.658 260.606 772.476 260.806 755.611 263.033C743.121 264.682 731.823 270.702 721.866 278.421L158.758 714.987C138.078 731.02 111.389 739.551 86.9706 730.146C75.1935 725.61 63.5614 719.183 56.5178 710.235C46.0423 696.927 43.4396 678.05 43.6238 661.777C43.8711 639.921 56.8139 620.974 73.8603 607.293L325.609 405.241C350.048 385.627 365.698 355.203 357.834 324.869C355.174 314.607 351.457 305.286 346.272 299.931C335.899 289.217 311.895 286.702 293.173 286.521C276.584 286.36 260.779 292.49 247.103 301.882L105.443 399.164C84.5302 413.526 58.1542 420.781 35.3979 409.568C24.3313 404.116 13.5427 396.429 7.38484 385.797C-3.9572 366.215 -0.155215 341.843 5.04862 324.808C9.37518 310.644 19.4781 299.399 31.3003 290.479Z"
            fill="black"
            fill-opacity="0.06"
          />
        </svg>
        <svg
          className="bg-coffee-1"
          width="184"
          height="231"
          viewBox="0 0 184 231"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_325_1242)">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M28.5476 199.229C24.9394 196.325 21.9203 192.482 19.6639 187.919C24.4467 189.008 29.3509 189.555 34.3197 189.553C45.4425 189.085 56.6438 185.079 66.8667 177.91C86.9738 163.402 99.2779 144.878 111.881 122.652C122.649 102.665 132.384 81.9427 141.018 60.6327C144.378 53.6339 147.224 46.5003 150.585 39.5015C151.525 37.7781 152.752 35.8932 153.616 34.2285C154.479 32.5638 154.381 33.4048 152.987 34.0638C151.593 34.7228 154.114 33.4923 157.108 33.2525C181.012 56.457 173.446 110.733 138.971 156.304C104.495 201.874 54.4922 222.338 28.488 199.922L28.5476 199.229ZM18.2419 185.655C5.75161 158.744 15.8605 113.407 45.1651 74.5188C72.4398 38.2503 108.733 18.3703 135.718 21.8955C134.337 24.134 133.319 26.3886 132.801 27.1985C129.456 35.3044 126.568 43.53 123.699 51.682C116.986 73.2858 109.279 94.5264 100.619 115.288C90.6686 136.949 80.8418 155.806 63.4752 171.742C54.6034 179.286 44.5815 184.023 34.41 185.479C28.9818 186.18 23.5816 186.165 18.2998 185.434L18.2419 185.655Z"
              fill="#AF7F58"
              fill-opacity="0.15"
            />
          </g>
          <defs>
            <clipPath id="clip0_325_1242">
              <rect
                width="137.229"
                height="202.213"
                fill="white"
                transform="translate(51.2568) rotate(14.6835)"
              />
            </clipPath>
          </defs>
        </svg>
        <svg
          className="bg-coffee-2"
          width="184"
          height="231"
          viewBox="0 0 184 231"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_325_1242)">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M28.5476 199.229C24.9394 196.325 21.9203 192.482 19.6639 187.919C24.4467 189.008 29.3509 189.555 34.3197 189.553C45.4425 189.085 56.6438 185.079 66.8667 177.91C86.9738 163.402 99.2779 144.878 111.881 122.652C122.649 102.665 132.384 81.9427 141.018 60.6327C144.378 53.6339 147.224 46.5003 150.585 39.5015C151.525 37.7781 152.752 35.8932 153.616 34.2285C154.479 32.5638 154.381 33.4048 152.987 34.0638C151.593 34.7228 154.114 33.4923 157.108 33.2525C181.012 56.457 173.446 110.733 138.971 156.304C104.495 201.874 54.4922 222.338 28.488 199.922L28.5476 199.229ZM18.2419 185.655C5.75161 158.744 15.8605 113.407 45.1651 74.5188C72.4398 38.2503 108.733 18.3703 135.718 21.8955C134.337 24.134 133.319 26.3886 132.801 27.1985C129.456 35.3044 126.568 43.53 123.699 51.682C116.986 73.2858 109.279 94.5264 100.619 115.288C90.6686 136.949 80.8418 155.806 63.4752 171.742C54.6034 179.286 44.5815 184.023 34.41 185.479C28.9818 186.18 23.5816 186.165 18.2998 185.434L18.2419 185.655Z"
              fill="#AF7F58"
              fill-opacity="0.15"
            />
          </g>
          <defs>
            <clipPath id="clip0_325_1242">
              <rect
                width="137.229"
                height="202.213"
                fill="white"
                transform="translate(51.2568) rotate(14.6835)"
              />
            </clipPath>
          </defs>
        </svg>
        <svg
          className="bg-coffee-3"
          width="184"
          height="231"
          viewBox="0 0 184 231"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_325_1242)">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M28.5476 199.229C24.9394 196.325 21.9203 192.482 19.6639 187.919C24.4467 189.008 29.3509 189.555 34.3197 189.553C45.4425 189.085 56.6438 185.079 66.8667 177.91C86.9738 163.402 99.2779 144.878 111.881 122.652C122.649 102.665 132.384 81.9427 141.018 60.6327C144.378 53.6339 147.224 46.5003 150.585 39.5015C151.525 37.7781 152.752 35.8932 153.616 34.2285C154.479 32.5638 154.381 33.4048 152.987 34.0638C151.593 34.7228 154.114 33.4923 157.108 33.2525C181.012 56.457 173.446 110.733 138.971 156.304C104.495 201.874 54.4922 222.338 28.488 199.922L28.5476 199.229ZM18.2419 185.655C5.75161 158.744 15.8605 113.407 45.1651 74.5188C72.4398 38.2503 108.733 18.3703 135.718 21.8955C134.337 24.134 133.319 26.3886 132.801 27.1985C129.456 35.3044 126.568 43.53 123.699 51.682C116.986 73.2858 109.279 94.5264 100.619 115.288C90.6686 136.949 80.8418 155.806 63.4752 171.742C54.6034 179.286 44.5815 184.023 34.41 185.479C28.9818 186.18 23.5816 186.165 18.2998 185.434L18.2419 185.655Z"
              fill="#AF7F58"
              fill-opacity="0.15"
            />
          </g>
          <defs>
            <clipPath id="clip0_325_1242">
              <rect
                width="137.229"
                height="202.213"
                fill="white"
                transform="translate(51.2568) rotate(14.6835)"
              />
            </clipPath>
          </defs>
        </svg>
      </div>
      <div className="container" style={{ position: "relative" }}>
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ height: "100%", minHeight: "100vh" }}
        >
          <div>
            <img
              onClick={() => navigate("/")}
              className="mb-4 mx-auto d-flex pointer"
              src={logo}
              style={{ width: "200px" }}
              alt=""
            />
            <div className="card-login m-auto ">
              <p
                className="text-login mb-1"
                style={{ fontSize: "10px", opacity: "60%" }}
              >
                Email/Phone
              </p>
              <input
                onChange={handleChange}
                name="email"
                type="text"
                className="input-login"
                placeholder="Email"
                autocomplete="off"
              />
              <br />
              <br />
              <div className="d-flex justify-content-between">
                {" "}
                <p
                  className="text-login mb-1"
                  style={{ fontSize: "10px", opacity: "60%" }}
                >
                  Password
                </p>
                <p
                  style={{ fontSize: "10px", color: "red" }}
                  className="blink-login mb-1 "
                >
                  {response}
                </p>{" "}
              </div>
              <div
                className="input-login d-flex p-0"
                style={{ backgroundColor: "white" }}
              >
                <input
                  onChange={handleChange}
                  name="password"
                  type={showPassword ? "text" : "password"}
                  className="input-logis"
                  autocomplete="off"
                  placeholder="Min 6 Karakter"
                />
                <div
                  className="pointer d-flex justify-content-center align-items-center me-3"
                  onClick={togglePasswordVisibility}
                >
                  {showPassword ? (
                    <BiHide color="black" size={20} />
                  ) : (
                    <BiShow color="black" size={20} />
                  )}
                </div>
              </div>
              <br />
              <div className="d-flex justify-content-center">
                <button
                  onClick={loginSubmit}
                  className="btn btn-md btn-dark btn-login"
                >
                  Masuk
                </button>
                {/* <button
                  onClick={() => navigate("/register")}
                  className="btn btn-sm btn-dark btn-login-outline"
                >
                  Buat Akun
                </button> */}
              </div>
            </div>
            <p
              onClick={() => navigate(`/forgot`)}
              className="text-login text-center mt-4 pointer"
              style={{
                fontWeight: "600",
                opacity: "60%",
                textDecoration: "underline",
                fontSize: "14px",
              }}
            >
              LUPA KATA SANDI?
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
