import React, { useState, useEffect } from "react";
import SlotCounter from "react-slot-counter";
import "./spinner.css";

function Spinner({ onClose }) {
  const [valueS, setValueS] = useState("??????");
  const [valueB, setValueB] = useState("??????");
  const [valueP, setValueP] = useState("??????");

  const closeSpin = () => {
    onClose(false);
  };

  const generateRandomValue = () => {
    const randomValue = Math.floor(Math.random() * 1000000).toString();
    return randomValue.padStart(6, "0");
  };

  const startAnimation = () => {
    setValueS(generateRandomValue);
    setValueB(generateRandomValue);
    setValueP(generateRandomValue);
  };

  return (
    <div className="spinner ">
      <div className="container">
        <br />
        <div className="w-100 card-spin mx-auto">
          <div className="spin-reward p-2 mb-2">
            <h1 className="mb-0 title-spin">UNDIAN SLOP</h1>
          </div>
          <SlotCounter
            startValue={"??????"}
            value={valueS}
            duration={15}
            direction="down"
            dummyCharacterCount={400}
          />
        </div>
        <br />
        <div className="w-100 card-spin mx-auto">
          <div className="spin-reward p-2 mb-2">
            <h1 className="mb-0 title-spin">UNDIAN BOX</h1>
          </div>
          <SlotCounter
            startValue={"??????"}
            value={valueB}
            duration={15}
            direction="down"
            dummyCharacterCount={400}
          />
        </div>

        <br />
        <div className="w-100 card-spin mx-auto">
          <div className="spin-reward p-2 mb-2">
            <h1 className="mb-0 title-spin">UNDIAN SACHET</h1>
          </div>
          <SlotCounter
            startValue={"??????"}
            value={valueP}
            duration={15}
            direction="down"
            dummyCharacterCount={400}
          />
        </div>

        <br />

        <div className="d-flex justify-content-between">
          <button
            className="btn btn-secondary w-100"
            style={{ height: "40px", borderRadius: "10px", maxWidth: "200px" }}
            onClick={closeSpin}
          >
            Close Spin
          </button>

          <button
            className="btn-ddn w-100 ms-3"
            style={{ maxWidth: "300px" }}
            onClick={startAnimation}
          >
            Start Spin
          </button>
        </div>
      </div>
    </div>
  );
}

export default Spinner;
