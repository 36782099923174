import React, { useState, useEffect, useRef } from "react";
import "./reseller.css";
import { FaArrowRight } from "react-icons/fa6";
import logoTick from "../../component/navbarAdmin/asset-sb/logoTick.svg";
import axios from "axios";

const Buy = () => {
  const userId = localStorage.getItem("userid");
  const initialErrors = {
    name: "",
    email: "",
    phone: "",
    address: "",
    price: 330000,
    qty: 20,
    city: "",
    role: 2,
    postalcode: "",
    type: "",
    status: "waiting",
    userid: userId,
  };
  const [formDataError, setFormDataError] = useState(initialErrors);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    price: 330000,
    qty: 20,
    city: "",
    role: 2,
    postalcode: "",
    type: "",
    status: "waiting",
    userid: userId,
  });

  console.log(formData);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setFormDataError({ ...formDataError, [name]: "" });
  };
  const validateForm = () => {
    const newErrors = {};

    if (!formData.email) {
      newErrors.email = "Wajib Diisi";
    }
    if (!formData.name) {
      newErrors.name = "Wajib Diisi";
    }

    if (!formData.phone) {
      newErrors.phone = "Wajib Diisi";
    }

    if (!formData.address) {
      newErrors.address = "Wajib Diisi";
    }

    if (!formData.city) {
      newErrors.city = "Wajib Diisi";
    }

    if (!formData.postalcode) {
      newErrors.postalcode = "Wajib Diisi";
    }

    if (!formData.qty) {
      newErrors.qty = "Wajib Diisi";
    }

    return newErrors;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = validateForm();

    if (Object.values(newErrors).some((error) => error !== "")) {
      setFormDataError(newErrors);
      return;
    }
    try {
      const response = await axios.post("/add-sales", formData);
      alert("Pemesanan berhasil");
      window.location.reload();
    } catch (error) {
      alert("Pemesanan gagal");
    }
  };

  const formatRupiah = (number) => {
    return number.toLocaleString("id-ID", {
      style: "currency",
      currency: "IDR",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  };

  return (
    <div
      className="tab-pane fade show active"
      id="pills-home"
      role="tabpanel"
      aria-labelledby="pills-home-tab"
    >
      <div className="card-reseller">
        <p
          className="text-center mb-0"
          style={{ opacity: "80%", fontSize: "20px" }}
        >
          DISTRIBUTOR SPECIAL PRICE
        </p>
        <h2 className="text-r text-center mt-2 mb-2">Rp 330.000</h2>
      </div>
      <br />
      <div className="form-reseller">
        <h3 className="text-center mb-5 mt-2">DISTRIBUTOR PURCHASE FORM</h3>
        <div className="row mb-4">
          <div className="col-12 col-sm-4">
            <p className="mb-1">
              EMAIL <span style={{ color: "red" }}>*</span>
            </p>
            {formDataError.email && (
              <p
                style={{ fontSize: "10px", color: "red" }}
                className="blink-login mb-1 "
              >
                {formDataError.email}
              </p>
            )}
          </div>
          <div className="col">
            <input
              type="text"
              className="input-reseller"
              placeholder="Ex : reseller@gmail.com"
              name="email"
              id=""
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-12 col-sm-4">
            <p className="mb-1">
              RECIPIENT NAME <span style={{ color: "red" }}>*</span>
            </p>
            {formDataError.name && (
              <p
                style={{ fontSize: "10px", color: "red" }}
                className="blink-login mb-1 "
              >
                {formDataError.name}
              </p>
            )}
          </div>
          <div className="col">
            <input
              type="text"
              className="input-reseller"
              placeholder="Ex : Fexy"
              name="name"
              id=""
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-12 col-sm-4">
            <p className="mb-1">
              WHATSAPP NUMBER <span style={{ color: "red" }}>*</span>
            </p>
            {formDataError.phone && (
              <p
                style={{ fontSize: "10px", color: "red" }}
                className="blink-login mb-1 "
              >
                {formDataError.phone}
              </p>
            )}
          </div>
          <div className="col">
            <input
              type="text"
              className="input-reseller"
              placeholder="Ex : 081294732992 "
              name="phone"
              id=""
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-12 col-sm-4">
            <p className="mb-1">
              QTY <span style={{ color: "red" }}>*</span>
            </p>
          </div>
          <div className="col">
            <div className="d-flex align-items-center">
              <input
                type="number"
                className="input-reseller"
                name="qty"
                id=""
                value={formData.qty === 20 && 1}
                readOnly
              />
              <p className="mb-1 ms-3">KARTON</p>
            </div>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-12 col-sm-4">
            <p className="mb-1">
              RECIPIENT ADDRESS <span style={{ color: "red" }}>*</span>
            </p>
            {formDataError.address && (
              <p
                style={{ fontSize: "10px", color: "red" }}
                className="blink-login mb-1 "
              >
                {formDataError.address}
              </p>
            )}
          </div>
          <div className="col">
            <input
              type="text"
              className="input-reseller"
              placeholder="Ex : Jl.ABC No123 "
              name="address"
              id=""
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-12 col-sm-4">
            <p className="mb-1">
              CITY <span style={{ color: "red" }}>*</span>
            </p>
            {formDataError.city && (
              <p
                style={{ fontSize: "10px", color: "red" }}
                className="blink-login mb-1 "
              >
                {formDataError.city}
              </p>
            )}
          </div>
          <div className="col">
            <input
              type="text"
              className="input-reseller"
              placeholder="Ex: Bandung "
              name="city"
              id=""
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-12 col-sm-4">
            <p className="mb-1">
              POSTAL CODE <span style={{ color: "red" }}>*</span>
            </p>
            {formDataError.postalcode && (
              <p
                style={{ fontSize: "10px", color: "red" }}
                className="blink-login mb-1 "
              >
                {formDataError.postalcode}
              </p>
            )}
          </div>
          <div className="col">
            <input
              type="number"
              className="input-reseller"
              placeholder="Ex : 40932 "
              name="postalcode"
              id=""
              onChange={handleChange}
            />
          </div>
        </div>
        {/* ------------------------------ */}
        <hr className="my-5" />
        <h3 className="text-center mb-5" style={{ opacity: "80%" }}>
          ORDER SUMMARY
        </h3>
        <div className="row mb-4">
          <div className="col-5 col-sm-4">
            <p className="mb-1">QTY ORDER</p>
          </div>
          <div className="col-7">
            <p className="mb-1"> : {formData.qty === 20 && 1} KARTON</p>
          </div>
        </div>{" "}
        <div className="row mb-4">
          <div className="col-5 col-sm-4">
            <p className="mb-1">TOTAL PRODUCT</p>
          </div>
          <div className="col-7">
            <p className="mb-1"> : {formatRupiah(formData.price)}</p>{" "}
          </div>
        </div>{" "}
        <div className="row mb-4">
          <div className="col-5 col-sm-4">
            <p className="mb-1">SHIPPING</p>
          </div>
          <div className="col-7">
            <p className="mb-1"> : Rp 15.000</p>
          </div>
        </div>
        <hr />
        <div className="row mb-4">
          <div className="col-5 col-sm-4">
            <p className="mb-1" style={{ fontWeight: "600" }}>
              GRAND TOTAL
            </p>
          </div>
          <div className="col-7">
            <p className="mb-1" style={{ color: "#ff535d", fontWeight: "600" }}>
              {" "}
              : {formatRupiah(formData.price + 15000)}
            </p>
          </div>
        </div>
        <button
          onClick={handleSubmit}
          className="btn btn-order mx-auto d-flex aling-items-center justify-content-center"
        >
          Order
        </button>
        <br />
        <br />
      </div>
    </div>
  );
};

export default Buy;
