import React, { useState, useEffect } from "react";
import axios from "axios";
import NavbarAdmin from "../../../component/navbarAdmin/Sidebar";
import { IoIosArrowForward } from "react-icons/io";
import OwnershipEvent from "./OwnershipEvent";
import Spinner from "./Spinner";
import Leaderboard from "./Leaderboard";
import AccEvent from "./AccEvent";
import { useNavigate } from "react-router-dom";
import { IoMdSettings } from "react-icons/io";
import { IoClose } from "react-icons/io5";
import { IoCheckmark } from "react-icons/io5";

const today = new Date().toISOString().split("T")[0];

const Reward = () => {
  const [sbOn, setSbOn] = useState("");
  const [price, setPrice] = useState("");
  const authToken = localStorage.getItem("token");
  const navigate = useNavigate();
  const [countdownOe, setCountdownOe] = useState("");
  const [countdownAe, setCountdownAe] = useState("");

  const calculateCountdownOe = (difference) => {
    if (difference <= 0) {
      return "Ended";
    }
    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
    );
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);
    return `${days.toString().padStart(2, "0")} D : ${hours
      .toString()
      .padStart(2, "0")} H : ${minutes
      .toString()
      .padStart(2, "0")} M : ${seconds.toString().padStart(2, "0")} S`;
  };

  const calculateCountdownAe = (difference) => {
    if (difference <= 0) {
      return "Ended";
    }
    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
    );
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);
    return `${days.toString().padStart(2, "0")} D : ${hours
      .toString()
      .padStart(2, "0")} H : ${minutes
      .toString()
      .padStart(2, "0")} M : ${seconds.toString().padStart(2, "0")} S`;
  };

  const receiveDataFromSidebar = (data) => {
    const sbData = { data: data === undefined ? true : data };
    setSbOn(sbData.data);
  };

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth > 768) {
        setSbOn(false);
      } else {
        setSbOn(true);
      }
    }

    handleResize();

    const getSpinReward = async () => {
      try {
        const response = await axios.get("/get-spin-reward", {
          headers: {
            Authorization: authToken,
          },
        });

        const intervalId = setInterval(() => {
          const difference = response.data[0]
            ? new Date(response.data[0].time) - new Date()
            : 0;
          const countdownOe = calculateCountdownOe(difference);
          setCountdownOe(countdownOe);
        }, 1000);

        // Bersihkan interval saat komponen dilepas
        return () => clearInterval(intervalId);
      } catch (error) {
        if (error.response) {
          if (error.response.status === 401 || error.response.status === 403) {
            navigate("/login");
          } else {
            console.log(error.response.status);
          }
        } else {
          // Jika tidak ada respon, set countdown ke tanggal sekarang
          const currentDate = new Date();
          const countdownOe = calculateCountdownOe(currentDate);
          setCountdownOe(countdownOe);
        }
      }
    };

    const getSpinSales = async () => {
      try {
        const response = await axios.get("/get-spin-sales", {
          headers: {
            Authorization: authToken,
          },
        });

        const intervalId = setInterval(() => {
          const difference = response.data[0]
            ? new Date(response.data[0].time) - new Date()
            : 0;
          const countdownAe = calculateCountdownAe(difference);
          setCountdownAe(countdownAe);
        }, 1000);

        // Bersihkan interval saat komponen dilepas
        return () => clearInterval(intervalId);
      } catch (error) {
        if (error.response) {
          if (error.response.status === 401 || error.response.status === 403) {
            navigate("/login");
          } else {
            console.log(error.response.status);
          }
        } else {
          // Jika tidak ada respon, set countdown ke tanggal sekarang
          const currentDate = new Date();
          const countdownAe = calculateCountdownAe(currentDate);
          setCountdownAe(countdownAe);
        }
      }
    };

    getSpinReward();
    getSpinSales();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [spin, setSpin] = useState(false);

  const openSpin = () => {
    setSpin(true);
  };
  const handleCloseSpin = () => {
    setSpin(false);
  };

  // ---------------------------------------------------------------------------------

  const [formData, setFormData] = useState({
    time: "",
  });

  const handleChangecdr = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const [cdr, setCdr] = useState(false);
  const openCdr = () => {
    setCdr((prevState) => !prevState);
  };
  const closeCdr = () => {
    setCdr(false);
  };
  const setCdrTime = async () => {
    if (!formData.time) {
      alert("Please enter countdown");
      return;
    }

    try {
      const currentDate = new Date();
      const futureDate = new Date(
        currentDate.getTime() + formData.time * 24 * 60 * 60 * 1000,
      );

      const response = await axios.post("/spin-reward", {
        time: futureDate,
        status: "active",
      });
      alert(response.data.msg);
      window.location.reload();
    } catch (error) {
      alert(error.response.data.msg);
    }
  };

  // ---------------------------------------------------------------------------------

  const [formDataS, setFormDataS] = useState({
    time: "",
  });

  const handleChangecds = (e) => {
    const { name, value } = e.target;
    setFormDataS({
      ...formDataS,
      [name]: value,
    });
  };

  const [cds, setCds] = useState(false);
  const openCds = () => {
    setCds((prevState) => !prevState);
  };
  const closeCds = () => {
    setCds(false);
  };
  const setCdsTime = async () => {
    if (!formDataS.time) {
      alert("Please enter countdown");
      return;
    }

    try {
      const currentDate = new Date();
      const futureDate = new Date(
        currentDate.getTime() + formDataS.time * 24 * 60 * 60 * 1000,
      );

      const response = await axios.post("/spin-sales", {
        time: futureDate,
        status: "active",
      });
      alert(response.data.msg);
      window.location.reload();
    } catch (error) {
      alert(error.response.data.msg);
    }
  };

  return (
    <div className="d-flex">
      <NavbarAdmin sbOnProp={receiveDataFromSidebar} sbAct={"reward"} />

      <div
        className={`container-fluid ${
          !sbOn ? "content-body" : "content-body-active"
        } p-4`}
      >
        <br />
        {spin && <Spinner onClose={handleCloseSpin} />}

        <div>
          <ul className="nav nav-pills mb-4" id="pills-tab" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                className="nav-link active"
                id="pills-lb-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-lb"
                type="button"
                role="tab"
                aria-controls="pills-lb"
                aria-selected="true"
              >
                LEADERBOARD
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="pills-oe-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-oe"
                type="button"
                role="tab"
                aria-controls="pills-oe"
                aria-selected="false"
              >
                OWNERSHIP EVENT
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="pills-ae-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-ae"
                type="button"
                role="tab"
                aria-controls="pills-ae"
                aria-selected="false"
              >
                ACCUMULATION EVENT
              </button>
            </li>
          </ul>
        </div>
        <div className="tab-content" id="pills-tabContent">
          <div
            className="tab-pane fade show active"
            id="pills-lb"
            role="tabpanel"
            aria-labelledby="pills-lb-tab"
          >
            <h4 className="text-center mb-3" style={{ color: "#2EBADB" }}>
              OVERALL ACCUMULATION LEADERBOARD
            </h4>

            <div className="card-reseller">
              {" "}
              <Leaderboard />
            </div>
          </div>
        </div>

        <div className="tab-content" id="pills-tabContent">
          <div
            className="tab-pane fade"
            id="pills-oe"
            role="tabpanel"
            aria-labelledby="pills-oe-tab"
          >
            <h4 className="text-center mb-3" style={{ color: "#FF47ED" }}>
              OWNERSHIP EVENT PARTICIPANTS
            </h4>
            <div className="card-reseller">
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <p className="mb-0 cdspin">{countdownOe}</p>
                  <div className="ms-2" style={{ position: "relative" }}>
                    <IoMdSettings
                      size={26}
                      onClick={openCdr}
                      className="pointer"
                    />{" "}
                    {cdr && (
                      <div className="config-spin-r">
                        <div>
                          <p className="mb-0" style={{ fontSize: "14px" }}>
                            Set Countdown
                          </p>
                          <div className="d-flex align-items-center mt-2">
                            <input
                              onChange={handleChangecdr}
                              name="time"
                              className="input-admin"
                              type="number"
                              placeholder="Ex: 90"
                              style={{
                                caretColor: "white",
                                width: "80px",
                                minWidth: "unset",
                                height: "30px",
                                fontSize: "12px",
                              }}
                            />{" "}
                            <p
                              className="mb-0 ms-2"
                              style={{ fontSize: "12px", opacity: "70%" }}
                            >
                              Days
                            </p>
                          </div>
                          <div className="d-flex mt-2 justify-content-between">
                            <button
                              onClick={closeCdr}
                              className="btn btn-sm btn-danger w-100 me-1 py-0 pb-1"
                              style={{ borderRadius: "10px" }}
                            >
                              <IoClose size={20} />
                            </button>
                            <button
                              onClick={setCdrTime}
                              className="btn btn-sm btn-success w-100 ms-1 py-0 pb-1"
                              style={{ borderRadius: "10px" }}
                            >
                              <IoCheckmark size={20} />
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <button className="btn-ddn" onClick={openSpin}>
                  SPIN
                </button>
              </div>
              <br />
              <OwnershipEvent />
            </div>
          </div>
        </div>

        <div className="tab-content" id="pills-tabContent">
          <div
            className="tab-pane fade"
            id="pills-ae"
            role="tabpanel"
            aria-labelledby="pills-ae-tab"
          >
            <h4 className="text-center mb-3" style={{ color: "#474FFF" }}>
              ACCUMULATION EVENT RANKING{" "}
            </h4>

            <div className="card-reseller">
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <p className="mb-0 cdspin">{countdownAe}</p>
                  <div className="ms-2" style={{ position: "relative" }}>
                    <IoMdSettings
                      size={26}
                      onClick={openCds}
                      className="pointer"
                    />{" "}
                    {cds && (
                      <div className="config-spin-r">
                        <div>
                          <p className="mb-0" style={{ fontSize: "14px" }}>
                            Set Countdown
                          </p>
                          <div className="d-flex align-items-center mt-2">
                            <input
                              onChange={handleChangecds}
                              name="time"
                              className="input-admin"
                              type="number"
                              placeholder="Ex: 90"
                              style={{
                                caretColor: "white",
                                width: "80px",
                                minWidth: "unset",
                                height: "30px",
                                fontSize: "12px",
                              }}
                            />{" "}
                            <p
                              className="mb-0 ms-2"
                              style={{ fontSize: "12px", opacity: "70%" }}
                            >
                              Days
                            </p>
                          </div>
                          <div className="d-flex mt-2 justify-content-between">
                            <button
                              onClick={closeCds}
                              className="btn btn-sm btn-danger w-100 me-1 py-0 pb-1"
                              style={{ borderRadius: "10px" }}
                            >
                              <IoClose size={20} />
                            </button>
                            <button
                              onClick={setCdsTime}
                              className="btn btn-sm btn-success w-100 ms-1 py-0 pb-1"
                              style={{ borderRadius: "10px" }}
                            >
                              <IoCheckmark size={20} />
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <button className="btn-ddn">ACCUMULATION EVENT WINNER</button>
              </div>
              <br />
              <AccEvent />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reward;
