import React, { useState, useEffect } from "react";
import axios from "axios";
import DataTable from "react-data-table-component";
import { IoClose } from "react-icons/io5";

const AllTable = ({ modalProps }) => {
  const [data, setData] = useState([]);
  const [product, setProduct] = useState([]);

  const columns = [
    {
      name: "BATCH",
      selector: (row) => (row.batch ? row.batch : "-"),
      sortable: true,
    },
    {
      name: "CODE",
      selector: (row) => (row.code ? row.code : "-"),
      sortable: true,
    },
    {
      name: "BUYER",
      selector: (row) => (row.phone ? row.phone : "-"),
      sortable: true,
    },

    {
      name: "STATUS",
      selector: (row) => (row.status ? row.status : "Available"),
      sortable: true,
      cell: (row) => {
        let cellStyle = {};
        if (row.status === "scanned") {
          cellStyle.color = "#0CAB58";
          cellStyle.fontWeight = "600";
        }
        return <div style={cellStyle}>{row.status ? row.status : "-"}</div>;
      },
    },

    {
      name: "PROVINCE",
      selector: (row) => (row.prov ? row.prov : "-"),
      sortable: true,
    },
    {
      name: "CITY",
      selector: (row) => (row.city ? row.city : "-"),
      sortable: true,
    },
    {
      name: "COORDINAT",
      selector: (row) => (row.coordinat ? row.coordinat : "-"),
      sortable: true,
    },

    {
      name: "CREATED AT",
      selector: (row) =>
        row.createdAt ? new Date(row.createdAt).toLocaleDateString() : "-",
      sortable: true,
    },
  ];
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/product");
        // Menyaring data berdasarkan status 'scanned'
        const scannedData = response.data.filter(
          (item) => item.status === "scanned",
        );
        setData(scannedData);
      } catch (error) {
        console.log(error.message);
      }
    };
    fetchData();
  }, []);
  console.log(product);
  return (
    <div>
      <DataTable columns={columns} data={data} pagination />
    </div>
  );
};

export default AllTable;
