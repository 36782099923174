import React, { useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "./../dashboard.css"; // Import file CSS untuk styling

const CalendarComp = () => {
  const [date, setDate] = useState(new Date());
  const [showEventInput, setShowEventInput] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [events, setEvents] = useState({}); // Objek untuk menyimpan event, misal: { "2022-02-10": ["Meeting with John", "Appointment with client"] }
  const [submittedDates, setSubmittedDates] = useState(new Set()); // Set untuk menyimpan tanggal yang sudah disubmit
  const [inputEvent, setInputEvent] = useState(""); // State untuk menyimpan input event sementara

  const onChange = (date) => {
    setDate(date);
    setSelectedDate(date);
    setShowEventInput(true);
  };

  const handleInputChange = (event) => {
    setInputEvent(event.target.value);
  };

  const handleSubmitEvent = () => {
    if (inputEvent.trim() === "") return; // Jika input event kosong, jangan lakukan apa-apa
    const newEvents = {
      ...events,
      [selectedDate.toDateString()]: [
        ...(events[selectedDate.toDateString()] || []),
        inputEvent,
      ],
    };
    setEvents(newEvents);
    setShowEventInput(false);
    setInputEvent(""); // Reset input event setelah disimpan
    setSubmittedDates(
      (prev) => new Set([...prev, selectedDate.toDateString()]),
    ); // Menambahkan tanggal yang disubmit ke dalam Set
  };

  const tileClassName = ({ date }) => {
    const eventDate = date.toDateString();
    let className = "";
    if (events[eventDate]) {
      className = "highlight-cal"; // Tambahkan kelas 'highlight-cal' jika ada event pada tanggal tersebut
    }
    return className;
  };

  const formatDate = (date) => {
    return new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    }).format(date);
  };

  const handleDeleteEvent = (dateString, index) => {
    const newEvents = { ...events };
    newEvents[dateString] = events[dateString].filter((_, i) => i !== index);
    setEvents(newEvents);
  };

  return (
    <div>
      <Calendar
        onChange={onChange}
        value={date}
        tileClassName={tileClassName}
      />
      {/* Overlay untuk input event */}
      {showEventInput && (
        <div className="overlay-cal">
          <div className="event-input-container">
            {events[selectedDate?.toDateString()] && (
              <div>
                <h2 className="title-cal mb-3">{formatDate(selectedDate)}</h2>
                {events[selectedDate?.toDateString()].map((event, index) => (
                  <div key={index} className="">
                    <p className=" p-2 mb-2 sc-cal">
                      {index + 1}.&nbsp;{event}{" "}
                      <button
                        onClick={() =>
                          handleDeleteEvent(selectedDate.toDateString(), index)
                        }
                        className="btn-del-cal"
                      >
                        Delete
                      </button>
                    </p>
                  </div>
                ))}
                <br />
              </div>
            )}
            <div>
              <input
                className="input-admin w-100"
                type="text"
                placeholder={`Add Event for ${formatDate(selectedDate)}`}
                value={inputEvent}
                onChange={handleInputChange}
              />
              <br />
              <div className="d-flex">
                <button
                  className="btn-ddn w-100 mt-3 me-1"
                  onClick={handleSubmitEvent}
                >
                  Submit Event
                </button>
                <button
                  className="btn-ddn w-100 mt-3 ms-1"
                  onClick={() => setShowEventInput(false)}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
          <div
            className="overlay-background"
            onClick={() => setShowEventInput(false)}
          ></div>
        </div>
      )}
      {/* Daftar event */}
    </div>
  );
};

export default CalendarComp;
