import React, { useState, useEffect } from "react";
import axios from "axios";
import Scan from "../../component/scanner/Scan";
import { BsBoxSeamFill } from "react-icons/bs";

import "./scanner.css";
import PinInput from "react-pin-input";
import { useNavigate } from "react-router-dom";

import { FaArrowCircleLeft } from "react-icons/fa";

import ddnC from "../../publicAssets/img/ddn-scan-s.png";
import ddnS from "../../publicAssets/img/ddn-scan-s.png";
import ddnB from "../../publicAssets/img/ddn-scan-s.png";
import ddnP from "../../publicAssets/img/ddn-scan-s.png";
import Loading from "../../component/loading/loading";
import AlertDdn from "../../component/alert/AlertDdn";

const Scanner = () => {
  const navigate = useNavigate();

  const [data, setData] = useState("");

  const [showData, setShowData] = useState(false);

  const [showDataScan, setShowDataScan] = useState(true);
  const [showDataScanR, setShowDataScanR] = useState(true);

  const [scannedData, setScannedData] = useState("");

  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [error, setError] = useState(null);

  const [errorData, setErrorData] = useState(null);

  const [city, setCity] = useState(null);
  const [prov, setProv] = useState(null);

  const [openVerifCode, setOpenVerifCode] = useState(false);

  const [verifC, setverifC] = useState([]);

  const [loading, setLoading] = useState(true);

  const verifcode = (data && data.verifcode) || "";
  console.log(verifC);
  const verifCodeClose = () => {
    window.location.reload();
  };

  const verifCodeConfirm = () => {
    setOpenVerifCode(false);

    // if (Number(verifcode) === Number(verifC)) {
    //   setOpenVerifCode(false);
    //   window.scrollTo(0, 0);
    // } else {
    //   setAlert({
    //     title: "PRODUK INI TIDAK ORIGINAL !!",
    //     message:
    //       "Produk ini tidak sesuai dengan kriteria otentikasi produk original, silahkan tukarkan kembali dan laporkan kepada kami.",
    //     open: true,
    //     results: false,
    //   });
    // }
  };

  const onNewScanResult = async (decodedText) => {
    setLoading(true);
    setScannedData(decodedText);
    setOpenVerifCode(true);
    setShowDataScanR(false);
    try {
      const response = await axios.get(`/product/${decodedText}`);
      setData(response.data);
      setShowData(true);
      setLoading(false);

      window.scrollTo(0, 0);
    } catch (error) {
      console.error("Error fetching data:", error);
      setShowData(false);
      setErrorData("Produk Tidak Ada");
      setLoading(false);

      setData(null);
      window.scrollTo(0, 0);
    }
    setShowDataScan(false);
  };

  const onError = (decodedText) => {};

  const onNewScanResultR = async (decodedText) => {
    setLoading(true);
    const trimmedText = decodedText.substring(1, decodedText.length - 1);
    try {
      const response = await axios.get(`/sales-invoice/${trimmedText}`);
      const randomValue = Math.random().toString(36).substring(2, 15);
      const encryptedText = btoa(trimmedText);
      const enkripVal = `${encryptedText}_${randomValue}`;
      navigate(`/invoice/${enkripVal}`);
    } catch (error) {
      console.error("Error fetching data:", error);
      window.scrollTo(0, 0);
      alert("Data Tidak Ada");
      window.location.reload();
    }
  };

  const onErrorR = (decodedText) => {};

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);

    window.scrollTo(0, 0);
    const fetchCity = async (lat, lon) => {
      try {
        const response = await fetch(
          `https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${lon}&format=json`,
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        const city = data.address.city || "Unknown";
        const postcode = data.address.postcode || "Unknown";
        const provn = data.address.state || "Unknown";
        const cityName = `${city}, ${postcode}`;
        setCity(cityName);
        setProv(provn);
        return cityName;
      } catch (error) {
        console.error("Error fetching city:", error);
        return "Unknown";
      }
    };
    const getLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setLatitude(position.coords.latitude);
            setLongitude(position.coords.longitude);
            fetchCity(position.coords.latitude, position.coords.longitude).then(
              () => setLoading(false),
            );
          },
          (error) => {
            setError(error.message);
          },
        );
      } else {
        setError("Geolocation is not supported by this browser.");
      }
    };

    getLocation();

    if (!loading) {
      const coordinats = `${latitude},${longitude}`;
      setFormData((prevFormData) => ({
        ...prevFormData,
        code: scannedData,
        city: city,
        prov: prov,
        coordinat: coordinats,
        batch: data && data.batch,
        type: data && data.type,
      }));
    }
    return () => clearTimeout(timer);
  }, [loading, city, prov, latitude, longitude]);

  const productiondate =
    data && data.production
      ? new Date(data.production).toLocaleDateString("en-GB", {
          timeZone: "UTC",
        })
      : "";

  const expiredate =
    data && data.expire
      ? new Date(data.expire).toLocaleDateString("en-GB", { timeZone: "UTC" })
      : "";

  let batchSuffix = "";
  if (data && data.batch === "1") {
    batchSuffix = "st";
  } else if (data && data.batch === "2") {
    batchSuffix = "nd";
  } else if (data && data.batch === "3") {
    batchSuffix = "rd";
  } else if (data && data.batch > "3") {
    batchSuffix = "th";
  }

  const coordinats = `${latitude},${longitude}`;
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    code: scannedData,
    batch: data && data.batch,
    type: data && data.type,

    city: city,
    prov: prov,
    coordinat: coordinats,
    status: "scanned",
  });
  const [phoneError, setPhoneError] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setPhoneError("");
  };
  const [alert, setAlert] = useState({
    title: "",
    message: "",
    open: false,
    results: true,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormData(() => ({
      batch: data && data.batch,
      type: data && data.type,
    }));

    if (!formData.phone) {
      setPhoneError("Mohon Masukan Nomor Whatsapp");
      return;
    }

    try {
      await axios.post("/scan", formData);
      await axios.post("/add-reward", formData);
      setAlert({
        title: "BERHASIL DIDAFTARKAN !",
        message: "Kepemilikan produk ini berhasil didaftarkan.",
        open: true,
        results: true,
      });
    } catch (error) {
      setAlert({
        title: "PRODUK INI TIDAK ORIGINAL !!",
        message:
          "Produk ini tidak sesuai dengan kriteria otentikasi produk original, silahkan tukarkan kembali dan laporkan kepada kami.",
        open: true,
        results: false,
      });
      console.error("Error posting data:", error);
    }
  };
  console.log(alert);

  const handleRefresh = () => {
    window.location.reload();
  };

  return (
    <div className="section-lp">
      <Loading loadingProps={loading} />
      <AlertDdn
        openAlert={alert.open}
        resultAlert={alert.results}
        title={alert.title}
        message={alert.message}
      />

      <div>
        {" "}
        <svg
          className="bg"
          width="800"
          viewBox="0 0 1063 735"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M31.3003 290.479C203.842 160.298 497.297 -83 507.648 -83H1063V347.103L797.826 563.593C785.215 573.888 770.278 581.282 754.016 582.025C733.78 582.949 706.055 580.871 690.895 565.291C677.33 551.35 675.712 527.703 677.129 508.771C678.572 489.484 689.524 472.699 703.948 459.815L796.27 377.35C812.82 362.567 824.792 342.643 824.328 320.457C823.977 303.672 820.854 284.793 810.167 274.109C796.658 260.606 772.476 260.806 755.611 263.033C743.121 264.682 731.823 270.702 721.866 278.421L158.758 714.987C138.078 731.02 111.389 739.551 86.9706 730.146C75.1935 725.61 63.5614 719.183 56.5178 710.235C46.0423 696.927 43.4396 678.05 43.6238 661.777C43.8711 639.921 56.8139 620.974 73.8603 607.293L325.609 405.241C350.048 385.627 365.698 355.203 357.834 324.869C355.174 314.607 351.457 305.286 346.272 299.931C335.899 289.217 311.895 286.702 293.173 286.521C276.584 286.36 260.779 292.49 247.103 301.882L105.443 399.164C84.5302 413.526 58.1542 420.781 35.3979 409.568C24.3313 404.116 13.5427 396.429 7.38484 385.797C-3.9572 366.215 -0.155215 341.843 5.04862 324.808C9.37518 310.644 19.4781 299.399 31.3003 290.479Z"
            fill="black"
            fill-opacity="0.06"
          />
        </svg>
        <svg
          className="bg-coffee-1"
          width="184"
          height="231"
          viewBox="0 0 184 231"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_325_1242)">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M28.5476 199.229C24.9394 196.325 21.9203 192.482 19.6639 187.919C24.4467 189.008 29.3509 189.555 34.3197 189.553C45.4425 189.085 56.6438 185.079 66.8667 177.91C86.9738 163.402 99.2779 144.878 111.881 122.652C122.649 102.665 132.384 81.9427 141.018 60.6327C144.378 53.6339 147.224 46.5003 150.585 39.5015C151.525 37.7781 152.752 35.8932 153.616 34.2285C154.479 32.5638 154.381 33.4048 152.987 34.0638C151.593 34.7228 154.114 33.4923 157.108 33.2525C181.012 56.457 173.446 110.733 138.971 156.304C104.495 201.874 54.4922 222.338 28.488 199.922L28.5476 199.229ZM18.2419 185.655C5.75161 158.744 15.8605 113.407 45.1651 74.5188C72.4398 38.2503 108.733 18.3703 135.718 21.8955C134.337 24.134 133.319 26.3886 132.801 27.1985C129.456 35.3044 126.568 43.53 123.699 51.682C116.986 73.2858 109.279 94.5264 100.619 115.288C90.6686 136.949 80.8418 155.806 63.4752 171.742C54.6034 179.286 44.5815 184.023 34.41 185.479C28.9818 186.18 23.5816 186.165 18.2998 185.434L18.2419 185.655Z"
              fill="#AF7F58"
              fill-opacity="0.15"
            />
          </g>
          <defs>
            <clipPath id="clip0_325_1242">
              <rect
                width="137.229"
                height="202.213"
                fill="white"
                transform="translate(51.2568) rotate(14.6835)"
              />
            </clipPath>
          </defs>
        </svg>
        <svg
          className="bg-coffee-2"
          width="184"
          height="231"
          viewBox="0 0 184 231"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_325_1242)">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M28.5476 199.229C24.9394 196.325 21.9203 192.482 19.6639 187.919C24.4467 189.008 29.3509 189.555 34.3197 189.553C45.4425 189.085 56.6438 185.079 66.8667 177.91C86.9738 163.402 99.2779 144.878 111.881 122.652C122.649 102.665 132.384 81.9427 141.018 60.6327C144.378 53.6339 147.224 46.5003 150.585 39.5015C151.525 37.7781 152.752 35.8932 153.616 34.2285C154.479 32.5638 154.381 33.4048 152.987 34.0638C151.593 34.7228 154.114 33.4923 157.108 33.2525C181.012 56.457 173.446 110.733 138.971 156.304C104.495 201.874 54.4922 222.338 28.488 199.922L28.5476 199.229ZM18.2419 185.655C5.75161 158.744 15.8605 113.407 45.1651 74.5188C72.4398 38.2503 108.733 18.3703 135.718 21.8955C134.337 24.134 133.319 26.3886 132.801 27.1985C129.456 35.3044 126.568 43.53 123.699 51.682C116.986 73.2858 109.279 94.5264 100.619 115.288C90.6686 136.949 80.8418 155.806 63.4752 171.742C54.6034 179.286 44.5815 184.023 34.41 185.479C28.9818 186.18 23.5816 186.165 18.2998 185.434L18.2419 185.655Z"
              fill="#AF7F58"
              fill-opacity="0.15"
            />
          </g>
          <defs>
            <clipPath id="clip0_325_1242">
              <rect
                width="137.229"
                height="202.213"
                fill="white"
                transform="translate(51.2568) rotate(14.6835)"
              />
            </clipPath>
          </defs>
        </svg>
        <svg
          className="bg-coffee-3"
          width="184"
          height="231"
          viewBox="0 0 184 231"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_325_1242)">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M28.5476 199.229C24.9394 196.325 21.9203 192.482 19.6639 187.919C24.4467 189.008 29.3509 189.555 34.3197 189.553C45.4425 189.085 56.6438 185.079 66.8667 177.91C86.9738 163.402 99.2779 144.878 111.881 122.652C122.649 102.665 132.384 81.9427 141.018 60.6327C144.378 53.6339 147.224 46.5003 150.585 39.5015C151.525 37.7781 152.752 35.8932 153.616 34.2285C154.479 32.5638 154.381 33.4048 152.987 34.0638C151.593 34.7228 154.114 33.4923 157.108 33.2525C181.012 56.457 173.446 110.733 138.971 156.304C104.495 201.874 54.4922 222.338 28.488 199.922L28.5476 199.229ZM18.2419 185.655C5.75161 158.744 15.8605 113.407 45.1651 74.5188C72.4398 38.2503 108.733 18.3703 135.718 21.8955C134.337 24.134 133.319 26.3886 132.801 27.1985C129.456 35.3044 126.568 43.53 123.699 51.682C116.986 73.2858 109.279 94.5264 100.619 115.288C90.6686 136.949 80.8418 155.806 63.4752 171.742C54.6034 179.286 44.5815 184.023 34.41 185.479C28.9818 186.18 23.5816 186.165 18.2998 185.434L18.2419 185.655Z"
              fill="#AF7F58"
              fill-opacity="0.15"
            />
          </g>
          <defs>
            <clipPath id="clip0_325_1242">
              <rect
                width="137.229"
                height="202.213"
                fill="white"
                transform="translate(51.2568) rotate(14.6835)"
              />
            </clipPath>
          </defs>
        </svg>
      </div>
      {openVerifCode && (
        <div>
          <div className="bg-cardkodeverif"></div>
          <div className="cardkodeverif d-flex justify-content-center align-items-center py-4">
            <h4 className=" text-dark mb-1">Verifikasi Kode Produk </h4>
            <p
              className="text-center text-dark mb-2"
              style={{ fontSize: "10px", opacity: "70%" }}
            >
              Masukkan angka 6 digit pada QR produk yang anda beli. <br />{" "}
              Setiap produk memiliki angka yang berbeda.
            </p>
            <PinInput
              length={6}
              initialValue=""
              onChange={setverifC}
              type="numeric"
              inputMode="number"
              style={{ padding: "10px" }}
              inputStyle={{ borderColor: "grey" }}
              inputFocusStyle={{ borderColor: "red" }}
              onComplete={(value, index) => {}}
              autoSelect={true}
              regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
            />{" "}
            <div className="d-flex w-100" style={{ padding: "0px 14px" }}>
              <button
                onClick={verifCodeConfirm}
                className="btn-ddn mt-2 mb-2 w-100 me-2"
              >
                Konfirmasi
              </button>

              <button
                onClick={verifCodeClose}
                className="btn btn-secondary mt-2 mb-2 w-100 ms-2"
                style={{ borderRadius: "10px", height: "40px" }}
              >
                Batalkan
              </button>
            </div>
          </div>
        </div>
      )}

      <br />
      <div className="scan-result p-2">
        <div>
          {showDataScan && (
            <div>
              <div className="d-flex mx-auto mb-4 align-items-center justify-content-center">
                <FaArrowCircleLeft
                  onClick={() => navigate(-1)}
                  size={30}
                  color="#FF535D"
                  className="pointer me-4 mt-1"
                />{" "}
                <h1 className="text-center mb-0 text-dark">Scan Produk</h1>
                <FaArrowCircleLeft
                  size={30}
                  color="transparent"
                  className="mt-1 ms-4"
                />{" "}
              </div>
              <Scan
                onScan={onNewScanResult}
                onError={onError}
                showScan={showDataScan}
              />

              <div className="d-flex flex-column btn-scan mx-auto">
                <button
                  className="btn-ddn btn-scan mb-2 mt-4 d-flex align-items-center justify-content-start ps-4 fw-bold"
                  onClick={() => navigate("/scan-confirmation")}
                >
                  <BsBoxSeamFill className="me-3" size={20} />
                  Konfirmasi Barang Diterima
                </button>
              </div>
            </div>
          )}
        </div>
        {showData ? (
          <div>
            <div className="p-4 outer-scan">
              <h3
                style={{ fontWeight: "700" }}
                className="text-center text-dark mb-4"
              >
                PRODUK ANDA ORIGINAL !!
              </h3>
              <div className="card-img-scan">
                {data && data.type === "carton" && (
                  <img
                    className="w-100"
                    src={ddnC}
                    style={{ borderRadius: "10px" }}
                  />
                )}
                {data && data.type === "slop" && (
                  <img
                    className="w-100"
                    src={ddnS}
                    style={{ borderRadius: "10px" }}
                  />
                )}
                {data && data.type === "box" && (
                  <img
                    className="w-100"
                    src={ddnB}
                    style={{ borderRadius: "10px" }}
                  />
                )}
                {data && data.type === "pieces" && (
                  <img
                    className="w-100"
                    src={ddnP}
                    style={{ borderRadius: "10px" }}
                  />
                )}
              </div>{" "}
              <br />
              <div className="d-flex mb-2">
                <p style={{ width: "100px" }} className=" mb-0 me-2 text-dark">
                  CODE
                </p>
                <p className="text-center mb-0 me-2 text-dark">:</p>

                <p className="text-center mb-0 text-dark fw-bold">
                  {" "}
                  {data && data.code}
                </p>
              </div>
              <div className="d-flex mb-2">
                <p style={{ width: "100px" }} className=" mb-0 me-2 text-dark">
                  BATCH
                </p>
                <p className="text-center mb-0 me-2 text-dark">:</p>

                <p className="text-center mb-0 text-dark fw-bold">
                  {" "}
                  {data && data.batch}
                  {batchSuffix}
                </p>
              </div>
              <div className="d-flex mb-2">
                <p style={{ width: "100px" }} className=" mb-0 me-2 text-dark">
                  EXP.DATE
                </p>
                <p className="text-center mb-0 me-2 text-dark">:</p>
                <p className="text-center mb-0 text-dark fw-bold">
                  {" "}
                  {expiredate}
                </p>
              </div>
              <div className="d-flex mb-2">
                <p style={{ width: "100px" }} className=" mb-0 me-2 text-dark">
                  MFG.DATE
                </p>
                <p className="text-center mb-0 me-2 text-dark">:</p>
                <p className="text-center mb-0 text-dark fw-bold">
                  {" "}
                  {productiondate}
                </p>
              </div>
              <div className="d-flex mb-2">
                <p style={{ width: "100px" }} className=" mb-0 me-2 text-dark">
                  KODE VERIF
                </p>
                <p className="text-center mb-0 me-2 text-dark">:</p>

                <p
                  style={{ textTransform: "capitalize" }}
                  className="text-center mb-0 text-dark fw-bold"
                >
                  {" "}
                  {verifcode}
                </p>
              </div>
              <br />
              <p
                className="mb-1 blink-login"
                style={{ fontSize: "12px", color: "red" }}
              >
                {phoneError}
              </p>
              {data && data.status !== "scanned" ? (
                <div>
                  <FaArrowCircleLeft
                    onClick={handleRefresh}
                    size={30}
                    color="#FF535D"
                    className="pointer"
                    style={{ position: "absolute", bottom: "30px" }}
                  />

                  <input
                    onChange={handleChange}
                    className="input-admin mx-auto d-flex w-100"
                    type="number"
                    placeholder="Nomor Whatsapp"
                    name="phone"
                  />
                  <br />
                  <button
                    onClick={handleSubmit}
                    className="mx-auto btn-ddn d-flex justify-content-center w-50"
                  >
                    Daftarkan Kepemilikan
                  </button>
                </div>
              ) : (
                <div>
                  <button
                    onClick={handleRefresh}
                    className="mx-auto btn-ddn d-flex justify-content-center px-5"
                  >
                    Kembali
                  </button>
                  <br />
                  <p
                    className="pointer mb-0"
                    style={{ fontSize: "12px", color: "red" }}
                  >
                    Produk ini sudah terdaftar dalam undian. Silahkan tukarkan
                    kembali jika anda ingin mengikuti undian.{" "}
                    <span style={{ color: "blue", fontStyle: "italic" }}>
                      {" "}
                      Pelajari lebih lanjut.
                    </span>
                  </p>
                </div>
              )}
            </div>

            <br />
            <br />
          </div>
        ) : (
          <div>
            <p
              style={{
                opacity: "80%",
                color: "red",
                fontSize: "30px",
                fontWeight: "600",
              }}
              className="text-center"
            >
              {errorData}
            </p>
            {errorData && (
              <button
                onClick={handleRefresh}
                className="btn-ddn mx-auto d-flex px-5"
              >
                Kembali
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Scanner;
