import React, { useState, useEffect, useRef } from "react";
import "./loadingadm.css";
import { BlinkBlur } from "react-loading-indicators";
const LoadingAdm = ({ loadingProps }) => {
  return (
    <div>
      <div
        className={
          loadingProps === true ? "canvasLoadingP" : "canvasLoadingP hidden"
        }
      >
        <BlinkBlur color="#FF535D" size="small" text="" textColor="" />{" "}
      </div>
    </div>
  );
};

export default LoadingAdm;
