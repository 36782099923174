import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import DataTable from "react-data-table-component";
import { IoClose } from "react-icons/io5";
import "../order.css";
import QrReaderProduct from "./ScannerOrder";
import ddnlogo from "../../../../publicAssets/img/ddnlogo.png";
import Wqr from "./Wqr";
import { BsBoxFill } from "react-icons/bs";
import LoadingAdm from "../../../loadAdmin/loadingAdm";
import uploadNoImage from "./upload.svg";

const AllTable = () => {
  const authToken = localStorage.getItem("token");
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [dataP, setDataP] = useState([]);
  const [dataU, setDataU] = useState([]);

  const [loading, setLoading] = useState(true);

  const columns = [
    {
      name: "ACTION",
      cell: (row) => (
        <div>
          {row.status === "waiting" ? (
            <span
              style={{ backgroundColor: "green", width: "100px" }}
              className="btn btn-download-qr text-white px-4"
              onClick={() =>
                handleSubmit(
                  row.id,
                  row.email,
                  row.invoice,
                  row.name,
                  row.qty,
                  row.price,
                  row.phone,
                )
              }
            >
              Approve
            </span>
          ) : (
            <span
              style={{ backgroundColor: "blue", width: "100px" }}
              className="btn btn-download-qr text-white px-4"
              onClick={() => openModal(row)}
            >
              Detail
            </span>
          )}
        </div>
      ),
      sortable: true,
    },
    {
      name: "IMAGE",
      cell: (row) => (
        <div>
          <span
            style={{ backgroundColor: "rgb(255, 83, 93)", width: "100px" }}
            className="btn btn-download-qr text-white px-4"
            onClick={() => openModalImage(row.image, row.id)}
          >
            Open
          </span>
        </div>
      ),
      sortable: true,
    },
    {
      name: "STATUS",
      selector: (row) => (row.status ? row.status : "-"),
      sortable: true,
      cell: (row) => {
        let cellStyle = {};
        if (row.status === "finish") {
          cellStyle.color = "#0CAB58";
          cellStyle.fontWeight = "600";
          cellStyle.textTransform = "uppercase";
        } else if (row.status === "approved") {
          cellStyle.color = "#B97F00";
          cellStyle.fontWeight = "600";
          cellStyle.textTransform = "uppercase";
        } else if (row.status === "packing") {
          cellStyle.color = "#0E8BAA";
          cellStyle.fontWeight = "600";
          cellStyle.textTransform = "uppercase";
        } else if (row.status === "sent") {
          cellStyle.color = "#B050EF";
          cellStyle.fontWeight = "600";
          cellStyle.textTransform = "uppercase";
        } else if (row.status === "waiting") {
          cellStyle.color = "gray";
          cellStyle.fontWeight = "600";
          cellStyle.textTransform = "uppercase";
        } else if (row.status === "received") {
          cellStyle.color = "#ff535d ";
          cellStyle.fontWeight = "600";
          cellStyle.textTransform = "uppercase";
        } else if (row.status === "scanned") {
          cellStyle.color = "#ff535d ";
          cellStyle.fontWeight = "600";
          cellStyle.textTransform = "uppercase";
        }
        return <div style={cellStyle}>{row.status ? row.status : "-"}</div>;
      },
    },

    {
      name: "TXN ID",
      selector: (row) => (row.invoice ? row.invoice : "-"),
      sortable: true,
    },
    {
      name: "DISTRIBUTOR",
      selector: (row) => {
        const distributor = dataU.find((user) => user.id === row.userid);
        return distributor ? distributor.username.toUpperCase() : "-";
      },
      sortable: true,
    },

    {
      name: "NAME",
      selector: (row) => (row.name ? row.name : "-"),
      sortable: true,
    },
    {
      name: "ROLE",
      selector: (row) =>
        row.role === 0 ? "USER" : row.role === 2 ? "DISTRIBUTOR" : "-",
      sortable: true,
    },

    {
      name: "PHONE",
      selector: (row) => (row.phone ? row.phone : "-"),
      sortable: true,
    },
    {
      name: "EMAIL",
      selector: (row) => (row.email ? row.email : "-"),
      sortable: true,
    },
    {
      name: "QTY",
      selector: (row) => (row.qty ? row.qty : "-"),
      sortable: true,
    },
    {
      name: "ADDRESS",
      selector: (row) => (row.address ? row.address : "-"),
      sortable: true,
    },
    {
      name: "CITY",
      selector: (row) => (row.city ? row.city : "-"),
      sortable: true,
    },
    {
      name: "POSTAL CODE",
      selector: (row) => (row.postalcode ? row.postalcode : "-"),
      sortable: true,
    },
    {
      name: "RESI",
      selector: (row) => (row.resi ? row.resi : "-"),
      sortable: true,
    },
    {
      name: "COURIER",
      selector: (row) => (row.kurir ? row.kurir : "-"),
      sortable: true,
    },
    {
      name: "COURIER",
      selector: (row) => (row.kurir ? row.kurir : "-"),
      sortable: true,
    },

    {
      name: "CREATED AT",
      selector: (row) =>
        row.createdAt ? new Date(row.createdAt).toLocaleDateString() : "-",
      sortable: true,
    },
  ];
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/sales", {
          headers: {
            Authorization: authToken,
          },
        });
        const filteredData = response.data.filter(
          (item) => item.status !== "received" && item.status !== "scanned",
        );
        setData(filteredData);
        setLoading(false);
      } catch (error) {
        if (error.response.status === 401) {
          navigate("/login");
        } else if (error.response.status === 403) {
          navigate("/login");
        } else {
          console.log(error.response.status);
        }
      }
    };
    const fetchDataProduct = async () => {
      try {
        const response = await axios.get("/product");
        const filteredData = response.data.filter(
          (item) =>
            item.code && item.code.startsWith("S") && item.sales !== "sold",
        );
        setDataP(filteredData);
      } catch (error) {
        console.log(error.message);
      }
    };

    const fetchDataUser = async () => {
      try {
        const response = await axios.get("/users", {
          headers: {
            Authorization: authToken,
          },
        });

        setDataU(response.data);
      } catch (error) {
        if (error.response.status === 401) {
          navigate("/login");
        } else if (error.response.status === 403) {
          navigate("/login");
        } else {
          console.log(error.response.status);
        }
      }
    };
    fetchDataUser();
    fetchData();
    fetchDataProduct();
  }, []);

  const [showModal, setShowModal] = useState(false);
  const [showModalImage, setShowModalImage] = useState(false);
  const [paymentImage, setPaymentImage] = useState("");
  const [paymentImageId, setPaymentImageId] = useState("");
  const [paymentImageData, setPaymentImageData] = useState("");

  const [order, setOrder] = useState({
    invoice: "" | "-",
    name: "" | "-",
    phone: "" | "-",
    address: "" | "-",
    code: "" | "-",
    type: "" | "-",
    price: "" | "-",
    qty: "" | "-",
    city: "" | "-",
    postalcode: "" | "-",
    kurir: "" | "-",
    resi: "" | "-",
    createdAt: "" | "-",
    status: "" | "-",
    email: "" | "-",
    role: "" | "-",
    verifcode: "" | "-",
    image: "" | "-",
  });

  const openModalImage = (image, id) => {
    setShowModalImage(true);
    setPaymentImageId(id);
    if (image) {
      setPaymentImage(`${process.env.REACT_APP_API_URL}${image}`);
    } else {
      setPaymentImage("");
    }
  };

  const closeModalImage = () => {
    setShowModalImage(false);
    setPaymentImage("");
  };

  const handleSubmitImg = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("image", e.target.files[0]);

    if (window.confirm("Pastikan data sudah benar")) {
      try {
        const response = await axios.post(
          `/set-sales-image/${paymentImageId}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          },
        );
        alert("Gambar berhasil diunggah");

        window.location.reload();
      } catch (error) {
        console.error("Error posting data:", error);
      }
    }
  };

  const openModal = (rowData) => {
    setLoading(true);

    setShowModal(true);
    setOrder({
      id: rowData.id || "",
      name: rowData.name || "",
      invoice: rowData.invoice || "",
      phone: rowData.phone || "",
      address: rowData.address || "",
      price: rowData.price || "",
      qty: rowData.qty || "",
      city: rowData.city || "",
      postalcode: rowData.postalcode || "",
      status: rowData.status || "",
      type: rowData.type || "",
      resi: rowData.resi || "",
      kurir: rowData.kurir || "",
      email: rowData.email || "",
      role: rowData.role || "",
      verifcode: rowData.verifcode || "",
      code: rowData.code || "",
    });
    setScannedData(rowData.code);

    const getProduct = async () => {
      try {
        const response = await axios.get("/product");
        const filteredProduct = response.data.filter(
          (item) => item.sales !== "sold",
        );
        setLoading(false);

        const filteredOrder =
          rowData.type === "Karton"
            ? "C"
            : rowData.type === "Slop"
            ? "S"
            : rowData.type === "Box"
            ? "B"
            : rowData.type === "Sachet"
            ? "P"
            : "";

        console.log(filteredOrder);

        const filteredByType = filteredProduct.filter((item) =>
          item.code.startsWith(filteredOrder),
        );

        const dataCodeString = rowData.code; // String yang ingin diubah menjadi array
        const dataCodeArray = dataCodeString
          .split(",")
          .filter((item) => item.trim() !== "");
        const cleanedDataArray = dataCodeArray.map((item) =>
          item.replace(/["\[\]]/g, ""),
        );
        const cleanedDataArrayFiltered = cleanedDataArray.filter(
          (item) => item.trim() !== "",
        );
        setScannedData(cleanedDataArrayFiltered);
      } catch (error) {
        console.log(error.message);
      }
    };
    getProduct();
  };

  const closeModal = () => {
    setShowModal(false);
    setScannedData([]);
    setDetail(false);
  };

  const [detail, setDetail] = useState(false);
  const [scannedData, setScannedData] = useState([]);
  const [scannedDataError, setScannedDataError] = useState("");

  const onNewScanResult = (decodedText) => {
    setScannedData((prevData) => [...prevData, decodedText]);
  };

  const handleProductClick = (code) => {
    setScannedData((prevData) => {
      if (prevData.includes(code)) {
        return prevData;
      } else {
        return [...prevData, code];
      }
    });
  };

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      code: scannedData,
      qty: order.qty,
      resi: order.resi,
      kurir: order.kurir,
      email: order.email,
      name: order.name,
      invoice: order.invoice,
      verifcode: order.verifcode,
      price: order.price,
    }));
    setFormDataSend((prevFormData) => ({
      ...prevFormData,
      code: scannedData,
      qty: order.qty,
      resi: order.resi,
      kurir: order.kurir,
      email: order.email,
      name: order.name,
      invoice: order.invoice,
      verifcode: order.verifcode,
      price: order.price,
    }));
  }, [scannedData]);

  const onError = (decodedText) => {
    setScannedDataError(decodedText);
  };

  const detailOpen = () => {
    setDetail((prevDetail) => !prevDetail);
  };

  const handleRemoveItem = (indexToRemove) => {
    setScannedData((prevData) =>
      prevData.filter((_, index) => index !== indexToRemove),
    );
  };

  const [formData, setFormData] = useState({
    code: [],
    qty: "",
    resi: order.resi,
    kurir: order.kurir,
    status: "packing",
    email: order.email,
    name: order.name,
    invoice: order.invoice,
    verifcode: order.verifcode,
    price: order.price,
  });

  const [formDataSend, setFormDataSend] = useState({
    code: [],
    qty: "",
    resi: order.resi,
    kurir: order.kurir,
    status: "sent",
    email: order.email,
    name: order.name,
    invoice: order.invoice,
    verifcode: order.verifcode,
    price: order.price,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    setFormDataSend((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const updateOrder = async (row) => {
    setLoading(true);

    if (window.confirm("Make sure the data is correct")) {
      if (order.status === "approved") {
        if (formData.code.length !== formData.qty) {
          window.alert(`You need to choose ${formData.qty} products`);
          setLoading(false);

          return;
        }

        try {
          const response = await axios.post(
            `/update-sales/${order.id}`,
            formData,
            {
              headers: {
                Authorization: authToken,
              },
            },
          );
          const responseMail = await axios.post("/send-mail", {
            to: formData.email,
            subject: "Pembayaran Diterima, Sedang Dikemas",
            html: `
            <div style="
              background: linear-gradient(to bottom left, #ffd0ae, white);
              padding: 20px;
              border-radius: 10px;
              color: white;
            ">
            <img src="https://www.dynamiccoffee.id/static/media/ddn-logo.009be8d12c466b87829e.png" style="width: 120px; margin-bottom:10px;" />
  
                <h2 style="margin-top: 5px; margin-bottom: 10px; color: #FF535D;">Pembayaran Diterima, Sedang Dikemas</h2>
              <table>
                <tr>
                  <td>
                    <p style="color: black; margin-bottom: 5px; margin-top: 5px;">Nama</p>
                  </td>
                  <td>
                    <p style="color: black; margin-left: 5px; margin-bottom: 5px; margin-top: 5px;">: ${formData.name}</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p style="color: black; margin-bottom: 5px; margin-top: 5px;">TXID</p>
                  </td>
                  <td>
                    <p style="color: black; margin-left: 5px; margin-bottom: 5px; margin-top: 5px;">: ${formData.invoice}</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p style="color: black; margin-bottom: 5px; margin-top: 5px;">Jumlah</p>
                  </td>
                  <td>
                    <p style="color: black; margin-left: 5px; margin-bottom: 5px; margin-top: 5px;">: ${formData.qty} SLOP</p>
                  </td>
                </tr>
                <tr>
                <td>
                  <p style="color:  black; margin-bottom: 5px; margin-top: 5px;">Website</p>
                </td>
                <td>
                  <p style="color:  blue; margin-left: 5px; margin-bottom: 5px; margin-top: 5px;">: https://dynamiccoffee.id/ </p>
                </td>
              </tr>
              </table>
              <p style="color:  black; margin-bottom: 5px; margin-top: 5px;">Pembayaran sudah terkonfirmasi, pesanan sedang dalam proses kemas. Mohon ditunggu.</p>

            </div>
            `,
          });
          window.location.reload();
        } catch (error) {
          setLoading(false);

          if (error.response.status === 401) {
            navigate("/login");
          } else if (error.response.status === 403) {
            navigate("/login");
          } else {
            alert(error.response.data.msg);
          }
        }
      } else if (order.status === "packing") {
        if (formData.kurir && formData.resi) {
          try {
            const response = await axios.post(
              `/update-sales-stat/${order.id}`,
              formDataSend,
              {
                headers: {
                  Authorization: authToken,
                },
              },
            );

            const responseMail = await axios.post("/send-mail", {
              to: formDataSend.email,
              subject: "Pesanan sedang dalam pengiriman",
              html: `
              <div style="
                background: linear-gradient(to bottom left, #ffd0ae, white);
                padding: 20px;
                border-radius: 10px;
                color: white;
              ">
              <img src="https://www.dynamiccoffee.id/static/media/ddn-logo.009be8d12c466b87829e.png" style="width: 120px; margin-bottom:10px;" />
    
                  <h2 style="margin-top: 5px; margin-bottom: 10px; color:  #FF535D;">Pesanan sedang dalam pengiriman</h2>
                <table>
               
                  <tr>
                    <td>
                      <p style="color:  black; margin-bottom: 5px; margin-top: 5px;">Nama</p>
                    </td>
                    <td>
                      <p style="color:  black; margin-left: 5px; margin-bottom: 5px; margin-top: 5px;">: ${formDataSend.name}</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p style="color:  black; margin-bottom: 5px; margin-top: 5px;">TXID</p>
                    </td>
                    <td>
                      <p style="color:  black; margin-left: 5px; margin-bottom: 5px; margin-top: 5px;">: ${formDataSend.invoice}</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p style="color:  black; margin-bottom: 5px; margin-top: 5px;">Jumlah</p>
                    </td>
                    <td>
                      <p style="color:  black; margin-left: 5px; margin-bottom: 5px; margin-top: 5px;">: ${formDataSend.qty} SLOP</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p style="color:  black; margin-bottom: 5px; margin-top: 5px;">No Resi</p>
                    </td>
                    <td>
                      <p style="color:  black; margin-left: 5px; margin-bottom: 5px; margin-top: 5px;">: ${formDataSend.resi}</p>
                    </td>
                  </tr>
                  <tr>
                  <td>
                    <p style="color:  black; margin-bottom: 5px; margin-top: 5px;">Kurir</p>
                  </td>
                  <td>
                    <p style="color:  black; margin-left: 5px; margin-bottom: 5px; margin-top: 5px;">: ${formDataSend.kurir}</p>
                  </td>
                </tr>
                <tr>
                <td>
                  <p style="color:  black; margin-bottom: 5px; margin-top: 5px;">Website</p>
                </td>
                <td>
                  <p style="color:  blue; margin-left: 5px; margin-bottom: 5px; margin-top: 5px;">: https://dynamiccoffee.id/ </p>
                </td>
              </tr>
                </table>
                <p style="color:  black; margin-bottom: 5px; margin-top: 5px;">Pesanan anda sudah berada di pihak kurir. Mohon ditunggu dan dicek kembali ketika pesanan anda sudah selesai. Hubungi kami di 081111114040 apabila ada terkendala mengenai produk yang dikirimkan.</p>


              </div>
              `,
            });

            window.location.reload();
          } catch (error) {
            setLoading(false);

            if (error.response.status === 401) {
              navigate("/login");
            } else if (error.response.status === 403) {
              navigate("/login");
            } else {
              alert(error.response.data.msg);
            }
          }
        } else {
          window.alert(`Please input Resi & Courier`);
        }
      } else {
        window.alert(`Something Wrong`);
      }
    }
  };

  const formatRupiah = (number) => {
    const formattedString = new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
    }).format(number);

    return formattedString.endsWith(",00")
      ? formattedString.slice(0, -3)
      : formattedString;
  };

  const handleSubmit = async (id, mail, txid, name, qty, price, phone) => {
    setLoading(true);
    const priceIdr = formatRupiah(price);

    if (window.confirm("Are you sure you approve this order and process it?")) {
      try {
        const response = await axios.post(
          `/update-sales-stat/${id}`,
          {
            status: "approved",
          },
          {
            headers: {
              Authorization: authToken,
            },
          },
        );

        const recipientPhoneNumber = phone.replace(/^0/, "62");
        const responsewa = axios.post("/send-invoice", {
          recipientPhoneNumber: recipientPhoneNumber,
          id_transaksi: txid,
          nama: name,
          pesanan: `${qty} SLOP`,
          total: price * qty,
        });

        const responseMail = await axios.post("/send-mail", {
          to: mail,
          subject: "Pesanan anda sudah dikonfirmasi",
          html: `
          <div style="
            background: linear-gradient(to bottom left, #ffd0ae, white);
            padding: 20px;
            border-radius: 10px;
            color: white;
          ">
          <img src="https://www.dynamiccoffee.id/static/media/ddn-logo.009be8d12c466b87829e.png" style="width: 120px; margin-bottom:10px;" />

              <h2 style="margin-top: 5px; margin-bottom: 10px; color:  #FF535D;">Pesanan anda sudah dikonfirmasi</h2>
            <table>
              <tr>
                <td>
                  <p style="color:  black; margin-bottom: 5px; margin-top: 5px;">Nama</p>
                </td>
                <td>
                  <p style="color:  black; margin-left: 5px; margin-bottom: 5px; margin-top: 5px;">: ${name}</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p style="color:  black; margin-bottom: 5px; margin-top: 5px;">TXID</p>
                </td>
                <td>
                  <p style="color:  black; margin-left: 5px; margin-bottom: 5px; margin-top: 5px;">: ${txid}</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p style="color:  black; margin-bottom: 5px; margin-top: 5px;">Total Bayar</p>
                </td>
                <td>
                  <p style="color:  black; margin-left: 5px; margin-bottom: 5px; margin-top: 5px;">: ${priceIdr}</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p style="color:  black; margin-bottom: 5px; margin-top: 5px;">Jumlah</p>
                </td>
                <td>
                  <p style="color:  black; margin-left: 5px; margin-bottom: 5px; margin-top: 5px;">: ${qty} SLOP</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p style="color:  black; margin-bottom: 5px; margin-top: 5px;">Website</p>
                </td>
                <td>
                  <p style="color:  blue; margin-left: 5px; margin-bottom: 5px; margin-top: 5px;">: https://dynamiccoffee.id/ </p>
                </td>
              </tr>

            </table>
            <p style="color:black;  margin-bottom: 5px; margin-top: 5px;">Kami telah mengirimkan detail pembayaran ke nomor whatsapp yang telah anda daftarkan. Silahkan cek whatsapp dan lakukan pembayaran dalam 24 jam. Terimakasih.</p>

          </div>
          `,
        });

        window.location.reload();
      } catch (error) {
        setLoading(false);

        if (error.response.status === 401) {
          navigate("/login");
        } else if (error.response.status === 403) {
          navigate("/login");
        } else {
          alert(error.response.data.msg);
        }

        console.error("Error:", error);
        alert(error.response.data);
      }
    } else {
      setLoading(false);
    }
  };
  return (
    <div>
      <LoadingAdm loadingProps={loading} />

      {showModal && (
        <div>
          <div className="modalh">
            <div className="modal-content-product-h">
              <div className="d-flex align-items-center justify-content-between">
                <h3
                  style={{ caretColor: "transparent" }}
                  className="mb-0 modal-content-product-text-h"
                >
                  Order Detail -{" "}
                  <span
                    style={{
                      textTransform: "uppercase",
                      color:
                        order.status === "sent"
                          ? "#B050EF"
                          : order.status === "approved"
                          ? "#B97F00"
                          : order.status === "packing"
                          ? "#0E8BAA"
                          : order.status === "received"
                          ? "#ff535d"
                          : "white",
                    }}
                  >
                    {order.status}
                  </span>
                </h3>

                <div className="pointer" onClick={closeModal}>
                  <IoClose size={26} color="red" />
                </div>
              </div>
              <hr className="modal-content-product-text-h" />
              <div className="row">
                <h5 className="mb-3" style={{ opacity: "60%" }}>
                  Recipient Data
                </h5>

                <div className="col-4 col-sm-4 col-md-4">
                  <p>Name</p>
                  <p>Phone</p>
                  <p>Address</p>
                  <p>City</p>
                  <p>Postal Code</p>
                </div>
                <div className="col">
                  <p>: {order.name}</p>
                  <p>: {order.phone}</p>
                  <p>: {order.address}</p>
                  <p>: {order.city}</p>
                  <p>: {order.postalcode}</p>
                </div>
              </div>
              {order.status != "packing" ? (
                <div>
                  <hr />
                  <h5 className="mb-3" style={{ opacity: "60%" }}>
                    Select Product
                  </h5>

                  <div className="d-flex">
                    <QrReaderProduct
                      disabled={
                        scannedData.length >= order.qty ||
                        order.status === "finish"
                      }
                      onScan={onNewScanResult}
                      onError={onError}
                    />
                    {order.status === "finish" ||
                    order.status === "approved" ? (
                      <div className="dropdown">
                        <span
                          className=" btn btn-sm btn-sp btn-dark  px-3 p-2 dropdown-toggle"
                          type="button"
                          id="dropdownMenuButton"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          style={{ height: "100%" }}
                        >
                          <BsBoxFill />{" "}
                        </span>
                        <div
                          className="dropdown-menu dm-s"
                          aria-labelledby="dropdownMenuButton"
                        >
                          {dataP.length > 0 ? (
                            dataP.map((product) => (
                              <div key={product.id}>
                                <p
                                  onClick={() =>
                                    handleProductClick(product.code)
                                  }
                                  className="dropdown-item mb-0"
                                  href="#"
                                >
                                  {product.code}
                                </p>
                              </div>
                            ))
                          ) : (
                            <p
                              className="mb-0 text-light"
                              style={{ fontSize: "12px" }}
                            >
                              No Product Available
                            </p>
                          )}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="d-flex mt-3 align-items-center">
                    <span
                      onClick={detailOpen}
                      className="btn btn-sm btn-ddn btn-sm me-3"
                    >
                      Detail
                    </span>

                    <h5 className="mb-0">{scannedData.length || 0} Selected</h5>
                  </div>
                  {detail && (
                    <div className="row g-0 mt-3 cardselected w-100">
                      {scannedData.length > 0 ? (
                        scannedData.map((data, index) => (
                          <p
                            style={{
                              fontSize: "12px",
                              position: "relative",
                              overflow: "hidden",
                            }}
                            className="col-6 col-sm-6 col-md-4 my-1"
                            key={index}
                          >
                            {order.status != "sent" &&
                            order.status != "received" ? (
                              <IoClose
                                onClick={() => handleRemoveItem(index)}
                                className="pointer mb-1"
                                size={16}
                                color="red"
                              />
                            ) : (
                              ""
                            )}{" "}
                            {data}
                          </p>
                        ))
                      ) : (
                        <p
                          style={{
                            fontSize: "12px",
                            position: "relative",
                            overflow: "hidden",
                          }}
                          className="my-1"
                        >
                          No Product Selected
                        </p>
                      )}
                    </div>
                  )}
                </div>
              ) : (
                ""
              )}
              <hr />

              <div className="row">
                <h5 className="mb-3" style={{ opacity: "60%" }}>
                  Order Data
                </h5>

                <div className="col-4 col-sm-4 col-md-4">
                  <p
                    className="d-flex align-items-center mb-2"
                    style={{ height: "40px" }}
                  >
                    Qty
                  </p>
                  {order.status === "packing" ? (
                    <div>
                      <p
                        className="d-flex align-items-center mb-2"
                        style={{ height: "40px" }}
                      >
                        Resi
                      </p>
                      <p
                        className="d-flex align-items-center mb-2"
                        style={{ height: "40px" }}
                      >
                        Courier
                      </p>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-8">
                  <p
                    className="d-flex align-items-center mb-2"
                    style={{ height: "40px" }}
                  >
                    : {order.qty}
                  </p>
                  {order.status === "packing" ? (
                    <div>
                      <p className="d-flex align-items-center mb-2">
                        <input
                          defaultValue={order.resi}
                          name="resi"
                          onChange={handleChange}
                          type="text"
                          className="input-admin"
                          style={{
                            height: "40px",
                            paddingBottom: "8px",
                            minWidth: "100px",
                            width: "100%",
                          }}
                        />
                      </p>
                      <p className="d-flex align-items-center mb-2">
                        {" "}
                        <input
                          defaultValue={order.kurir}
                          name="kurir"
                          onChange={handleChange}
                          type="text"
                          className="input-admin"
                          style={{
                            height: "40px",
                            paddingBottom: "8px",
                            minWidth: "100px",
                            width: "100%",
                          }}
                        />
                      </p>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              {order.status === "sent" ||
              order.status === "packing" ||
              order.status === "scanned" ||
              order.status === "received" ? (
                <Wqr wqr={order.invoice} />
              ) : (
                ""
              )}
              {order.status != "received" &&
              order.status != "sent" &&
              order.status != "scanned" ? (
                <span
                  onClick={updateOrder}
                  className="btn btn-dark btn-submit w-100"
                  style={{ height: "40px" }}
                >
                  Update Order
                </span>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      )}

      {showModalImage && (
        <div>
          <div className="modalh">
            <div className="modal-content-product-h">
              <div className="d-flex align-items-center justify-content-between">
                <h3
                  style={{ caretColor: "transparent" }}
                  className="mb-0 modal-content-product-text-h"
                >
                  Payment Image
                </h3>

                <div className="pointer" onClick={closeModalImage}>
                  <IoClose size={26} color="red" className="ms-3" />
                </div>
              </div>
              <hr className="modal-content-product-text-h" />
              <label
                htmlFor={`imageUploads${paymentImageId}`}
                className="image-upload-label"
              >
                <div
                  className="d-flex align-items-center justify-content-center"
                  style={{ width: "300px", height: "350px" }}
                >
                  <img
                    src={
                      paymentImage && paymentImage
                        ? paymentImage
                        : uploadNoImage
                    }
                    className="w-100 pointer"
                    style={{
                      maxWidth: "1000px",
                      maxHeight: "300px",
                      objectFit: "contain",
                      caretColor: "transparent",
                    }}
                    alt=""
                  />
                </div>

                <input
                  id={`imageUploads${paymentImageId}`}
                  type="file"
                  onChange={(e) => handleSubmitImg(e)}
                  style={{ display: "none" }}
                />
              </label>
            </div>
          </div>
        </div>
      )}

      <DataTable columns={columns} data={data} pagination />
    </div>
  );
};

export default AllTable;
