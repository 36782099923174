import React, { useState, useEffect } from "react";
import axios from "axios";
import imgP from "../../../publicAssets/img/ddn-scan-s.png";

const InputProduct = () => {
  const [price, setPrice] = useState("");

  useEffect(() => {
    const getPrice = async () => {
      try {
        const response = await axios.get("/price");
        setPrice(response.data[0]);
        setFormData(response.data[0]);
      } catch (error) {
        console.log(error.message);
      }
    };
    getPrice();
  }, []);

  // ---------------------------------------------------------------------------------

  const [formData, setFormData] = useState({
    name: "",
    desc: "",
    subdesc: "",
    buy: "",
    sell: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (window.confirm("Make sure the data is correct")) {
      try {
        const response = await axios.post("/set-price", formData);
        console.log("Price Updated", response.data);
        window.location.reload();
      } catch (error) {
        console.error("Error posting data:", error);
      }
    }
  };
  const handleSubmitImg = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("image", e.target.files[0]);

    if (window.confirm("Pastikan data sudah benar")) {
      try {
        const response = await axios.post("/set-price-image", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        console.log("Gambar berhasil diunggah:", response.data.msg);

        window.location.reload();
      } catch (error) {
        console.error("Error posting data:", error);
      }
    }
  };

  return (
    <div className="row">
      <div className="col-12 mb-3 col-sm-12 col-md-12 col-lg-7 col-xl-6 d-flex flex-column align-items-center justify-content-center">
        <div
          className="p-2 w-100 h-100 d-flex justify-content-center align-items-center"
          style={{ backgroundColor: "grey" }}
        >
          <label htmlFor="imageUpload" className="image-upload-label">
            <img
              src={
                price && price.image
                  ? process.env.REACT_APP_API_URL + price.image
                  : imgP
              }
              className="w-100 pointer"
              style={{
                maxWidth: "450px",
                maxHeight: "450px",
                objectFit: "contain",
                caretColor: "transparent",
              }}
              alt=""
            />
            <input
              id="imageUpload"
              type="file"
              onChange={handleSubmitImg}
              style={{ display: "none" }}
            />
          </label>
        </div>
      </div>
      <div className="col mb-3">
        <div className="mb-2">
          <p className="mb-1" style={{ fontSize: "12px" }}>
            Product Name
          </p>
          <input
            style={{ caretColor: "white" }}
            name="name"
            onChange={handleChange}
            className="input-admin w-100"
            type="text"
            placeholder={
              formData && formData.name ? formData.name : "Ex: Dynamic"
            }
            value={formData && formData.name ? formData.name : ""}
          />
        </div>
        <div className="mb-2">
          <p className="mb-1" style={{ fontSize: "12px" }}>
            Product Description
          </p>
          <input
            style={{ caretColor: "white" }}
            name="desc"
            onChange={handleChange}
            className="input-admin w-100"
            type="text"
            placeholder={
              formData && formData.desc ? formData.desc : "Ex: Dynamic is..."
            }
            value={formData && formData.desc ? formData.desc : ""}
          />
        </div>
        <div className="mb-2">
          <p className="mb-1" style={{ fontSize: "12px" }}>
            Product Sub Description
          </p>
          <textarea
            style={{ minHeight: "80px", caretColor: "white" }}
            name="subdesc"
            onChange={handleChange}
            className="input-admin w-100"
            type="text"
            placeholder={
              formData && formData.subdesc ? formData.subdesc : "Ex: Dynamic..."
            }
            value={formData && formData.subdesc ? formData.subdesc : ""}
          />
        </div>
        <div className="mb-2">
          <p className="mb-1" style={{ fontSize: "12px" }}>
            Buy Price
          </p>
          <input
            style={{ caretColor: "white" }}
            name="buy"
            onChange={handleChange}
            className="input-admin w-100"
            type="number"
            placeholder={formData && formData.buy ? formData.buy : 0}
            value={formData && formData.buy ? formData.buy : ""}
          />
        </div>
        <div className="mb-4">
          <p className="mb-1" style={{ fontSize: "12px" }}>
            Sell Price
          </p>
          <input
            style={{ caretColor: "white" }}
            name="sell"
            onChange={handleChange}
            className="input-admin w-100"
            type="number"
            placeholder={formData && formData.sell ? formData.sell : 0}
            value={formData && formData.sell ? formData.sell : ""}
          />
        </div>
        <div
          onClick={handleSubmit}
          className="btn-ddn pointer text-center"
          style={{ maxWidth: "200px" }}
        >
          Update
        </div>{" "}
      </div>
    </div>
  );
};

export default InputProduct;
