import React, { useState, useEffect, useRef } from "react";
import "./loading.css";
import { BlinkBlur } from "react-loading-indicators";
const Loading = ({ loadingProps }) => {
  return (
    <div>
      <div
        className={
          loadingProps === true ? "canvasLoading" : "canvasLoading hidden"
        }
      >
        <BlinkBlur color="#FF535D" size="small" text="" textColor="" />{" "}
      </div>
    </div>
  );
};

export default Loading;
