import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import DataTable from "react-data-table-component";
import LoadingAdm from "../../../loadAdmin/loadingAdm";

const DistributionTable = () => {
  const authToken = localStorage.getItem("token");
  const navigate = useNavigate();

  const [data, setData] = useState([]);

  const [loading, setLoading] = useState(true);

  const columns = [
    {
      name: "NO",
      selector: (row, index) => index + 1 + paginationStartIndex,
      sortable: true,
    },
    {
      name: "NAME",
      selector: (row) => (row.name ? row.name : "-"),
      sortable: true,
    },

    {
      name: "PHONE",
      selector: (row) => (row.phone ? row.phone : "-"),
      sortable: true,
    },

    {
      name: "QTY",
      selector: (row) => (row.qty ? row.qty : "-"),
      sortable: true,
    },

    {
      name: "DATE",
      selector: (row) =>
        row.createdAt ? new Date(row.createdAt).toLocaleDateString() : "-",
      sortable: true,
    },
    {
      name: "STATUS",
      selector: (row) => (row.status ? row.status : "-"),
      sortable: true,
      cell: (row) => {
        let cellStyle = {};
        if (row.status === "finish") {
          cellStyle.color = "#0CAB58";
          cellStyle.fontWeight = "600";
          cellStyle.textTransform = "uppercase";
        } else if (row.status === "approved") {
          cellStyle.color = "#B97F00";
          cellStyle.fontWeight = "600";
          cellStyle.textTransform = "uppercase";
        } else if (row.status === "packing") {
          cellStyle.color = "#0E8BAA";
          cellStyle.fontWeight = "600";
          cellStyle.textTransform = "uppercase";
        } else if (row.status === "sent") {
          cellStyle.color = "#B050EF";
          cellStyle.fontWeight = "600";
          cellStyle.textTransform = "uppercase";
        } else if (row.status === "waiting") {
          cellStyle.color = "#fff";
          cellStyle.fontWeight = "600";
          cellStyle.textTransform = "uppercase";
        } else if (row.status === "received") {
          cellStyle.color = "#ff535d ";
          cellStyle.fontWeight = "600";
          cellStyle.textTransform = "uppercase";
        } else if (row.status === "scanned") {
          cellStyle.color = "#0CAB58";
          cellStyle.fontWeight = "600";
          cellStyle.textTransform = "uppercase";
        }
        return <div style={cellStyle}>{row.status ? row.status : "-"}</div>;
      },
    },
  ];
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/sales", {
          headers: {
            Authorization: authToken,
          },
        });
        const sortedData = response.data.reverse(); // Reverse to get the latest first
        const latestData = sortedData.slice(0, 10); // Get the first 10 items

        setData(latestData);
      } catch (error) {
        if (error.response.status === 401) {
          navigate("/login");
        } else if (error.response.status === 403) {
          navigate("/login");
        } else {
          console.log(error.response.status);
        }
      }
    };

    fetchData();

    setLoading(false);
  }, []);

  const [paginationStartIndex, setPaginationStartIndex] = useState(0);
  const handlePageChange = (page) => {
    setPaginationStartIndex((page - 1) * 10);
  };
  return (
    <div>
      <LoadingAdm loadingProps={loading} />

      <DataTable columns={columns} data={data} />
    </div>
  );
};

export default DistributionTable;
