import React, { useState, useEffect, useRef } from "react";
import axios from "axios";

import { useNavigate } from "react-router-dom";

import Navbar from "../../component/navbar-lp/Navbars";
import BuyProduct from "./BuyProduct";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, EffectFade } from "swiper/modules";

import Loading from "../../component/loading/loading";
import Footer from "../../component/footer/Footer";

const Products = () => {
  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);
  const [typep, setTypep] = useState("");
  const [price, setPrice] = useState("");
  const [priceP, setPriceP] = useState("");
  const [prodP, setProdP] = useState("");
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [promotion, setPromotion] = useState([]);

  console.log(prodP.length);
  console.log(showModal);

  const openModal = (data, price) => {
    setShowModal(true);
    setTypep(data);
    setPriceP(price);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    const getPrice = async () => {
      try {
        const response = await axios.get("/price");
        const responseP = await axios.get("/promo");
        const responseProd = await axios.get("/product");

        setPrice(response.data[0]);
        setPromotion(responseP.data);
        const productAv = responseProd.data.filter(
          (product) => product.sales !== "sold" && product.prefix === "S1",
        );
        setProdP(productAv);

        setLoading(false);
        setIsDataLoaded(true); // Set data loaded state to true
      } catch (error) {
        console.log(error.message);
        setLoading(false);
        setIsDataLoaded(true); // Set data loaded state to true even on error
      }
    };
    getPrice();
  }, []);

  useEffect(() => {
    if (!isDataLoaded) return;

    const section = document.getElementById("s1");
    if (!section) return;

    const animatedElements = section.querySelectorAll(
      ".animated-s1,.animated-s1-title,.animated-s1-subtitle,.animated-s1-text",
    );

    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.1,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("visible");
        } else {
          entry.target.classList.remove("visible");
        }
      });
    }, options);

    animatedElements.forEach((element) => {
      observer.observe(element);
    });

    return () => {
      animatedElements.forEach((element) => {
        observer.unobserve(element);
      });
    };
  }, [isDataLoaded]);

  const formatRupiah = (number) => {
    // Format ke mata uang Rupiah
    const formattedString = new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
    }).format(number);

    return formattedString.endsWith(",00")
      ? formattedString.slice(0, -3)
      : formattedString;
  };

  const [loading, setLoading] = useState(true);
  return (
    <div id="s1">
      <div className="section-lp d-flex flex-column">
        <Loading loadingProps={loading} />
        <div>
          <svg
            className="bgp"
            style={{ zIndex: "0" }}
            width="828"
            height="598"
            viewBox="0 0 828 598"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="174.5"
              cy="-55.5"
              r="528.5"
              stroke="url(#paint0_linear_374_1145)"
              stroke-opacity="0.12"
              stroke-width="250"
            />
            <defs>
              <linearGradient
                id="paint0_linear_374_1145"
                x1="175"
                y1="-435.5"
                x2="174.5"
                y2="598"
                gradientUnits="userSpaceOnUse"
              >
                <stop />
                <stop offset="1" stop-color="#FF535D" />
              </linearGradient>
            </defs>
          </svg>
        </div>
        <Navbar name={"product"} />
        <BuyProduct
          propsModalProduct={showModal}
          closeModalCallback={closeModal}
          type={typep}
          price={priceP}
          qty={prodP.length}
        />
        <div className=" container" style={{ marginTop: "80px", zIndex: "1" }}>
          <br />
          <div className="row g-3">
            <div className="mb-3 col-12 col-sm-12 col-md-12 col-lg-6 d-flex align-items-center justify-content-center px-3">
              <img
                src={price && process.env.REACT_APP_API_URL + price.image}
                className="img-card-p1 animated-s1"
                alt=""
              />
            </div>
            <div className="mb-3 col-12 col-md-12 col-lg-6 d-flex flex-column justify-content-center px-4">
              <p
                className="text-prod animated-s1-title mb-0"
                style={{ fontWeight: "500" }}
              >
                {price && price.name}
              </p>
              <p className="mb-3 text-price text-dark animated-s1-subtitle">
                {price && price.desc}
              </p>
              <p className="text-dark text-des animated-s1-text">
                {price && price.subdesc}
              </p>
              <p className="mb-0 text-prices animated-s1-text">
                {formatRupiah(price && price.sell)}
              </p>{" "}
              {prodP.length > 0 ? (
                <button
                  onClick={() => openModal("Slop", price && price.sell)}
                  className="mt-4 py-2 w-100 btn btn-sm btn-dark btn-buy d-flex justify-content-center "
                  style={{ borderRadius: "20px", maxWidth: "250px" }}
                >
                  Beli Produk
                </button>
              ) : (
                <button
                  disabled
                  className="mt-4 py-2 w-100 btn btn-sm btn-dark btn-buy d-flex justify-content-center "
                  style={{ borderRadius: "20px", maxWidth: "250px" }}
                >
                  Beli Produk
                </button>
              )}
            </div>
          </div>
          {promotion.length > 0 ? (
            <div className="animated-s1-text" style={{ position: "relative" }}>
              <p className="slideHead mt-3 mb-2">PROMO</p>

              <Swiper
                style={{ marginTop: "80px" }}
                spaceBetween={10}
                slidesPerView={1}
                pagination={true}
                modules={[Pagination]}
                className="pointer"
              >
                {promotion.map((items, el) => {
                  return (
                    <SwiperSlide>
                      <div
                        onClick={() => (window.location.href = items.desc)}
                        className="cardSliderProd"
                        style={{
                          backgroundImage: `url(${
                            process.env.REACT_APP_API_URL + items.image
                          })`,
                        }}
                      ></div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
          ) : (
            ""
          )}

          <br />
          <br />
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Products;
