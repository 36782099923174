import React, { useEffect, useRef, useState } from "react";
import QrScanner from "qr-scanner";
import axios from "axios";
import { FaCamera } from "react-icons/fa6";
import { RiGalleryFill } from "react-icons/ri";

import "./scanner.css";

const QrReader = ({ onScan, onError, showScan }) => {
  const scanner = useRef(null);
  const videoContainerRef = useRef(null);
  const qrBoxEl = useRef(null);
  const fileInputRef = useRef(null);
  const [qrOn, setQrOn] = useState(false);

  const onScanSuccess = (result) => {
    onScan(result ? result.data : "");
    if (scanner.current) {
      scanner.current.stop();
      scanner.current = null;
      setQrOn(false);
    }
  };
  const onScanFail = (error) => {
    console.log(error);
    onError("File bukan QR Code");
  };

  const handleOpenCamera = () => {
    if (!qrOn && videoContainerRef.current && !scanner.current) {
      while (videoContainerRef.current.firstChild) {
        videoContainerRef.current.removeChild(
          videoContainerRef.current.firstChild,
        );
      }

      const videoEl = document.createElement("video");
      videoEl.setAttribute("autoplay", "");
      videoEl.setAttribute("playsinline", "");
      videoContainerRef.current.appendChild(videoEl);

      scanner.current = new QrScanner(videoEl, onScanSuccess, {
        onDecodeError: onScanFail,
        preferredCamera: "environment",
        highlightScanRegion: true,
        highlightCodeOutline: true,
        overlay: qrBoxEl.current || undefined,
      });

      scanner.current
        .start()
        .then(() => setQrOn(true))
        .catch((error) => {
          console.error("Failed to start scanner:", error);
          alert(error);
          scanner.current = null;
          setQrOn(false);
        });
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = () => {
          QrScanner.scanImage(image)
            .then((result) => {
              onScanSuccess({ data: result });
            })
            .catch((error) => {
              onScanFail(error);
            });
        };
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    return () => {
      if (scanner.current) {
        scanner.current.stop();
        scanner.current = null;
      }
    };
  }, []);

  return (
    <div className="d-flex flex-column btn-scan mx-auto ">
      {!qrOn && showScan && (
        <button
          className="btn-ddn mb-2 mt-2 d-flex align-items-center justify-content-start ps-4 fw-bold"
          onClick={handleOpenCamera}
        >
          <FaCamera className="me-3" size={20} />
          Scan Melalui Kamera
        </button>
      )}
      <div
        className={`${!qrOn ? "camHidden" : "qr-reader mt-2"}`}
        ref={videoContainerRef}
      ></div>
      <input
        type="file"
        accept="image/*"
        onChange={handleFileChange}
        ref={fileInputRef}
        style={{ display: "none" }}
      />
      {showScan && (
        <button
          className="btn-ddn btn-scan mt-3  d-flex align-items-center justify-content-start ps-4 fw-bold"
          onClick={() => fileInputRef.current.click()}
        >
          <RiGalleryFill className="me-3" size={20} />
          Scan Melalui Galeri
        </button>
      )}
    </div>
  );
};

export default QrReader;
