import React, { useEffect, useRef, useState } from "react";
import QrScanner from "qr-scanner";

import "./scanner.css";

const QrReader = ({ onScan, onError, disabled }) => {
  const scanner = useRef(null);
  const videoContainerRef = useRef(null);
  const qrBoxEl = useRef(null);
  const fileInputRef = useRef(null);
  const [qrOn, setQrOn] = useState(false);

  const onScanSuccess = (result) => {
    onScan(result ? result.data : "");
    if (scanner.current) {
      scanner.current.stop();
      scanner.current = null; // Clear the scanner reference
      setQrOn(false); // Set qrOn to false when scanning is stopped
    }
  };
  const onScanFail = (error) => {
    console.log(error);
    onError("Image is not QR Code");
  };

  const handleOpenCamera = () => {
    if (!qrOn && videoContainerRef.current && !scanner.current) {
      while (videoContainerRef.current.firstChild) {
        videoContainerRef.current.removeChild(
          videoContainerRef.current.firstChild,
        );
      }

      const videoEl = document.createElement("video");
      videoEl.setAttribute("autoplay", "");
      videoEl.setAttribute("playsinline", "");
      videoContainerRef.current.appendChild(videoEl);

      scanner.current = new QrScanner(videoEl, onScanSuccess, {
        onDecodeError: onScanFail,
        preferredCamera: "environment",
        highlightScanRegion: true,
        highlightCodeOutline: true,
        overlay: qrBoxEl.current || undefined,
      });

      scanner.current
        .start()
        .then(() => setQrOn(true))
        .catch((error) => {
          console.error("Failed to start scanner:", error);
          scanner.current = null; // Clear the scanner reference
          setQrOn(false);
        });
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = () => {
          QrScanner.scanImage(image)
            .then((result) => {
              onScanSuccess({ data: result });
            })
            .catch((error) => {
              onScanFail(error);
            });
        };
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    return () => {
      if (scanner.current) {
        scanner.current.stop();
        scanner.current = null;
      }
    };
  }, []);

  return (
    <div className="d-flex ">
      {!disabled ? (
        <div>
          {" "}
          {!qrOn && (
            <span
              className="btn btn-sm btn-sp btn-dark me-2 px-3 p-2"
              onClick={handleOpenCamera}
            >
              Open Camera
            </span>
          )}
        </div>
      ) : (
        <span className="btn btn-sm btn-sp btn-dark me-2 px-3 p-2">
          Open Camera
        </span>
      )}
      <div
        className={`${!qrOn ? "camHidden" : "qr-reader"}`}
        ref={videoContainerRef}
      ></div>
      <input
        type="file"
        accept="image/*"
        onChange={handleFileChange}
        ref={fileInputRef}
        style={{ display: "none" }}
      />
      {!disabled ? (
        <span
          className="btn btn-sm btn-sp btn-dark me-2 px-3 p-2"
          onClick={() => fileInputRef.current.click()}
        >
          Scan by Image
        </span>
      ) : (
        <span className="btn btn-sm btn-sp btn-dark me-2 px-3 p-2">
          Scan by Image
        </span>
      )}
    </div>
  );
};

export default QrReader;
