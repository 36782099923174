import React, { useState, useEffect } from "react";
import axios from "axios";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";

const AccEvent = () => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [paginationStartIndex, setPaginationStartIndex] = useState(0);

  const navigate = useNavigate();
  const authToken = localStorage.getItem("token");

  const columns = [
    {
      name: "RANK",
      selector: (row, index) => index + 1 + paginationStartIndex,
      sortable: true,
    },

    {
      name: "BUYER",
      selector: (row) => (row.phone ? row.phone : "-"),
      sortable: true,
    },
    {
      name: "TOTAL QTY",
      selector: (row) => (row.qtyTotal ? row.qtyTotal : "-"),
      sortable: true,
    },

    {
      name: "TYPE",
      selector: (row) => (row.type ? row.type : "-"),
      sortable: true,
      cell: (row) => {
        let cellStyle = {};
        cellStyle.textTransform = "uppercase";

        return <div style={cellStyle}>{row.type ? row.type : "-"}</div>;
      },
    },
    {
      name: "CITY",
      selector: (row) => (row.city ? row.city : "-"),
      sortable: true,
    },
    {
      name: "POSTALCODE",
      selector: (row) => (row.postalcode ? row.postalcode : "-"),
      sortable: true,
    },
    {
      name: "CREATED AT",
      selector: (row) =>
        row.createdAt ? new Date(row.createdAt).toLocaleDateString() : "-",
      sortable: true,
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/sales", {
          headers: {
            Authorization: authToken,
          },
        });
        const groupedData = groupDataByPhoneNumber(response.data);
        const processedData = processGroupedData(groupedData);
        const sortedData = sortDataByQtyTotal(processedData);
        setData(sortedData);
      } catch (error) {
        if (error.response.status === 401) {
          navigate("/login");
        } else if (error.response.status === 403) {
          navigate("/login");
        } else {
          console.log(error.response.status);
        }
      }
    };
    fetchData();
  }, []);
  const groupDataByPhoneNumber = (data) => {
    const groupedData = {};
    data.forEach((item) => {
      const phoneNumber = item.phone;
      if (!groupedData[phoneNumber]) {
        groupedData[phoneNumber] = [];
      }
      groupedData[phoneNumber].push(item);
    });
    return groupedData;
  };

  const processGroupedData = (groupedData) => {
    const processedData = [];
    for (const phoneNumber in groupedData) {
      if (groupedData.hasOwnProperty(phoneNumber)) {
        const latestItem = groupedData[phoneNumber][0]; // Get latest item for each phone number
        const totalQty = groupedData[phoneNumber].reduce(
          (acc, curr) => acc + curr.qty,
          0,
        ); // Calculate total qty
        latestItem.qtyTotal = totalQty; // Add total qty to latest item
        processedData.push(latestItem); // Push latest item to processed data
      }
    }
    return processedData;
  };

  const sortDataByQtyTotal = (data) => {
    return data.sort((a, b) => b.qtyTotal - a.qtyTotal); // Sort by qtyTotal descending
  };
  const handlePageChange = (page) => {
    setPaginationStartIndex((page - 1) * 10);
  };
  return (
    <>
      <DataTable
        columns={columns}
        data={data}
        pagination
        paginationTotalRows={data.length}
        onChangePage={handlePageChange}
      />
    </>
  );
};

export default AccEvent;
