import React, { useEffect } from "react";
import axios from "axios";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
  useParams,
} from "react-router-dom";
// -------------
import Dashboard from "./admin/pages/dashboard/Dashboard";
import Product from "./admin/pages/product/Product";
// -------------
import ScannerQr from "./pages/scanner/Scanner";
import Home from "./pages/home/Home";
import "./App.css";
import AdminInput from "./admin/pages/adminInput/AdminInput";
import Order from "./admin/pages/order/Order";
import Products from "./pages/product/Product";
import Login from "./pages/auth/Login.js";
import Distribution from "./admin/pages/distribution/Distribution";
import Reward from "./admin/pages/reward/Reward.js";
import Reseller from "./pages/reseller/Reseller.js";
import Register from "./pages/auth/Register.js";
import WqrUser from "./pages/scanner/WqrUser.js";
import Forgot from "./pages/auth/Forgot.js";
import ScannerInv from "./pages/scanner/ScannerInv.js";

// axios.defaults.baseURL = "http://localhost:5000";
axios.defaults.baseURL = process.env.REACT_APP_API_URL;
// http://localhost:5000
// https://ddn-app-po3tu.ondigitalocean.app

const App = () => {
  return (
    <Router>
      <AppRoutes />
    </Router>
  );
};

const EnsureTokenMatch = ({ children }) => {
  const navigate = useNavigate();
  const token = useParams();
  useEffect(() => {
    const checkToken = async () => {
      try {
        if (!token.token) {
          navigate("/login");
        } else {
          await axios.post(
            "/verif-token",
            {},
            { headers: { Authorization: token.token } },
          );
        }
      } catch (error) {
        console.error("Error:", error);
        navigate("/login");
      }
    };

    checkToken();
  }, []);

  return children;
};

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/scan" element={<ScannerQr />} />
      <Route path="/scan-confirmation" element={<ScannerInv />} />

      <Route path="/products" element={<Products />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/forgot" element={<Forgot />} />

      <Route path="/invoice/:invoice" element={<WqrUser />} />

      {/* Admin routes */}
      <Route
        path="/controller/dashboard/:token"
        element={
          <EnsureTokenMatch>
            <Dashboard />
          </EnsureTokenMatch>
        }
      />
      <Route
        path="/controller/product/:token"
        element={
          <EnsureTokenMatch>
            <Product />
          </EnsureTokenMatch>
        }
      />
      <Route
        path="/controller/admin-input/:token"
        element={
          <EnsureTokenMatch>
            <AdminInput />
          </EnsureTokenMatch>
        }
      />
      <Route
        path="/controller/order/:token"
        element={
          <EnsureTokenMatch>
            <Order />
          </EnsureTokenMatch>
        }
      />
      <Route
        path="/controller/distribution/:token"
        element={
          <EnsureTokenMatch>
            <Distribution />
          </EnsureTokenMatch>
        }
      />
      <Route
        path="/controller/reward/:token"
        element={
          <EnsureTokenMatch>
            <Reward />
          </EnsureTokenMatch>
        }
      />
      <Route
        path="/controller/reseller/:token"
        element={
          <EnsureTokenMatch>
            <Reseller />
          </EnsureTokenMatch>
        }
      />
    </Routes>
  );
};

export default App;
