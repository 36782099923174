import React, { useState, useEffect } from "react";
import axios from "axios";
import DataTable from "react-data-table-component";
import QrPreview from "./QrPreview";

const CTable = () => {
  const [data, setData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationStartIndex, setPaginationStartIndex] = useState(0);
  const [qrDownload, setqrDownload] = useState([]);
  const [qrModal, setQrModal] = useState(false);

  const fetchData = async (page = 1, limit = 10) => {
    try {
      const offset = (page - 1) * limit; // Hitung offset berdasarkan halaman saat ini
      const response = await axios.get(`/product`, {
        params: { limit, offset, codePrefix: "S" }, // Tambahkan parameter filter
      });

      setData(response.data.data); // Data yang sudah difilter dari backend
      setTotalRows(response.data.total); // Update total rows
      setPaginationStartIndex(offset); // Set start index untuk halaman saat ini
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  useEffect(() => {
    fetchData(currentPage); // Fetch initial data
  }, [currentPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleDelete = async (qrid, batch) => {
    if (window.confirm("Are you sure you want to delete this product?")) {
      try {
        await axios.post(`/delete-product/${qrid}/${batch}`);
        fetchData(currentPage); // Refresh data for the current page
        console.log("Product deleted successfully");
      } catch (error) {
        console.error("Error deleting product:", error);
      }
    }
  };

  const handleDownloadQRAll = (qrid) => {
    console.log("Downloading QR for qrid:", qrid);
    const filteredAll = data.filter((item) => item.qrid === qrid);
    setqrDownload(filteredAll);
    setQrModal(true);
  };

  const handleDownloadAll = () => {
    const filteredAll = data.map((item) => item.qrid);
    console.log("Downloading QR for qrid:", filteredAll);

    // setqrDownload(filteredAll);
    // setQrModal(true);
  };

  const columns = [
    {
      name: "NO",
      selector: (row, index) => index + 1 + paginationStartIndex,
      sortable: true,
    },
    {
      name: "BATCH",
      selector: (row) => (row.batch ? row.batch : "-"),
      sortable: true,
    },
    {
      name: "CODE",
      selector: (row) => (row.code ? row.code : "-"),
      sortable: true,
    },
    {
      name: "SALES",
      selector: (row) => (row.sales ? row.sales : "Available"),
      sortable: true,
      cell: (row) => {
        let cellStyle = {};
        if (row.sales === "sold") {
          cellStyle.color = "#0CAB58";
          cellStyle.fontWeight = "600";
          cellStyle.textTransform = "uppercase";
        }
        return <div style={cellStyle}>{row.sales ? row.sales : "-"}</div>;
      },
    },
    {
      name: "ALL - QR",
      cell: (row) => (
        <span
          onClick={() => handleDownloadQRAll(row.qrid)}
          style={{ backgroundColor: "green" }}
          className="btn btn-download-qr text-white"
        >
          Download
        </span>
      ),
      sortable: false,
    },
    {
      name: "ACTION",
      cell: (row) => (
        <span
          onClick={() => handleDelete(row.qrid, row.batch)}
          style={{ backgroundColor: "red" }}
          className="btn btn-download-qr text-white px-4"
        >
          Delete
        </span>
      ),
      sortable: false,
    },
  ];

  return (
    <div>
      <DataTable
        columns={columns}
        data={data}
        pagination
        paginationServer // Enable server-side pagination
        paginationTotalRows={totalRows} // Total rows from the server
        onChangePage={handlePageChange}
        paginationPerPage={10} // Number of items per page
      />
      <QrPreview QrCode={qrDownload} QrModal={qrModal} />
      <button onClick={handleDownloadAll}>download all</button>
    </div>
  );
};

export default CTable;
