import React, { useState, useEffect } from "react";
import axios from "axios";
import NavbarAdmin from "../../../component/navbarAdmin/Sidebar";
import { IoIosArrowForward } from "react-icons/io";
import imgP from "../../../publicAssets/img/ddn-scan-s.png";
import InputProduct from "./InputProduct";
import InputPromotions from "./InputPromotions";

const AdminInput = () => {
  const [sbOn, setSbOn] = useState("");
  const [price, setPrice] = useState("");

  const receiveDataFromSidebar = (data) => {
    const sbData = { data: data === undefined ? true : data };
    setSbOn(sbData.data);
  };
  useEffect(() => {
    function handleResize() {
      if (window.innerWidth > 768) {
        setSbOn(false);
      } else {
        setSbOn(true);
      }
    }

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="d-flex">
      <NavbarAdmin sbOnProp={receiveDataFromSidebar} sbAct={"input"} />

      <div
        className={`container-fluid ${
          !sbOn ? "content-body" : "content-body-active"
        } p-4`}
      >
        <br />

        <div>
          <ul className="nav nav-pills mb-4" id="pills-tab" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                className="nav-link active"
                id="pills-ip-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-ip"
                type="button"
                role="tab"
                aria-controls="pills-ip"
                aria-selected="true"
              >
                SET PRODUCT
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="pills-prm-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-prm"
                type="button"
                role="tab"
                aria-controls="pills-prm"
                aria-selected="false"
              >
                PROMOTION
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                // id="pills-???-tab"
                // data-bs-toggle="pill"
                // data-bs-target="#pills-???"
                type="button"
                role="tab"
                // aria-controls="pills-???"
                // aria-selected="false"
              ></button>
            </li>
          </ul>
        </div>
        <div className="tab-content" id="pills-tabContent">
          <div
            className="tab-pane fade show active"
            id="pills-ip"
            role="tabpanel"
            aria-labelledby="pills-ip-tab"
          >
            <div className="card-reseller" style={{ maxWidth: "1000px" }}>
              <InputProduct />
            </div>
          </div>
        </div>
        <div className="tab-content" id="pills-tabContent">
          <div
            className="tab-pane fade "
            id="pills-prm"
            role="tabpanel"
            aria-labelledby="pills-prm-tab"
          >
            <div
              className="card-reseller"
              style={{ maxWidth: "1000px", backgroundColor: "transparent" }}
            >
              <InputPromotions />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminInput;
